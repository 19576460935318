import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerNumber } from "store/reducers";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useGlobalEvent, useDebouncedFn } from "beautiful-react-hooks";
import MaterialButton from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  getPumpHoursReport,
  getDownloadPumpHoursReport,
  getAnyMachine,
} from "store/actions";
import { cmsLogoAndCollor, cMSResources } from "../../index";
import FetchData from "../../components/Common/FetchData";
import ReportIcon from "@material-ui/icons/Assessment";

import "./Reports.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const ReportsView = () => {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const getFormattedDate = (daysAgo) => {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    return date.toISOString().slice(0, 10);
  };
  const pumpHoursData = useSelector((state) => state.pumpHoursReport);
  let anyMachine = useSelector((state) => state.anyMachine);
  const customerNumber = useSelector(getCustomerNumber);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const onWindowResize = useGlobalEvent("resize");
  const [embedResponse, setEmebedResponse] = useState({});
  const [report, setReport] = useState("");
  const [selectedOption, setSelectedOption] = useState("PowerBiEmbeded");
  var initialDaysAgo = 30;
  const [dateFrom, setDateFrom] = useState(getFormattedDate(initialDaysAgo));
  const [dateTo, setDateTo] = useState(getFormattedDate(0));

  const onWindowResizeHandler = useDebouncedFn(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, 250);

  onWindowResize(onWindowResizeHandler);

  var cmsReportsTotalHours = findArrayElementByTitle(
    cMSResources,
    "{{ReportsTotalHours}}"
  );
  var cmsReportsNothingToShow = findArrayElementByTitle(
    cMSResources,
    "{{ReportsNothingToShow}}"
  );
  var cmsReportsUserNoDevice = findArrayElementByTitle(
    cMSResources,
    "{{ReportsUserNoDevice}}"
  );

  useEffect(() => {
    dispatch(getPumpHoursReport(dateFrom, dateTo, customerNumber));
    dispatch(getAnyMachine(customerNumber));
  }, [dispatch, dateFrom, dateTo, customerNumber]);

  useEffect(() => {
    if (pumpHoursData) {
      const datasets = pumpHoursData.map((modelData) => {
        const labels = modelData.dailyMeasuresVW.map(
          (data) => data.measureDate
        );
        const values = modelData.dailyMeasuresVW.map(
          (data) => data.pumpHours ?? 0
        );

        return {
          model: modelData.model,
          labels,
          values,
        };
      });

      renderChart(datasets);
    }
  }, [pumpHoursData]);

  useEffect(() => {
    FetchData(
      `api/PowerBi/GetPowerBiToken/${customerNumber}/${dateFrom}/${dateTo}`,
      {},
      "GET"
    ).then((results) => {
      if (results.includes("user not authorized")) {
      } else {
        setEmebedResponse(JSON.parse(results));
      }
    });
  }, [selectedOption, customerNumber, dateFrom, dateTo]);

  const renderChart = (datasets) => {
    if (!chartRef.current) return;

    const formattedDatasets = datasets.map((dataset, index) => {
      const formattedLabels = dataset.labels.map((label) => {
        const date = new Date(label);
        return date.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
        });
      });

      const colors = [
        "rgba(0, 118, 129, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(0, 255, 0, 0.2)",
        "rgba(0, 0, 255, 0.2)",
        "rgba(255, 255, 0, 0.2)",
        "rgba(255, 0, 255, 0.2)",
      ];
      const borderColors = [
        "rgba(0, 118, 129, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(0, 255, 0, 1)",
        "rgba(0, 0, 255, 1)",
        "rgba(255, 255, 0, 1)",
        "rgba(255, 0, 255, 1)",
      ];

      return {
        label: dataset.model, // Use the equipment model as the label
        data: dataset.values,
        backgroundColor: colors[index % colors.length],
        borderColor: borderColors[index % borderColors.length],
        borderWidth: 1,
      };
    });

    const ctx = chartRef.current.getContext("2d");
    if (window.myChart) {
      window.myChart.destroy();
    }

    window.myChart = new window.Chart(ctx, {
      type: "bar",
      data: {
        labels: datasets[0].labels.map((label) => {
          const date = new Date(label);
          return date.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
          });
        }), // Assuming all datasets share the same label dates
        datasets: formattedDatasets,
      },
      options: {
        plugins: {
          legend: {
            labels: {
              font: {
                size: 24,
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  };

  const handleDisplayButtonClick = () => {
    if (selectedOption !== "PowerBiEmbeded") {
      dispatch(getPumpHoursReport(dateFrom, dateTo, customerNumber));
    }
  };

  const handleDownloadButtonClick = () => {
    dispatch(getDownloadPumpHoursReport(dateFrom, dateTo, customerNumber));
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="order-history order-history-wrapper">
      <div className="order-history-header shape_flex shape_flex--space-between shape_flex--align-center">
        <div className="order-history-header-text shape_flex shape_flex--align-center">
          <ReportIcon
            className="order-history-header-icon"
            color={cmsLogoAndCollor.color}
          />
          <div className="shape_flex shape_flex--direction-column">
            <span className="order-history-header-title">Reports</span>
          </div>
          <div className="select-wrapper">
            <select onChange={handleDropdownChange} value={selectedOption}>
              <option value="PowerBiEmbeded">Job Information</option>
              <option value="PumpHours">Pump Hours</option>
            </select>
          </div>
        </div>
        <div className="order-history-controls">
          <div>
            <TextField
              id="date-from"
              label="Date From"
              type="date"
              variant="outlined"
              margin="dense"
              className="date-picker"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginRight: "5px" }}
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              disabled={!anyMachine}
            />
            <TextField
              id="date-to"
              label="Date To"
              type="date"
              variant="outlined"
              margin="dense"
              className="date-picker"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginRight: "5px" }}
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
              disabled={!anyMachine}
            />
            <MaterialButton
              variant="contained"
              color="primary"
              style={{ margin: "10px" }}
              onClick={handleDisplayButtonClick}
              disabled={!anyMachine}
            >
              Display
            </MaterialButton>

            {selectedOption === "PumpHours" && (
              <MaterialButton
                variant="contained"
                color="primary"
                style={{ margin: "10px" }}
                onClick={handleDownloadButtonClick}
                disabled={!anyMachine}
              >
                Download
              </MaterialButton>
            )}
          </div>
        </div>
        {selectedOption === "PumpHours" && (
          <span className="order-history-header-subtitle" color="primary">
            <b>
              {cmsReportsTotalHours.value}:{" "}
              {pumpHoursData
                .reduce((sum, data) => sum + data.totalHours, 0)
                .toFixed(2)}
            </b>
          </span>
        )}
        {selectedOption === "PowerBiEmbeded" && (
          <span
            className="order-history-header-subtitle"
            color="primary"
          ></span>
        )}
      </div>
      {selectedOption === "PumpHours" && (
        <div className="chart-container" style={{ height: "350px" }}>
          {pumpHoursData && pumpHoursData.length > 0 ? (
            <canvas ref={chartRef} />
          ) : (
            <div className="no-data-message" style={{ textAlign: "center" }}>
              {!anyMachine
                ? cmsReportsUserNoDevice.value
                : cmsReportsNothingToShow.value}
            </div>
          )}
        </div>
      )}
      {selectedOption === "PowerBiEmbeded" && (
        <div className="PowerBiEmbeded">
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual and qna
              id: embedResponse.reportId, //"4407d493-2929-48a5-8144-b886f7f70520", // "report id",
              embedUrl: embedResponse.embedUrl,
              accessToken: embedResponse.accessToken,
              tokenType: models.TokenType.Embed,
              permissions: models.Permissions.All,
              settings: {
                navContentPaneEnabled: false,
                filterPaneEnabled: true,
                panes: {
                  filters: {
                    expanded: true,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
                layoutType:
                  windowWidth &&
                  windowWidth <= 900 &&
                  windowHeight &&
                  windowHeight <= 1024
                    ? models.LayoutType.MobilePortrait
                    : null,
              },
            }}
            eventHandlers={new Map([["loaded"], ["rendered"], ["error"]])}
            cssClassName="report-style-class"
            getEmbeddedComponent={(embedObject) => {
              setReport(embedObject);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ReportsView;
