import React from "react";
import Input from "components/generic/Input/Input";
import { cMSResources } from "index";

import "./AddressSection.scss";

/**
 * Address section
 *
 * @param {Object}   errors   form errors
 * @param {Function} register react-hook-form register handler
 */
function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const AddressSection = ({ errors, register }) => {
  var cmsStreet = findArrayElementByTitle(cMSResources, "{{Street}}");
  var cmsCity = findArrayElementByTitle(cMSResources, "{{City}}");
  var cmsState = findArrayElementByTitle(cMSResources, "{{State}}");
  var cmsZipCode = findArrayElementByTitle(cMSResources, "{{ZipCode}}");
  var cmsAddress = findArrayElementByTitle(cMSResources, "{{Address}}");

  return (
    <div className="address-section-wrapper">
      <div className="address-section-heading">{cmsAddress.value}</div>
      <div className="address-section-input-line">
        <Input
          inputRef={register({
            required: "The field is required",
          })}
          name="street"
          required
          type="text"
          error={errors.street?.message}
          variant="outlined"
          label={cmsStreet.value}
        />
        <div className="error-message">{errors.street?.message}</div>
      </div>
      <div className="address-section-input-line">
        <Input
          inputRef={register({
            required: "The field is required",
          })}
          name="city"
          required
          type="text"
          error={errors.city?.message}
          variant="outlined"
          label={cmsCity.value}
        />
        <div className="error-message">{errors.city?.message}</div>
      </div>
      <div className="address-section-input-line">
        <div>
          <Input
            inputRef={register({
              required: "Required",
            })}
            name="state"
            required
            type="text"
            error={errors.state?.message}
            variant="outlined"
            label={cmsState.value}
          />
          <div className="error-message">{errors.state?.message}</div>
        </div>
        <div>
          <Input
            inputRef={register({
              required: "The field is required",
            })}
            name="postalCode"
            required
            type="text"
            error={errors.postalCode?.message}
            variant="outlined"
            label={cmsZipCode.value}
          />
          <div className="error-message">{errors.postalCode?.message}</div>
        </div>
      </div>
    </div>
  );
};

export default AddressSection;
