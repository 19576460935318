import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Plus = ({ color }) => {
  return (
    <SvgIcon color={color} className='icon-plus'>
      <path className="a" d="M25,16.429H16.429V25H13.571V16.429H5V13.571h8.571V5h2.857v8.571H25Z" transform="translate(-3 -3)"/>
    </SvgIcon>
  );
};

export default Plus;
