import React, { useEffect, useState } from 'react';
import { useGlobalEvent, useDebouncedFn } from 'beautiful-react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import Plus from 'components/generic/Icon/Plus';
import Button from 'components/generic/Button/Button';
import { getUsers, setCurrentPage, toggleModal } from 'store/actions';
import ModalPopup from '../../components/Modal/Modal';
import TableComponent from '../../components/Table/Table';
import TableMobile from '../../components/TableMobile/TableMobile';
import { appInsights, cMSResources } from '../../index';

import './ManageUser.scss';

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  })
}

const ManageCustomer = () => {
  appInsights.startTrackPage('ManageCustomer');
  const show = useSelector((state) => state.showModal);
  const [popupClicked, clickPopup] = useState(false);
  const userClaims = useSelector((state) => state.claims);
  const dispatch = useDispatch();

  const currentRole = userClaims
    ? JSON.parse(userClaims.extension_CustomerMapping).CustomerMappings[0]
      .Role
    : null;
  const currentCustomer = currentRole && currentRole.includes('GlobalAdmin')
    ? 'All'
    : JSON.parse(userClaims.extension_CustomerMapping).CustomerMappings[0]
      .CustomerName;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const onWindowResize = useGlobalEvent('resize');

  const onWindowResizeHandler = useDebouncedFn(() => {
    setWindowWidth(window.innerWidth);
  }, 250);
  const inSimulationMode = useSelector((state) => state.inSimulationMode);

  onWindowResize(onWindowResizeHandler);
  useEffect(() => {
    !inSimulationMode && dispatch(getUsers(currentCustomer, 'Manage Users'));
  }, []);

  dispatch(setCurrentPage('Manage Users'));
  appInsights.stopTrackPage('ManageCustomer', null, {
    currentCustomer,
    currentRole,
  });

  var cmsOnboardUser = findArrayElementByTitle(cMSResources, "{{OnboardUser}}");

  return (
    <>
      {windowWidth
      && windowWidth <= 1080 ? (
        <div className="manage-user-mobile">
          <Button
            text={cmsOnboardUser.value}
            variant="contained"
            color="primary"
            size="fullWidth"
            startIcon={<Plus />}
            onClick={() => {
              dispatch(toggleModal(true));
              clickPopup(true);
            }}
          />
          {popupClicked ? (
            <ModalPopup
              show={show}
              isEdit={false}
              screenName="manage-user"
              onHide={() => {
                dispatch(toggleModal(false));
                clickPopup(false);
              }}
            />
          ) : null}
          <div className="manage-table">
            <TableMobile screenName="manage-user" />
          </div>
          {' '}
        </div>
        ) : (
          <div className="manage-customer">
            <Button
              text={cmsOnboardUser.value}
              variant="contained"
              color="primary"
              startIcon={<Plus />}
              size="onboard"
              onClick={() => {
                dispatch(toggleModal(true));
                clickPopup(true);
              }}
            />
            {popupClicked ? (
              <ModalPopup
                show={show}
                isEdit={false}
                screenName="manage-user"
                onHide={() => {
                  dispatch(toggleModal(false));
                  clickPopup(false);
                }}
              />
            ) : null}
            <div className="manage-table">
              <TableComponent screenName="manage-user" />
            </div>
          </div>
        )}
    </>
  );
};

export default ManageCustomer;
