import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PaginationArrow = () => (
  <SvgIcon width="16" height="17" viewBox="0 0 16 17">
    <g clipPath="url(#clip0)">
      <path d="M12.8946 7.67402L5.96909 0.748619C5.80891 0.588314 5.59508 0.5 5.36709 0.5C5.13909 0.5 4.92527 0.588314 4.76509 0.748619L4.25507 1.25851C3.9232 1.59076 3.9232 2.13076 4.25507 2.46251L10.0706 8.27804L4.24862 14.1C4.08844 14.2603 4 14.474 4 14.7019C4 14.93 4.08844 15.1437 4.24862 15.3042L4.75864 15.8139C4.91894 15.9742 5.13264 16.0625 5.36064 16.0625C5.58863 16.0625 5.80246 15.9742 5.96264 15.8139L12.8946 8.88219C13.0552 8.72138 13.1434 8.50667 13.1429 8.27842C13.1434 8.04929 13.0552 7.8347 12.8946 7.67402Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default PaginationArrow;
