import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Construction = ({ className, color }) => (
  <SvgIcon style={{color : color}} width="20" height="20" viewBox="0 0 20 20" className={className}>
    <path d="M13.2533 10.8756L11.4856 12.6433L16.4824 17.6401L18.2502 15.8724L13.2533 10.8756Z" />
    <path d="M14.5833 8.33337C16.1917 8.33337 17.5 7.02504 17.5 5.41671C17.5 4.93337 17.3667 4.48337 17.1583 4.08337L14.9083 6.33337L13.6667 5.09171L15.9167 2.84171C15.5167 2.63337 15.0667 2.50004 14.5833 2.50004C12.975 2.50004 11.6667 3.80837 11.6667 5.41671C11.6667 5.75837 11.7333 6.08337 11.8417 6.38337L10.3 7.92504L8.81667 6.44171L9.40833 5.85004L8.23333 4.67504L10 2.90837C9.025 1.93337 7.44167 1.93337 6.46667 2.90837L3.51667 5.85837L4.69167 7.03337H2.34167L1.75 7.62504L4.7 10.575L5.29167 9.98337V7.62504L6.46667 8.80004L7.05833 8.20837L8.54167 9.69171L2.36667 15.8667L4.13333 17.6334L13.6167 8.15837C13.9167 8.26671 14.2417 8.33337 14.5833 8.33337Z" />
  </SvgIcon>
);

export default Construction;
