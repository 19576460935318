import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VideoLibrary from 'components/generic/Icon/VideoLibrary';
import LandingPageTile from 'components/LandingPageTile/LandingPageTile';
import Paper from 'components/generic/Paper/Paper';
import BorderedHeading from 'components/generic/BorderedHeading/BorderedHeading';
import Button from 'components/generic/Button/Button';
import { getInitialVideos } from 'store/actions';
import config from './docsAndVideosConfig';
import { config as appConfig } from '../../index';
import VimeoVideo from './VimeoVideo/VimeoVideo';

import './DocsAndVideos.scss';

const videosPerSection = 3;

const SeeMoreButton = ({ showCaseId }) => (
  <Button
    text="See more"
    variant="text"
    color="primary"
    disableRipple
    className="videos-see-more-button"
    component={Link}
    to={`/docsAndVideos/${showCaseId}`}
  />
);

const DocsAndVideos = () => {
  const externalToken = appConfig?.vimeoToken;
  const showcasesIds = appConfig?.vimeoShowcases ? JSON.parse(appConfig.vimeoShowcases) : {};

  const vimeoData = useSelector(({ vimeo }) => vimeo);
  const section1Data = vimeoData.find((data) => data.title.id === showcasesIds.showcase1);
  const section2Data = vimeoData.find((data) => data.title.id === showcasesIds.showcase2);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInitialVideos({ showcase1: showcasesIds.showcase1, showcase2: showcasesIds.showcase2, externalToken }));
  }, []);

  return (
    vimeoData.length !== 0 ? (
      <div className="docs-and-videos">
        <div className="docs-and-videos-header shape_flex shape_flex--align-center">
          <VideoLibrary className="docs-and-videos-header-icon" color="primary" />
          <div className="shape_flex shape_flex--direction-column">
            <span className="docs-and-videos-header-title">
              Docs & Videos
            </span>
            <span className="docs-and-videos-header-description">
              Search Flow&apos;s knowledge base
            </span>
          </div>
        </div>
        <div className="docs-and-videos-tiles">
          {Object.values(config).map(({
            tileTitle, description, link, id, disabled,
          }) => (
            <div className="docs-and-videos-tile" key={id}>
              <LandingPageTile
                title={tileTitle}
                description={description}
                link={link}
                tileLinkIcon="navigate_next"
                id={id}
                key={id}
                className="docs-and-videos-tile"
                order={0}
                disabled={disabled}
              />
            </div>
          ))}
        </div>
        <Paper variant="gray" className="docs-and-videos-main-paper">
          <BorderedHeading
            bold={false}
            text={section1Data.title.name}
            button={section1Data.totalCount > videosPerSection && (
            <SeeMoreButton showCaseId={showcasesIds.showcase1} />
            )}
          />
          <div className="docs-and-videos-thumbs">
            {section1Data.videos?.length !== 0 && section1Data.videos.map((video) => (
              <VimeoVideo key={video.link} link={video.link} id={video.uri.substring(video.uri.lastIndexOf('/') + 1, video.uri.length)} />
            ))}
          </div>
        </Paper>
        <Paper variant="gray" className="docs-and-videos-main-paper">
          <BorderedHeading
            bold={false}
            text={section2Data.title.name}
            button={section2Data.totalCount > videosPerSection && (
            <SeeMoreButton showCaseId={showcasesIds.showcase2} />
            )}
          />
          <div className="docs-and-videos-thumbs">
            {section2Data.videos?.length !== 0 && section2Data.videos.map((video) => (
              <VimeoVideo key={video.link} link={video.link} id={video.uri.substring(video.uri.lastIndexOf('/') + 1, video.uri.length)} />
            ))}
          </div>
        </Paper>
      </div>
    ) : <></>
  );
};

export default DocsAndVideos;
