import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RoundedCorner = () => (
  <SvgIcon width="42" height="42" viewBox="0 0 42 42" fill="none">
    <path d="M33.25 33.25H36.75V36.75H33.25V33.25ZM33.25 29.75H36.75V26.25H33.25V29.75ZM5.25 22.75H8.75V19.25H5.25V22.75ZM5.25 29.75H8.75V26.25H5.25V29.75ZM5.25 15.75H8.75V12.25H5.25V15.75ZM5.25 8.75H8.75V5.25H5.25V8.75ZM12.25 8.75H15.75V5.25H12.25V8.75ZM26.25 36.75H29.75V33.25H26.25V36.75ZM19.25 36.75H22.75V33.25H19.25V36.75ZM26.25 36.75H29.75V33.25H26.25V36.75ZM12.25 36.75H15.75V33.25H12.25V36.75ZM5.25 36.75H8.75V33.25H5.25V36.75ZM36.75 14C36.75 9.17 32.83 5.25 28 5.25H19.25V8.75H28C30.8875 8.75 33.25 11.1125 33.25 14V22.75H36.75V14Z" />
  </SvgIcon>
);

export default RoundedCorner;
