import React, { useState, useEffect } from 'react';

import './MaxFileErrorMessage.scss';

const MaxFileErrorMessage = ({ showError, onTimeoutEnd }) => {
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (!showError) {
      return;
    }

    setCountdown(20);

    const interval = setInterval(() => {
      setCountdown((prevState) => {
        prevState - 1 < 0 && clearInterval(interval);
        return prevState - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [showError]);

  useEffect(() => {
    countdown && countdown < 0 && onTimeoutEnd();
  }, [countdown]);

  return (
    showError && countdown >= 0 && (
      <span className="pump-hours-max-file-error-message">
        One or more files are too large to be uploaded. Files larger than 3 MB are not currently supported.
      </span>
    )
  );
};

export default MaxFileErrorMessage;
