import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@material-ui/core/Button';
import classNames from 'classnames';

import './Button.scss';

const btnSize = (size) => `button_size--${size}`;

/**
 * Button
 *
 * @param {String} text      button text
 * @param {String} size      size of the button
 * @param {String} className specific class name
 */
const Button = ({
  text, size, className, ...props
}) => (
  <MaterialButton
    className={classNames('button', className, {
      [btnSize(size)]: size,
    })}
    classes={{
      outlinedPrimary: 'button-outlined-primary',
    }}
    {...props}
  >
    {text}
  </MaterialButton>
);

export default Button;

Button.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  text: '',
  size: '',
  className: '',
};
