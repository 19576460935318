import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CookieConsent from "react-cookie-consent";
import { isUserRoleGlobalAdmin, isCustomerAdmin } from "store/reducers";
import ManageCustomer from "Pages/ManageCustomer/ManageCustomer";
import ManageUser from "Pages/ManageUser/ManageUser";
import AccountInfo from "Pages/AccountInfo/AccountInfo";
import Home from "Pages/Home/Home";
import DrawerNav from "components/DrawerNav/DrawerNav";
import DocsAndVideos from "Pages/DocsAndVideos/DocsAndVideos";
import AllVideos from "Pages/DocsAndVideos/AllVideos/AllVideos";
import ContactUs from "Pages/ContactUs/ContactUs";
import OrderHistory from "Pages/OrderHistory/OrderHistory";
import OrderHistoryDetails from "Pages/OrderHistoryDetails/OrderHistoryDetails";
import Reports from "Pages/Reports/Reprts";
import ServiceHistory from "Pages/ServiceHistory/ServiceHistory";
import CMSPage from "Pages/CustomCMSPage1/CMSPage";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { setClaims } from "./store/actions";
import Footer from "./components/Footer/Footer";
import { claims, config, appInsights, cmsLogoAndCollor } from "./index";

import "./custom.scss";

const AuthIsNotGlobalAdmin = ({ isGlobalAdminRole, children }) => {
  const inSimulationMode = useSelector((state) => state.inSimulationMode);

  return !isGlobalAdminRole || inSimulationMode ? (
    children
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const AuthManageUser = () =>
  claims !== null &&
  (JSON.parse(
    claims.idTokenClaims.extension_CustomerMapping
  ).CustomerMappings[0].Role.includes("GlobalAdmin") ||
    JSON.parse(
      claims.idTokenClaims.extension_CustomerMapping
    ).CustomerMappings[0].Role.includes("CustomerTeamAdmin")) ? (
    <ManageUser />
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );

const AuthManageCustomer = () =>
  claims !== null &&
  JSON.parse(
    claims.idTokenClaims.extension_CustomerMapping
  ).CustomerMappings[0].Role.includes("GlobalAdmin") ? (
    <ManageCustomer />
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );

const AuthCustomerAdmin = ({ children }) => {
  const inSimulationMode = useSelector((state) => state.inSimulationMode);
  const isAdmin = useSelector(isCustomerAdmin);

  return inSimulationMode || isAdmin ? (
    children
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  const isGlobalAdminRole = useSelector(isUserRoleGlobalAdmin);
  appInsights.startTrackPage("Shapeportal");
  const dispatch = useDispatch();
  dispatch(
    setClaims(claims !== "" && claims !== null ? claims.idTokenClaims : {})
  );
  appInsights.stopTrackPage("Shapeportal", null, {
    claims: claims.idTokenClaims.emails[0],
  });

  const appClassName = "app-content";

  return (
    <div className="app-container">
      {claims ? (
        <div className={appClassName}>
          <ScrollToTop />
          <DrawerNav />
          <CookieConsent
            disableStyles
            location="top"
            overlay=""
            buttonText="Accept"
            buttonStyle={{ backgroundColor: cmsLogoAndCollor.color }}
            cookieName="ShapeTechConsentCookie"
            cookieValue={claims.idTokenClaims.emails[0]}
            buttonClasses="cookie-buttons"
            containerClasses="alert alert-warning col-lg-12"
            contentClasses="text-capitalize"
          >
            We use cookies on this website to support our offerings and deliver
            the best experience. By clicking “Accept” you agree to our cookie
            policy.
            <a
              href={cmsLogoAndCollor.cookiePolicyLink}
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: cmsLogoAndCollor.color }}
            >
              {"  "}
              Learn more
            </a>
          </CookieConsent>
          <div className="main-container">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/managecustomer" element={<AuthManageCustomer />} />
              <Route path="/manageuser" element={<AuthManageUser />} />
              <Route
                path="/accountinfo"
                element={
                  <AuthIsNotGlobalAdmin isGlobalAdminRole={isGlobalAdminRole}>
                    <AccountInfo />
                  </AuthIsNotGlobalAdmin>
                }
              />
              <Route
                path="docsAndVideos"
                element={
                  <AuthIsNotGlobalAdmin isGlobalAdminRole={isGlobalAdminRole}>
                    <DocsAndVideos />
                  </AuthIsNotGlobalAdmin>
                }
              />
              <Route path="docsAndVideos/:showcaseId" element={<AllVideos />} />
              <Route exact path="/contactUs" element={<ContactUs />} />
              <Route path="/orderHistory" element={<OrderHistory />} />
              <Route
                path="/orderHistoryDetails"
                element={<OrderHistoryDetails />}
              />
              <Route
                path="/serviceHistory"
                element={
                  <AuthIsNotGlobalAdmin>
                    <ServiceHistory />
                  </AuthIsNotGlobalAdmin>
                }
              />
              <Route path="/CMSPage" element={<CMSPage />} />
              <Route path="/reports" element={<Reports />} />
            </Routes>
          </div>
          <Footer />
        </div>
      ) : null}
    </div>
  );
};
export default App;
