import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import EventIcon from "@material-ui/icons/Event";
import PhoneIcon from "@material-ui/icons/Phone";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Modal from "components/generic/Modal/Modal";
import ModalButtons from "components/generic/Modal/ModalButtons/ModalButtons";
import Input from "components/generic/Input/Input";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { IconButton, InputAdornment } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import { scheduleTraining } from "store/actions";
import { cMSResources } from "../../index";

import "./ScheduleTrainingModal.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const isPhoneChar = (char) => char.match(/^[0-9 ()+-]$/g);
const isPhone = (value) => /^[\d ()+-]+$/.test(value) || "Invalid phone number";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const ScheduleTrainingModal = ({ open, onClose }) => {
  const inSimulationMode = useSelector((state) => state.inSimulationMode);
  const defaultValues = {
    Id: "",
    preferedDate: null,
    preferedTime: null,
    phoneNumber: "",
    serviceDetails: "",
  };

  const handleModalClose = () => {
    onClose();
  };

  const {
    trigger,
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur", reValidateMode: "onBlur", defaultValues });
  const dispatch = useDispatch();
  const defaultLanguage = sessionStorage.getItem("defaultLanguage");
  const classes = useStyles();

  const onSubmit = (data) => {
    console.log(defaultLanguage);
    dispatch(scheduleTraining(data, defaultLanguage));
    onClose();
  };

  // const onSubmit = (fData) => {
  //   const data = new FormData();

  //   Object.keys(fData).map((key) => data.append(key, fData[key]));

  //   data.append("culture", defaultLanguage);
  //   dispatch(scheduleTraining(data));
  //   handleModalClose();
  // };

  var cmsCancel = findArrayElementByTitle(cMSResources, "{{Cancel}}");
  var cmsScheduleTraining = findArrayElementByTitle(
    cMSResources,
    "{{ScheduleTraining}}"
  );
  var cmsScheduleTrainingTitle = findArrayElementByTitle(
    cMSResources,
    "{{ScheduleTrainingTitle}}"
  );
  var cmsScheduleTrainingPrefferedDate = findArrayElementByTitle(
    cMSResources,
    "{{ScheduleTrainingPrefferedDate}}"
  );
  var cmsScheduleTrainingPrefferedTime = findArrayElementByTitle(
    cMSResources,
    "{{ScheduleTrainingPrefferedTime}}"
  );
  var cmsScheduleTrainingPhoneNumber = findArrayElementByTitle(
    cMSResources,
    "{{ScheduleTrainingPhoneNumber}}"
  );
  var cmsScheduleTrainingDetails = findArrayElementByTitle(
    cMSResources,
    "{{ScheduleTrainingDetails}}"
  );
  var cmsScheduleTrainingRequestTraining = findArrayElementByTitle(
    cMSResources,
    "{{ScheduleTrainingRequestTraining}}"
  );

  const buttonsConfig = [
    {
      text: cmsCancel.value,
      handler: handleModalClose,
    },
    {
      text: cmsScheduleTrainingRequestTraining.value,
      // disabled: true,
      handler: handleSubmit(onSubmit),
      disabled: !isValid || inSimulationMode,
    },
  ];

  const onTimeInputKeyPress = (event) => {
    if (!isPhoneChar(event.nativeEvent.key)) {
      event.preventDefault();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      title="Schedule Training"
      className="book-service-modal"
      footer={<ModalButtons config={buttonsConfig} />}
    >
      <div className="book-service-modal-description">
        {cmsScheduleTrainingTitle.value}
      </div>
      <form className="book-service-modal-form" noValidate>
        <div className="book-service-modal-input-line">
          <InputLabel
            classes={{
              root: "generic_select__label",
              asterisk: "generic_select__asterisk",
            }}
            required
          >
            {cmsScheduleTrainingPrefferedDate.value}
          </InputLabel>
          <Controller
            control={control}
            name="preferedDate"
            rules={{ required: true }}
            render={(props) => (
              <DatePicker
                inputVariant="outlined"
                className="book-service-modal-date-time-picker"
                minDate={new Date()}
                value={props.value}
                onChange={(e) => {
                  props.onChange(e);
                  trigger("preferedDate");
                }}
                InputProps={{
                  placeholder: "Choose preferred date",
                  required: true,
                  error: !!errors.preferedDate,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className="book-service-modal-date-time-adornment"
                    >
                      <IconButton>
                        <EventIcon
                          color="primary"
                          className="book-service-modal-date-time-icon"
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {errors.preferedDate && (
            <div className="error-message">This field is required</div>
          )}
        </div>
        <div className="book-service-modal-input-line">
          <InputLabel
            classes={{
              root: "generic_select__label",
              asterisk: "generic_select__asterisk",
            }}
            required
          >
            {cmsScheduleTrainingPrefferedTime.value}
          </InputLabel>
          <Controller
            control={control}
            name="preferedTime"
            rules={{ required: true }}
            render={(props) => (
              <TimePicker
                inputVariant="outlined"
                className="book-service-modal-date-time-picker"
                value={props.value}
                onChange={(e) => {
                  props.onChange(e);
                  trigger("preferedTime");
                }}
                InputProps={{
                  placeholder: "Choose preferred time",
                  required: true,
                  error: !!errors.preferedTime,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className="book-service-modal-date-time-adornment"
                    >
                      <IconButton>
                        <ScheduleIcon
                          color="primary"
                          className="book-service-modal-date-time-icon"
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {errors.preferedTime && (
            <div className="error-message">This field is required</div>
          )}
        </div>
        <div className="book-service-modal-input-line">
          <Input
            inputRef={register({
              required: "The field is required",
              validate: {
                isPhoneNumber: isPhone,
              },
            })}
            name="phoneNumber"
            required
            type="tel"
            error={errors.phoneNumber?.message}
            variant="outlined"
            label={cmsScheduleTrainingPhoneNumber.value}
            onKeyPress={onTimeInputKeyPress}
            placeholder="Your phone number:"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="">
                  <IconButton>
                    <PhoneIcon
                      color="primary"
                      className="book-service-modal-phone-icon"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.phoneNumber?.message && (
            <div className="error-message">{errors.phoneNumber?.message}</div>
          )}
        </div>
        <div className="book-service-modal-input-line">
          <Input
            inputRef={register({
              required: "The field is required",
            })}
            name="trainingDetails"
            required
            error={errors.serviceDetails?.message}
            label={cmsScheduleTrainingDetails.value}
            type="text"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            placeholder="Describe more details"
            className="book-service-modal-message-input"
          />
          {errors.serviceDetails?.message && (
            <div className="error-message">
              {errors.serviceDetails?.message}
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default ScheduleTrainingModal;
