import React, { useEffect } from 'react';
import VideoLibrary from 'components/generic/Icon/VideoLibrary';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllVideos } from 'store/actions';
import Paper from 'components/generic/Paper/Paper';
import { config as appConfig } from '../../../index';
import VimeoVideo from '../VimeoVideo/VimeoVideo';

import './AllVideos.scss';

const AllVideos = () => {
  const externalToken = appConfig?.vimeoToken;
  const dispatch = useDispatch();
  const params = useParams();

  const vimeoData = useSelector(({ vimeo }) => vimeo);
  const showcaseData = vimeoData.find((data) => `${data.title.id}` === params.showcaseId);

  useEffect(() => {
    dispatch(getAllVideos({ showcaseId: params.showcaseId, externalToken }));
  }, []);
  return (
    showcaseData ? (
      <div className="all-videos">
        <div className="docs-and-videos-header shape_flex shape_flex--align-center">
          <VideoLibrary className="docs-and-videos-header-icon" color="primary" />
          <div className="shape_flex shape_flex--direction-column">
            <span className="docs-and-videos-header-title">
              {showcaseData.title.name}
            </span>
          </div>
        </div>
        <div className="all-videos-tiles">
          {showcaseData.videos?.length !== 0 && showcaseData.videos.map((video) => (
            <Paper variant="gray" className="all-videos-tiles-tile">
              <div className="all-videos-tiles-tile-title">{video.name}</div>
              <VimeoVideo key={video.link} link={video.link} id={video.uri.substring(video.uri.lastIndexOf('/') + 1, video.uri.length)} />
            </Paper>
          ))}
        </div>
      </div>
    ) : <></>
  );
};

export default AllVideos;
