import React from 'react';
import classNames from 'classnames';
import MuiModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from 'components/generic/Button/Button';
import { cmsLogoAndCollor } from '../../../index';

import './InfoModal.scss';

/**
 * Info modal
 *
 * @param {Boolean} open         indicates if the modal is opened
 * @param {Boolean} onClose      called when the modal is closed
 * @param {Boolean} title        title of the modal
 * @param {Boolean} className    additional classes
 * @param {Boolean} children     children
 */
const InfoModal = ({
  open, onClose, title, className, children,
}) => (
  <MuiModal
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
    className={classNames('generic-info-modal', { [className]: className })}
  >
    <Fade in={open}>
      <div className="generic-info-modal-window">
        <div style={{color: cmsLogoAndCollor.color}} className="generic-info-modal-title">{title}</div>
        <div className="generic-info-modal-body">
          {children}
        </div>
        <div className="generic-info-modal-footer">
          <Button
            text="Close"
            variant="contained"
            color="primary"
            size="modal-btn"
            onClick={onClose}
            className="account-modal-button"
          />
        </div>
      </div>
    </Fade>
  </MuiModal>
);

export default InfoModal;
