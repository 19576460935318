import React from "react";
import Button from "components/generic/Button/Button";

import "./ModalButtons.scss";

/**
 * Modal buttons
 *
 * @param {Object[]} config buttons configuration
 */
const ModalButtons = ({ config }) => {
  const [button1, button2] = config;

  return (
    <>
      <Button
        text={button1.text}
        variant="outlined"
        color="primary"
        size="modal-btn"
        onClick={button1.handler}
        className="generic-modal-button"
      />
      {button2 && (
        <Button
          text={button2.text}
          variant="contained"
          color="primary"
          size="modal-btn"
          onClick={button2.handler}
          className="generic-modal-button"
          disabled={button2.disabled}
          display="none"
        />
      )}
    </>
  );
};

export default ModalButtons;
