import React from 'react';
import classNames from 'classnames';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import './AccountInfoSecondaryTile.scss';

/**
 * Secondary tile. Used in account info page
 *
 * @param {String}   heading        text in the header
 * @param {Function} onEdit         called when the edit button is clicked
 * @param {String}   className      additional classes
 * @param {Boolean}  headingBorder  indicates if the header has border
 * @param {Boolean}  showEditButton indicates if the edit button should be shown
 * @param {Object}   children       children
 */
const AccountInfoSecondaryTile = ({
  heading, onEdit, className, headingBorder = true, showEditButton = true, children,
}) => (
  <div className={classNames('account-info-secondary-tile', { [className]: className })}>
    <div className={classNames('account-info-secondary-tile--header', { 'account-info-secondary-tile--header-no-border': !headingBorder })}>
      <div className="account-info-secondary-tile--header-text">
        {heading}
      </div>
      {showEditButton && onEdit && (
        <IconButton onClick={onEdit} className="account-info-secondary-tile--edit-btn" edge="end" color="primary">
          <EditIcon classes={{ root: 'account-info-edit-icon-root' }} />
        </IconButton>
      )}
    </div>
    <div className="account-info-secondary-tile--body">
      {children}
    </div>
  </div>
);

export default AccountInfoSecondaryTile;
