import React from 'react';
import classNames from 'classnames';

import './Paper.scss';

/**
 * Simple wrapper component with background and box-shadow
 *
 * @param {String} variant   variant of the component
 * @param {String} className additional classes
 * @param {Number} padding   padding
 * @param {Object} children  children
 */
const Paper = ({
  variant = 'white', className, padding, children,
}) => (
  <div
    style={{ padding }}
    className={classNames('generic-paper', className, {
      'generic-paper-white': variant === 'white',
      'generic-paper-gray': variant === 'gray',
    })}
  >
    {children}
  </div>
);

export default Paper;
