import React from 'react';
import Button from 'components/generic/Button/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Link } from 'react-router-dom';

const DialogBox = ({
  checkOpen, handleToClose, displayMessage, enteringSimulation, user, formData, onSubmit,
}) => (
  <Dialog
    open={checkOpen}
    onClose={handleToClose}
    PaperProps={{ classes: { root: 'modal-root' } }}
  >
    <DialogContentText
      style={{ textAlign: 'center', color: '5b6770', fontSize: '1.25rem' }}
    >
      {displayMessage}
    </DialogContentText>
    <DialogContent>
      {enteringSimulation && (
      <DialogContentText
        style={{
          textAlign: 'center',
          color: '#007681',
          fontWeight: 'bold',
          fontSize: '1rem',
        }}
      >
        {`${user?.givenName} ${user?.surname} | ${formData?.Roles[0]}`}
        <br />
        {user?.extension_CustomerMapping.CustomerMappings[0].CustomerName}
        {' '}
        -
        {user?.extension_CustomerMapping.CustomerMappings[0].CustomerNumber}
        <br />
        {user?.mail}
      </DialogContentText>
      )}
    </DialogContent>
    <DialogActions>
      <Button
        text="No"
        variant="outlined"
        color="primary"
        size="modal-btn"
        onClick={handleToClose}
      />
      {!enteringSimulation ? (
        <Link to="/">
          <Button
            text="Yes"
            variant="contained"
            color="primary"
            size="modal-btn"
            onClick={onSubmit}
          />
        </Link>
      ) : (
        <Button
          text="Yes"
          variant="contained"
          color="primary"
          size="modal-btn"
          onClick={onSubmit}
        />
      )}
    </DialogActions>
  </Dialog>
);

export default DialogBox;
