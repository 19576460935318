import React from 'react';
import OrderHistoryIcon from 'components/generic/Icon/OrderHistory';
import { useSelector } from 'react-redux';
import { getCurrentRoleConfig } from 'store/reducers';
import MaterialButton from '@material-ui/core/Button';
import PastOrdersDetails from './PastOrdersDetails/PastOrdersDetails';
import { cmsLogoAndCollor, cMSResources } from '../../index';

import './OrderHistoryDetails.scss';

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  })
}

const OrderHistory = () => {
  const roleConfig = useSelector(getCurrentRoleConfig);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('oNo');
  var cmsBackBtn = findArrayElementByTitle(cMSResources, "{{Back}}");
  var cmsPartsOrdersDetailsTitle = findArrayElementByTitle(cMSResources, "{{PartsOrdersDetailsTitle}}");
  var cmsPartsOrdersDetailsDescription = findArrayElementByTitle(cMSResources, "{{PartsOrdersDetailsDescription}}");
  
  return (
    <div className="order-history order-history-wrapper">
      <div className="order-history-header shape_flex shape_flex--space-between shape_flex--align-center">
        <div className="order-history-header-text shape_flex shape_flex--align-center">
          <OrderHistoryIcon className="order-history-header-icon" color={cmsLogoAndCollor.color} />
          <div className="shape_flex shape_flex--direction-column">
            <span className="order-history-header-title">
            {cmsPartsOrdersDetailsTitle.value}: {id}
            </span>
            <span className="order-history-header-description">
            {cmsPartsOrdersDetailsDescription.value}
            </span>
          </div>
        </div>
        <MaterialButton variant="contained" color="primary" href={"/orderHistory"}>
          {cmsBackBtn.value}
        </MaterialButton>
      </div>
      <PastOrdersDetails />
    </div>
  );
};

export default OrderHistory;
