import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reducers/index';

function createStore(preloadedState) {
  const store = configureStore({
    reducer: appReducer,
    devTools: true,
    preloadedState,
  });

  return store;
}

const store = createStore();

export default store;
