import React, { useEffect, useState } from "react";
import EventIcon from "@material-ui/icons/Event";
import PlaceIcon from "@material-ui/icons/Place";
import { useSelector, useDispatch } from "react-redux";
import { getServices, getCustomerNumber } from "store/reducers";
import { getServiceHistory } from "store/actions";
import Paper from "components/generic/Paper/Paper";
import Table from "components/generic/Table/Table/Table";
import TablePagination from "components/generic/Table/TablePagination/TablePagination";
import TableCell from "components/generic/Table/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { cMSResources } from "../../../index";
import { format } from "date-fns";

import "./ServiceHistoryTable.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const rowsPerPage = 25;

const ServiceHistoryTable = () => {
  const services = useSelector(getServices);
  const customerNumber = useSelector(getCustomerNumber);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const handleChangeRowsPerPage = () => {
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    dispatch(getServiceHistory(customerNumber));

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, customerNumber]);

  const pagedResult =
    services &&
    services.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  var cmsWorkOrderNumber = findArrayElementByTitle(
    cMSResources,
    "{{WorkOrderNumber}}"
  );
  var cmsWorkOrderType = findArrayElementByTitle(
    cMSResources,
    "{{WorkOrderType}}"
  );
  var cmsScheduleDate = findArrayElementByTitle(
    cMSResources,
    "{{ScheduleDate}}"
  );
  var cmsWorkOrderStatus = findArrayElementByTitle(
    cMSResources,
    "{{WorkOrderStatus}}"
  );
  var cmsLocation = findArrayElementByTitle(cMSResources, "{{Location}}");
  return (
    <>
      <div className="service-history-table-container">
        {/* Render Tiles for Mobile */}
        {isMobile ? (
          <div className="mobile-tiles">
            {pagedResult &&
              pagedResult.map((row) => (
                <div key={row.id} className="mobile-tile">
                  <div>
                    {cmsWorkOrderNumber.value}: {row.workOrderNumber}
                  </div>
                  <div>
                    {cmsWorkOrderType.value}: {row.workOrderType}
                  </div>
                  <div>
                    <EventIcon color="primary" /> {cmsScheduleDate.value}:{" "}
                    {format(new Date(row.scheduledDate), "MM/dd/yyyy")}
                  </div>
                  <div>
                    {cmsWorkOrderStatus.value}: {row.workOrderStatus}
                  </div>
                  <div>
                    <PlaceIcon color="primary" /> {cmsLocation.value}:{" "}
                    {row.location}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <Paper variant="gray" className="service-history-table-paper">
            <Table
              head={
                <>
                  <TableCell>{cmsWorkOrderNumber.value}</TableCell>
                  <TableCell>{cmsWorkOrderType.value}</TableCell>
                  <TableCell>
                    <EventIcon color="primary" /> {cmsScheduleDate.value}
                  </TableCell>
                  <TableCell>{cmsWorkOrderStatus.value}</TableCell>
                  <TableCell>
                    <PlaceIcon color="primary" /> {cmsLocation.value}
                  </TableCell>
                </>
              }
            >
              {pagedResult &&
                pagedResult.map((row) => (
                  <TableRow key={row.id} className="generic-table-body-row">
                    <TableCell>{row.workOrderNumber}</TableCell>
                    <TableCell>{row.workOrderType}</TableCell>
                    <TableCell>
                      {format(new Date(row.scheduledDate), "MM/dd/yyyy")}
                    </TableCell>
                    <TableCell>{row.workOrderStatus}</TableCell>
                    <TableCell>{row.location}</TableCell>
                  </TableRow>
                ))}
            </Table>
          </Paper>
        )}
      </div>

      {pagedResult && (
        <TablePagination
          count={services.length}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default ServiceHistoryTable;
