import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import './Input.scss';

/**
 * Input field with label (optional)
 *
 * @param {String}  error     error
 * @param {String}  className additional classes
 * @param {String}  variant   variant
 * @param {String}  label     label
 * @param {Boolean} required  is the field required
 * @param {Boolean} disabled  is the field disabled
 */
const Input = ({
  error, className = '', variant, label, required, disabled, ...props
}) => (
  <div className="generic-input">
    {label && variant === 'outlined' && (
      <InputLabel
        classes={{
          root: 'input-label-root',
          asterisk: 'input-label-asterisk',
          disabled: 'input-label-disabled',
        }}
        required={required}
        disabled={disabled}
      >
        {label}
      </InputLabel>
    )}
    <TextField
      classes={{ root: `generic-input-root ${className}` }}
      label={variant === 'outlined' ? null : label}
      variant={variant}
      color="secondary"
      disabled={disabled}
      InputProps={{
        classes: {
          root: 'input-base-root',
        },
      }}
      InputLabelProps={{
        classes: {
          root: 'input-integrated-label-root',
        },
      }}
      {...(error ? { error: true } : {})}
      {...props}
    />
  </div>
);

export default Input;
