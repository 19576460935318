import React from "react";
import OrderHistoryIcon from "components/generic/Icon/OrderHistory";
import { useSelector } from "react-redux";
import { getCurrentRoleConfig } from "store/reducers";
import MaterialButton from "@material-ui/core/Button";
import PastOrders from "./PastOrders/PastOrders";
import { cmsLogoAndCollor, cMSResources } from "../../index";

import "./OrderHistory.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const OrderHistory = () => {
  const roleConfig = useSelector(getCurrentRoleConfig);
  var cmsPartsOrders = findArrayElementByTitle(cMSResources, "{{PartsOrders}}");
  var cmsPurchaseParts = findArrayElementByTitle(
    cMSResources,
    "{{PurchaseParts}}"
  );

  return (
    <div className="order-history order-history-wrapper">
      <div className="order-history-header shape_flex shape_flex--space-between shape_flex--align-center">
        <div className="order-history-header-text shape_flex shape_flex--align-center">
          <OrderHistoryIcon
            className="order-history-header-icon"
            color={cmsLogoAndCollor.color}
          />
          <div className="shape_flex shape_flex--direction-column">
            <span className="order-history-header-title">
              {cmsPartsOrders.value}
            </span>
          </div>
        </div>
        <MaterialButton
          variant="contained"
          color="primary"
          href={roleConfig.navItems.purchase.link}
          target="_blank"
        >
          {cmsPurchaseParts.value}
        </MaterialButton>
      </div>
      <PastOrders />
    </div>
  );
};

export default OrderHistory;
