export default {
  knowledgeBase: {
    id: 'knowledgeBase',
    link: '/docsAndVideos',
    tileTitle: 'Knowledge Base',
    description: 'Browse technical documents and more',
    tileLinkIcon: 'navigate_next',
    disabled: true,
  },
  // disabled for now. Saved for later use
  // installationAndService: {
  //   id: 'installationAndService',
  //   tileTitle: 'Installation & Service',
  //   link: '/docsAndVideos',
  //   description: 'Install and service guidelines',
  //   tileLinkIcon: 'navigate_next',
  //   disabled: false,
  // },
  // operationAndMaintenance: {
  //   id: 'operationAndMaintenance',
  //   tileTitle: 'Operation & Maintenance',
  //   link: '/docsAndVideos',
  //   description: 'Recommendations for daily machine operations',
  //   tileLinkIcon: 'navigate_next',
  //   disabled: true,
  // },
  // operationAndMaintenancePump: {
  //   id: 'operationAndMaintenancePump',
  //   tileTitle: 'Operation & Maintenance - Pump',
  //   link: '/docsAndVideos',
  //   description: 'Recommendations for daily machine operations',
  //   tileLinkIcon: 'navigate_next',
  //   disabled: true,
  // },
  // operationAndMaintenanceAncillary: {
  //   id: 'operationAndMaintenanceAncillary',
  //   tileTitle: 'Operation & Maintenance – Ancillary',
  //   link: '/docsAndVideos',
  //   description: 'Recommendations for daily machine operations',
  //   tileLinkIcon: 'navigate_next',
  //   disabled: true,
  // },
  // safety: {
  //   id: 'safety',
  //   tileTitle: 'Safety',
  //   link: '/docsAndVideos',
  //   description: 'Recommendations for safe operations',
  //   tileLinkIcon: 'navigate_next',
  //   disabled: true,
  // },
  // softwareAndApplications: {
  //   id: 'softwareAndApplications',
  //   tileTitle: 'Software & Applications',
  //   link: '/docsAndVideos',
  //   description: 'Operational software guides',
  //   tileLinkIcon: 'navigate_next',
  //   disabled: true,
  // },
};
