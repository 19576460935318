import React from "react";
import * as Msal from "msal";
import ReactDOM from "react-dom";
import {
  StylesProvider,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import PasswordResetSuccess from "components/PasswordResetSuccess/PasswordResetSuccess";
import DateFnsUtils from "@date-io/date-fns";
import store from "store/index";
import request from "api/request";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { defaultLanguage } from "components/DrawerNav/DrawerNav";

let msalConfig;
let userMSALObj;
let b2cPolicies;

export let claims = null;
export let config = null;
export let appInsights = null;
export let accessToken = null;
export let tilesDetails = null;
export let customTilesDetails = null;
export let cMSResources = null;
export let cmsLogoAndCollor = null;
export let webPages = null;
export let cmsLicenseAgreement = null;
export let hasAcceptEULA = false;
export let mainSettings = false;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#4ba5b0",
      main: "#007681",
      dark: "#004a55",
      contrastText: "#fff",
    },
    secondary: {
      main: "#888B8D",
    },
    action: {
      disabled: "#fff",
      disabledBackground: "#888B8D",
    },
    text: {
      primary: "#5B6770",
    },
  },
  breakpoints: {
    values: {
      mobile: 392,
      tablet: 640,
      desktop: 1168,
    },
  },
});

const getRedirectUrlOnFirstLogin = (instance, tenantId, clientId, state) => {
  const currentUrl = window.location.href;
  const redirectUri =
    currentUrl.slice(-1) === "#" ? currentUrl.slice(0, -1) : currentUrl;

  return `${instance}${tenantId}/B2C_1A_ForcePasswordReset_signup_signin/oauth2/v2.0/authorize?response_type=id_token&scope=openid%20profile&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
};

const FetchConfigValues = async (language) =>
  await request({ urlSlug: `api/UserData/GetConfigValues/${language}` }).then(
    (res) => {
      b2cPolicies = {
        names: {
          SignIn: res.SignInPolicy,
          forgotPassword: res.ForgotPasswordPolicy,
        },
        authorities: {
          SignIn: {
            authority: `${res.Instance + res.TenantId}/${res.SignInPolicy}`,
          },
          forgotPassword: {
            authority: `${res.Instance + res.TenantId}/${
              res.ForgotPasswordPolicy
            }`,
          },
        },
      };
      msalConfig = {
        auth: {
          clientId: res.ClientId,
          authority: b2cPolicies.authorities.SignIn.authority,
          validateAuthority: false,
        },
        cache: {
          cacheLocation: "localStorage", // This configures where your cache will be stored
          storeAuthStateInCookie: true, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
        },
      };
      config = res;
    }
  );

const FetchLogoAndColor = async (language) =>
  request({ urlSlug: `api/CmsData/GetLogoAndColor/${language}` }).then(
    (res) => {
      theme.palette.main = res.color;
      theme.palette.primary.main = res.color;
      theme.palette.dark = res.hoverColor;
      theme.palette.primary.dark = res.hoverColor;
      cmsLogoAndCollor = res;
    }
  );

const FetchTileFeatures = async (language) =>
  request({ urlSlug: `api/CmsData/GetTileFeatures/${language}` }).then(
    (res) => {
      tilesDetails = res;
    }
  );

const FetchCustomTileFeatures = async (language) =>
  request({ urlSlug: `api/CmsData/GetCustomTileFeatures/${language}` }).then(
    (res) => {
      customTilesDetails = res;
    }
  );

const FetchResourcesCMS = async (language) =>
  request({ urlSlug: `api/CmsData/GetResources/${language}` }).then((res) => {
    cMSResources = res;
  });

const FetchwebPages = async (language) =>
  request({ urlSlug: `api/CmsData/GetWebPages/${language}` }).then((res) => {
    webPages = res;
  });

const FetchLicenseAgreementCMS = async (language) =>
  request({ urlSlug: `api/CmsData/GetlicenseAgreement/${language}` }).then(
    (res) => {
      cmsLicenseAgreement = res;
    }
  );

const FetchHasAcceptedLicenseAgreement = async () =>
  request({
    urlSlug: `api/LicenseAgreement/GetLicenseAgreement`,
    method: "GET",
  }).then((res) => {
    hasAcceptEULA = res;
  });

const FetchMainSettings = async (language) =>
  request({
    urlSlug: `api/CmsData/GetMainSettings/${language}`,
    method: "GET",
  }).then((res) => {
    mainSettings = res;
  });

function authRedirectCallBack(error, response) {
  // Error handling
  if (error) {
    console.log(error);

    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    const forgotPwdErrorCode = "AADB2C90118";
    if (error.errorMessage.indexOf(forgotPwdErrorCode) > -1) {
      try {
        // Password reset policy/authority
        userMSALObj.loginRedirect({
          authority: b2cPolicies.authorities.forgotPassword.authority,
        });
      } catch (err) {
        console.log(err);
      }
    }
  } else {
    // We need to reject id tokens that were not issued with the default sign-in policy.
    // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
    // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
    if (
      response.tokenType === "id_token" &&
      response.idToken.claims.tfp !== b2cPolicies.names.SignIn
    ) {
      const rootElement = document.getElementById("root");
      ReactDOM.render(
        <ReduxProvider store={store}>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <PasswordResetSuccess msalObj={userMSALObj} />
            </ThemeProvider>
          </StylesProvider>
        </ReduxProvider>,
        rootElement
      );
    } else if (
      response.tokenType === "id_token" &&
      response.idToken.claims.tfp === b2cPolicies.names.SignIn &&
      userMSALObj.getAccount()
    ) {
      claims = userMSALObj.getAccount();
      accessToken = response.idToken.rawIdToken;

      appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: config.InstrumentationKey,
        },
      });

      appInsights.loadAppInsights();

      const registrationStatus = claims
        ? claims.idTokenClaims.extension_Registered
        : null;
      if (registrationStatus === false && registrationStatus !== null) {
        window.location.href = getRedirectUrlOnFirstLogin(
          config.Instance,
          config.TenantId,
          config.ClientId,
          userMSALObj.redirectResponse.accountState
        );
        return;
      }

      // render react
      const baseUrl = document
        .getElementsByTagName("base")[0]
        .getAttribute("href");
      const rootElement = document.getElementById("root");
      ReactDOM.render(
        <ReduxProvider store={store}>
          <BrowserRouter basename={baseUrl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <StylesProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </StylesProvider>
            </MuiPickersUtilsProvider>
          </BrowserRouter>
        </ReduxProvider>,
        rootElement
      );
      registerServiceWorker();
    }
  }
}

export const manageAuth = async (action) => {
  const { pathname, origin } = window.location;

  if (
    action == "en" ||
    action == "de" ||
    action == "fr" ||
    action == "esp" ||
    action == "it"
  ) {
    window.location.reload(false);
  }

  const defaultLanguage = sessionStorage.getItem("defaultLanguage");

  // if (action == "en" || action == "de" || action == "fr" || action == "esp") {
  //   window.location.reload(false);
  // }

  if (action === "reset-password") {
    userMSALObj.loginRedirect({
      authority: b2cPolicies.authorities.forgotPassword.authority,
    });
    return;
  }
  if (action === "logout") {
    userMSALObj.logout();
    return;
  }
  // Fetch config values to create msal object
  await FetchConfigValues(defaultLanguage);
  await FetchLogoAndColor(defaultLanguage);
  await FetchTileFeatures(defaultLanguage);
  await FetchResourcesCMS(defaultLanguage);
  await FetchMainSettings(defaultLanguage);
  await FetchCustomTileFeatures(defaultLanguage);
  await FetchLicenseAgreementCMS(defaultLanguage);

  const msalConfigExtended = {
    ...msalConfig,
    auth: {
      ...msalConfig.auth,
      redirectUri: origin,
    },
  };

  userMSALObj = new Msal.UserAgentApplication(msalConfigExtended);
  userMSALObj.handleRedirectCallback(authRedirectCallBack);
  const { redirectResponse } = userMSALObj;
  if (
    claims === null &&
    !(
      redirectResponse &&
      redirectResponse.tokenType === "id_token" &&
      redirectResponse.idToken.claims.tfp !== b2cPolicies.names.SignIn
    )
  ) {
    userMSALObj.loginRedirect({
      scopes: ["openid", "profile"],
      state: pathname,
    });
  }
  if (accessToken != null) {
    await FetchHasAcceptedLicenseAgreement();
    await FetchwebPages(defaultLanguage);
  }
};

if (!claims) {
  manageAuth();
}
