import React from 'react';
import { useDispatch } from 'react-redux';
import Button from 'components/generic/Button/Button';
import Construction from 'components/generic/Icon/Construction';
import { toggleRequestServiceModal } from 'store/actions';
import ServiceHistoryTable from './ServiceHistoryTable/ServiceHistoryTable';
import { cmsLogoAndCollor, cMSResources } from '../../index';

import './ServiceHistory.scss';

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  })
}

const ServiceHistory = () => {
  const dispatch = useDispatch();

  const openServiceModal = () => {
    dispatch(toggleRequestServiceModal());
  };

  var cmsServiceActivity = findArrayElementByTitle(cMSResources, "{{ServiceActivity}}");
  var cmsRequestService = findArrayElementByTitle(cMSResources, "{{RequestServiceBtn}}");

  return (
    <div className="service-history">
      <div className="service-history-header shape_flex shape_flex--space-between shape_flex--align-center">
        <div className="service-history-header-text shape_flex shape_flex--align-center">
          <Construction className="service-history-header-icon" color={cmsLogoAndCollor.color} />
          <span className="service-history-header-title">
          {cmsServiceActivity.value}
          </span>
        </div>
        <Button
          text={cmsRequestService.value}
          variant="contained"
          color="primary"
          onClick={openServiceModal}
          className="service-history-header-button"
        />
      </div>
      <ServiceHistoryTable />
    </div>
  );
};

export default ServiceHistory;
