import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const VideoLibrary = ({ className }) => (
  <SvgIcon className={className} width="36" height="35" viewBox="0 0 36 35">
    <path d="M4 7.25002H0.5V31.1667C0.5 33.0459 2.075 34.5834 4 34.5834H28.5V31.1667H4V7.25002ZM32 0.416687H11C9.075 0.416687 7.5 1.95419 7.5 3.83335V24.3334C7.5 26.2125 9.075 27.75 11 27.75H32C33.925 27.75 35.5 26.2125 35.5 24.3334V3.83335C35.5 1.95419 33.925 0.416687 32 0.416687ZM18 21.7709V6.39585L28.5 14.0834L18 21.7709Z" />
  </SvgIcon>
);

export default VideoLibrary;
