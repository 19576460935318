import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/generic/Button/Button";
import { getLogoAndColor } from "store/actions";
import DescriptionIcon from "@material-ui/icons/Description";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Link from "@material-ui/core/Link";
import { cMSResources } from "../../../index";
import { toggleLicenseAgreementModal } from "store/actions";

import "./LicenseAgreementSection.scss";

/**
 * License agreement section rendered in Account Info page only for Customer Admin users
 *
 * @param {String} waterJetLink link to WaterJet Connect
 */
function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const LicenseAgreementSection = ({ waterJetLink }) => {
  const dispatch = useDispatch();
  const logoAndColor = useSelector((state) => state.logoAndColor);
  var cmsWaterjetConnectLicense = findArrayElementByTitle(
    cMSResources,
    "{{WaterjetConnectLicense}}"
  );
  var cmsReadLicenseAgreement = findArrayElementByTitle(
    cMSResources,
    "{{ReadLicenseAgreement}}"
  );

  const openServiceModal = () => {
    dispatch(toggleLicenseAgreementModal());
  };

  return (
    <div className="license-agreement-section">
      <div className="license-header-wrapper">
        <DescriptionIcon color="primary" />{" "}
        <span className="license-header-text">
          {cmsWaterjetConnectLicense.value}
        </span>
      </div>
      <Button
        text={cmsReadLicenseAgreement.value}
        variant="contained"
        color="primary"
        size="licenseAgreementWidth"
        onClick={openServiceModal}
      />
    </div>
  );
};

export default LicenseAgreementSection;
