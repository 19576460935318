import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import EventIcon from "@material-ui/icons/Event";
import PhoneIcon from "@material-ui/icons/Phone";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Modal from "components/generic/Modal/Modal";
import ModalButtons from "components/generic/Modal/ModalButtons/ModalButtons";
import Button from "components/generic/Button/Button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MaxFileErrorMessage from "./MaxFileErrorMessage/MaxFileErrorMessage";
import ChosenImages from "./ChosenImages/ChosenImages";
import Input from "components/generic/Input/Input";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { IconButton, InputAdornment } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import { requestService } from "store/actions";
import BookServiceModalSelect from "./BookServiceModalSelect/BookServiceModalSelect";
import { cMSResources } from "../../index";

import "./RequestServiceModal.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const isPhoneChar = (char) => char.match(/^[0-9 ()+-]$/g);
const isPhone = (value) => /^[\d ()+-]+$/.test(value) || "Invalid phone number";
const IMAGES_LIMIT = 3;
const TREE_MB = 3145728;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const RequestServiceModal = ({ open, onClose }) => {
  const inSimulationMode = useSelector((state) => state.inSimulationMode);
  const defaultValues = {
    Id: "",
    preferedDate: null,
    preferedTime: null,
    phoneNumber: "",
    serviceDetails: "",
  };
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileSizeErrorMessageShow, setFileSizeErrorMessageShow] =
    useState(false);

  const handleModalClose = () => {
    setUploadedFiles([]);
    setFileSizeErrorMessageShow(false);
    onClose();
  };

  const {
    trigger,
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur", reValidateMode: "onBlur", defaultValues });
  const dispatch = useDispatch();
  const defaultLanguage = sessionStorage.getItem("defaultLanguage");
  const classes = useStyles();

  const onSubmit = (fData) => {
    const data = new FormData();
    Object.keys(fData).forEach((key) => data.append(key, fData[key]));
    uploadedFiles.forEach((file) => data.append("files", file, file.name));
  
    data.append("culture", defaultLanguage);
    dispatch(requestService(data));
    handleModalClose();
  };

  var cmsRequestServiceBtn = findArrayElementByTitle(
    cMSResources,
    "{{RequestServiceBtn}}"
  );
  var cmsCancel = findArrayElementByTitle(cMSResources, "{{Cancel}}");

  var cmsRequestServiceHeader = findArrayElementByTitle(
    cMSResources,
    "{{RequestServiceHeader}}"
  );

  const buttonsConfig = [
    {
      text: cmsCancel.value,
      handler: handleModalClose,
    },
    {
      text: cmsRequestServiceBtn.value,
      handler: handleSubmit(onSubmit),
      disabled: !isValid || inSimulationMode,
    },
  ];

  const onTimeInputKeyPress = (event) => {
    if (!isPhoneChar(event.nativeEvent.key)) {
      event.preventDefault();
    }
  };

  var cmsRequestServiceTitle = findArrayElementByTitle(
    cMSResources,
    "{{RequestServiceTitle}}"
  );
  var cmsRequestServiceDate = findArrayElementByTitle(
    cMSResources,
    "{{RequestServiceDate}}"
  );
  var cmsRequestServiceTime = findArrayElementByTitle(
    cMSResources,
    "{{RequestServiceTime}}"
  );
  var cmsRequestServicePhone = findArrayElementByTitle(
    cMSResources,
    "{{RequestServicePhone}}"
  );
  var cmsRequestServiceDescription = findArrayElementByTitle(
    cMSResources,
    "{{RequestServiceDescription}}"
  );
  var cmsPhotoUpload = findArrayElementByTitle(cMSResources, "{{PhotoUpload}}");
  var cmsAddPhoto = findArrayElementByTitle(cMSResources, "{{AddPhoto}}");
  var cmsNoPhotosChosen = findArrayElementByTitle(
    cMSResources,
    "{{NoPhotosChosen}}"
  );
  var cmsPhotoRestriction = findArrayElementByTitle(
    cMSResources,
    "{{PhotoRestriction}}"
  );

  const fileChangedHandler = (event) => {
    const { files } = event.target;
    const filesArray = [...files];

    const hasBiggerFile = filesArray.find(
      (file) => file && file.size > TREE_MB
    );

    if (hasBiggerFile) {
      setFileSizeErrorMessageShow(true);
      event.target.value = null;

      return;
    }

    const filteredFiles = filesArray
      .filter(
        (file) =>
          !uploadedFiles.find((uploadedFile) => uploadedFile.name === file.name)
      )
      .slice(0, 3 - uploadedFiles.length);

    setFileSizeErrorMessageShow(false);
    setUploadedFiles([...uploadedFiles, ...filteredFiles]);
    event.target.value = null;
  };

  const getSelectedFilesText = () => {
    if (uploadedFiles.length) {
      const chosenPhotosText = uploadedFiles.length === 1 ? "photo" : "photos";

      return `${uploadedFiles.length} ${chosenPhotosText} chosen · ${
        IMAGES_LIMIT - uploadedFiles.length
      } left`;
    }

    return cmsNoPhotosChosen.value;
  };

  const removeUploadedImage = (index) => {
    uploadedFiles.splice(index, 1);
    setUploadedFiles([...uploadedFiles]);
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      title={cmsRequestServiceHeader.value}
      className="book-service-modal"
      footer={<ModalButtons config={buttonsConfig} />}
    >
      <div className="book-service-modal-description">
        {cmsRequestServiceTitle.value}
      </div>
      <form className="book-service-modal-form" noValidate>
        {/* <div className="book-service-modal-input-line">
          <BookServiceModalSelect
            hasError={!!errors.Id?.message}
            control={control}
            trigger={trigger}
            name="Id"
          />
          {errors.Id?.message && (
            <div className="error-message">{errors.Id?.message}</div>
          )}
        </div> */}
        <div className="book-service-modal-input-line">
          <InputLabel
            classes={{
              root: "generic_select__label",
              asterisk: "generic_select__asterisk",
            }}
            required
          >
            {cmsRequestServiceDate.value}
          </InputLabel>
          <Controller
            control={control}
            name="preferedDate"
            rules={{ required: true }}
            render={(props) => (
              <DatePicker
                inputVariant="outlined"
                className="book-service-modal-date-time-picker"
                minDate={new Date()}
                value={props.value}
                onChange={(e) => {
                  props.onChange(e);
                  trigger("preferedDate");
                }}
                InputProps={{
                  placeholder: cmsRequestServiceDate.value,
                  required: true,
                  error: !!errors.preferedDate,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className="book-service-modal-date-time-adornment"
                    >
                      <IconButton>
                        <EventIcon
                          color="primary"
                          className="book-service-modal-date-time-icon"
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {errors.preferedDate && (
            <div className="error-message">This field is required</div>
          )}
        </div>
        <div className="book-service-modal-input-line">
          <InputLabel
            classes={{
              root: "generic_select__label",
              asterisk: "generic_select__asterisk",
            }}
            required
          >
            {cmsRequestServiceTime.value}
          </InputLabel>
          <Controller
            control={control}
            name="preferedTime"
            rules={{ required: true }}
            render={(props) => (
              <TimePicker
                inputVariant="outlined"
                className="book-service-modal-date-time-picker"
                value={props.value}
                onChange={(e) => {
                  props.onChange(e);
                  trigger("preferedTime");
                }}
                InputProps={{
                  placeholder: cmsRequestServiceTime.value,
                  required: true,
                  error: !!errors.preferedTime,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className="book-service-modal-date-time-adornment"
                    >
                      <IconButton>
                        <ScheduleIcon
                          color="primary"
                          className="book-service-modal-date-time-icon"
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {errors.preferedTime && (
            <div className="error-message">This field is required</div>
          )}
        </div>
        <div className="book-service-modal-input-line">
          <Input
            inputRef={register({
              required: "The field is required",
              validate: {
                isPhoneNumber: isPhone,
              },
            })}
            name="phoneNumber"
            required
            type="tel"
            error={errors.phoneNumber?.message}
            variant="outlined"
            label={cmsRequestServicePhone.value}
            onKeyPress={onTimeInputKeyPress}
            placeholder={cmsRequestServicePhone.value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="">
                  <IconButton>
                    <PhoneIcon
                      color="primary"
                      className="book-service-modal-phone-icon"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.phoneNumber?.message && (
            <div className="error-message">{errors.phoneNumber?.message}</div>
          )}
        </div>
        <div className="book-service-modal-input-line">
          <Input
            inputRef={register({
              required: "The field is required",
            })}
            name="serviceDetails"
            required
            error={errors.serviceDetails?.message}
            label={cmsRequestServiceDescription.value}
            type="text"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            placeholder={cmsRequestServiceDescription.value}
            className="book-service-modal-message-input"
          />
          {errors.serviceDetails?.message && (
            <div className="error-message">
              {errors.serviceDetails?.message}
            </div>
          )}
        </div>
        <div className="shape_flex shape_flex--direction-column">
          <span className="pump-hours-file-upload-text">
            {cmsPhotoUpload.value}
          </span>
          <input
            accept=".jpg,.png"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            disabled={uploadedFiles.length === IMAGES_LIMIT}
            onChange={fileChangedHandler}
            name="files"
          />
          <div>
            <label
              htmlFor="contained-button-file"
              className="add-file-button-label"
            >
              <Button
                text={cmsAddPhoto.value}
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<AttachFileIcon />}
                disabled={uploadedFiles.length === IMAGES_LIMIT}
                classes={{ disabled: "button-disabled-state" }}
              />
            </label>
            <span className="pump-hours-selected-files-text">
              {getSelectedFilesText()}
            </span>
          </div>
          <span className="pump-hours-file-restrictions-text">
            {cmsPhotoRestriction.value}
          </span>
          <MaxFileErrorMessage
            showError={fileSizeErrorMessageShow}
            onTimeoutEnd={() => setFileSizeErrorMessageShow(false)}
          />
          <ChosenImages
            files={uploadedFiles}
            onImageRemove={(index) => removeUploadedImage(index)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default RequestServiceModal;
