import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { submitPumpHours } from "store/actions";
import Modal from "components/generic/Modal/Modal";
import ModalButtons from "components/generic/Modal/ModalButtons/ModalButtons";
import Input from "components/generic/Input/Input";
import Button from "components/generic/Button/Button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MaxFileErrorMessage from "./MaxFileErrorMessage/MaxFileErrorMessage";
import ChosenImages from "./ChosenImages/ChosenImages";
import { cMSResources } from "../../index";

import "./PumpHoursModal.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const TREE_MB = 3145728;
const DETAILS_CHARACTER_LIMIT = 1200;
const IMAGES_LIMIT = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const isNumeric = (value) => String(value).match(/^[0-9]*$/g);
const isNumericValidator = (val) =>
  !!isNumeric(val) || "Should contain digits only";

const PumpHoursModal = ({ open, onClose }) => {
  const defaultLanguage = sessionStorage.getItem("defaultLanguage");
  const classes = useStyles();
  const inSimulationMode = useSelector((state) => state.inSimulationMode);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileSizeErrorMessageShow, setFileSizeErrorMessageShow] =
    useState(false);
  const defaultValues = {
    pumpSerialNumber: null,
    pumpHours: null,
    details: null,
    culture: defaultLanguage,
  };

  var cmsSubmitYourPumpHours = findArrayElementByTitle(
    cMSResources,
    "{{SubmitYourPumpHours}}"
  );
  var cmsSubmitYourPumpHoursDescription = findArrayElementByTitle(
    cMSResources,
    "{{SubmitYourPumpHoursDescription}}"
  );
  var cmsPumpSerialNumber = findArrayElementByTitle(
    cMSResources,
    "{{PumpSerialNumber}}"
  );
  var cmsCurrentPumpHours = findArrayElementByTitle(
    cMSResources,
    "{{CurrentPumpHours}}"
  );
  var cmsDetails = findArrayElementByTitle(cMSResources, "{{Details}}");
  var cmsPhotoUpload = findArrayElementByTitle(cMSResources, "{{PhotoUpload}}");
  var cmsNoPhotosChosen = findArrayElementByTitle(
    cMSResources,
    "{{NoPhotosChosen}}"
  );
  var cmsAddPhoto = findArrayElementByTitle(cMSResources, "{{AddPhoto}}");
  var cmsPhotoRestriction = findArrayElementByTitle(
    cMSResources,
    "{{PhotoRestriction}}"
  );
  var cmsSubmit = findArrayElementByTitle(cMSResources, "{{Submit}}");
  var cmsCancel = findArrayElementByTitle(cMSResources, "{{Cancel}}");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur", reValidateMode: "onBlur", defaultValues });
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setUploadedFiles([]);
    setFileSizeErrorMessageShow(false);
    onClose();
  };

  const onSubmit = (fData) => {
    const data = new FormData();
    // debugger;

    Object.keys(fData).map((key) => data.append(key, fData[key]));
    uploadedFiles.map((file) => data.append("files", file, file.name));

    data.append("culture", defaultLanguage);

    dispatch(submitPumpHours(data));
    handleModalClose();
  };

  const fileChangedHandler = (event) => {
    const { files } = event.target;
    const filesArray = [...files];

    const hasBiggerFile = filesArray.find(
      (file) => file && file.size > TREE_MB
    );

    if (hasBiggerFile) {
      setFileSizeErrorMessageShow(true);
      event.target.value = null;

      return;
    }

    const filteredFiles = filesArray
      .filter(
        (file) =>
          !uploadedFiles.find((uploadedFile) => uploadedFile.name === file.name)
      )
      .slice(0, 3 - uploadedFiles.length);

    setFileSizeErrorMessageShow(false);
    setUploadedFiles([...uploadedFiles, ...filteredFiles]);
    event.target.value = null;
  };

  const removeUploadedImage = (index) => {
    uploadedFiles.splice(index, 1);
    setUploadedFiles([...uploadedFiles]);
  };

  const onBreakKeyPress = (event) => {
    if (!isNumeric(event.nativeEvent.key)) {
      event.preventDefault();
    }
  };

  const getSelectedFilesText = () => {
    if (uploadedFiles.length) {
      const chosenPhotosText = uploadedFiles.length === 1 ? "photo" : "photos";

      return `${uploadedFiles.length} ${chosenPhotosText} chosen · ${
        IMAGES_LIMIT - uploadedFiles.length
      } left`;
    }

    return cmsNoPhotosChosen.value;
  };

  const buttonsConfig = [
    {
      text: cmsCancel.value,
      handler: handleModalClose,
    },
    {
      text: cmsSubmit.value,
      handler: handleSubmit(onSubmit),
      disabled: !isValid || inSimulationMode,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      title={cmsSubmitYourPumpHours.value}
      className="pump-hours-modal"
      footer={<ModalButtons config={buttonsConfig} />}
    >
      <div className="pump-hours-modal-description">
        {cmsSubmitYourPumpHoursDescription.value}
      </div>
      <form className="pump-hours-modal-form" noValidate>
        <div className="pump-hours-modal-input-line">
          <Input
            inputRef={register({
              required: "The field is required",
            })}
            name="pumpSerialNumber"
            required
            type="text"
            error={errors.pumpSerialNumber?.message}
            variant="outlined"
            label={cmsPumpSerialNumber.value}
            placeholder={cmsPumpSerialNumber.value}
          />
          {errors.pumpSerialNumber?.message && (
            <div className="error-message">
              {errors.pumpSerialNumber?.message}
            </div>
          )}
        </div>
        <div className="pump-hours-modal-input-line">
          <Input
            inputRef={register({
              required: "The field is required",
              validate: {
                isNumeric: isNumericValidator,
              },
            })}
            onKeyPress={onBreakKeyPress}
            name="pumpHours"
            required
            type="text"
            error={errors.pumpHours?.message}
            variant="outlined"
            label={cmsCurrentPumpHours.value}
            placeholder={cmsCurrentPumpHours.value}
            InputProps={{ inputProps: { inputMode: "numeric" } }}
          />
          {errors.pumpHours?.message && (
            <div className="error-message">{errors.pumpHours?.message}</div>
          )}
        </div>
        <div className="pump-hours-modal-input-line">
          <Input
            inputRef={register()}
            inputProps={{ maxLength: DETAILS_CHARACTER_LIMIT }}
            helperText={`${
              watch("details")?.length || 0
            }/${DETAILS_CHARACTER_LIMIT}`}
            name="details"
            type="text"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            label={cmsDetails.value}
            placeholder={cmsDetails.value}
            className="pump-hours-modal-details-input"
            FormHelperTextProps={{
              classes: { root: "details-field-helper-text-root" },
            }}
          />
        </div>
        <div className="shape_flex shape_flex--direction-column">
          <span className="pump-hours-file-upload-text">
            {cmsPhotoUpload.value}
          </span>
          <input
            accept=".jpg,.png"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            disabled={uploadedFiles.length === IMAGES_LIMIT}
            onChange={fileChangedHandler}
            name="files"
          />
          <div>
            <label
              htmlFor="contained-button-file"
              className="add-file-button-label"
            >
              <Button
                text={cmsAddPhoto.value}
                variant="outlined"
                color="primary"
                component="span"
                startIcon={<AttachFileIcon />}
                disabled={uploadedFiles.length === IMAGES_LIMIT}
                classes={{ disabled: "button-disabled-state" }}
              />
            </label>
            <span className="pump-hours-selected-files-text">
              {getSelectedFilesText()}
            </span>
          </div>
          <span className="pump-hours-file-restrictions-text">
            {cmsPhotoRestriction.value}
          </span>
          <MaxFileErrorMessage
            showError={fileSizeErrorMessageShow}
            onTimeoutEnd={() => setFileSizeErrorMessageShow(false)}
          />
          <ChosenImages
            files={uploadedFiles}
            onImageRemove={(index) => removeUploadedImage(index)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default PumpHoursModal;
