import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { changeInstallationAlias } from 'store/actions';
import Modal from 'components/generic/Modal/Modal';
import Input from 'components/generic/Input/Input';
import ModalButtons from '../../ModalButtons/ModalButtons';

import './EditSystemAliasModal.scss';

/**
 * Modal used to edit the system alias of the machine
 *
 * @param {Object}   machine        machine data
 * @param {Function} onCloseHandler called when the modal is closed
 * @param {Function} onSaveHandler  called when the save button is triggered
 * @param {open}     open           indicates if the modal is opened
 */
const EditSystemAliasModal = ({
  machine, onCloseHandler, onSaveHandler, open,
}) => {
  const { alias, name, systemId } = machine;
  const defaultValues = {
    alias: alias || '',
  };
  const { register, handleSubmit } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur', defaultValues });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    onSaveHandler();
    dispatch(changeInstallationAlias(data, systemId));
  };

  const onModalClose = (event, reason) => {
    if (reason === 'backdropClick') {
      onCloseHandler();
    }
  };

  const buttonsConfig = [
    {
      text: 'Cancel',
      handler: onCloseHandler,
    },
    {
      text: 'Save',
      handler: handleSubmit(onSubmit),
    },
  ];

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      className="edit-system-alias-modal"
      title="Change system alias"
      footer={(
        <ModalButtons config={buttonsConfig} />
      )}
    >
      <form className="edit-system-alias-modal-form">
        <div className="edit-system-alias-modal-heading">{name}</div>
        <Input
          inputRef={register()}
          name="alias"
          type="text"
          variant="outlined"
          label="Alias:"
        />
      </form>
    </Modal>
  );
};

export default EditSystemAliasModal;
