import { createReducer } from '@reduxjs/toolkit';

const initialState = false;

/**
 * Keeps spinner state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const spinner = createReducer(initialState, {
  SET_SPINNER: (state, { value }) => value.payload,
});

export default spinner;
