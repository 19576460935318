import { createReducer } from '@reduxjs/toolkit';

const initialState = [];

/**
 * Keeps customerGridData state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const customerGridData = createReducer(initialState, {
  GET_CUSTOMERS: (state, { value }) => value.payload,
});

export default customerGridData;
