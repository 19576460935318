import { createReducer } from "@reduxjs/toolkit";
import {
  toggleScheduleTrainingModal,
  toggleLicenseAgreementModal,
  toggleRequestServiceModal,
  toggleEditBillingInfoSuccessModal,
  toggleEditInstallationAddressSuccessModal,
  toggleEditInstallationAliasSuccessModal,
  togglePumpHoursModal,
  toggleSimulationExitConfirmationModal,
  toggleSimulationModal,
  togglePumpHoursSuccessModal,
  toggleRequestServiceSuccessModal,
} from "../actions";

const initialState = {
  scheduleTrainingModal: {
    isOpen: false,
  },
  licenseAgreementModal: {
    isOpen: false,
  },
  bookServiceModal: {
    isOpen: false,
  },
  pumpHoursModal: {
    isOpen: false,
  },
  pumpHoursSuccessModal: {
    isOpen: false,
  },
  editBillingInfoSuccessModal: {
    isOpen: false,
  },
  editInstallationAddressSuccessModal: {
    isOpen: false,
  },
  editInstallationAliasSuccessModal: {
    isOpen: false,
  },
  simulationModal: {
    isOpen: false,
  },
  simulationExitConfirmationModal: {
    isOpen: false,
  },
  requestServiceSuccessModal: {
    isOpen: false,
  },
};

/**
 * Keeps modals state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const modals = createReducer(initialState, {
  [toggleScheduleTrainingModal.type]: ({ scheduleTrainingModal }) => {
    scheduleTrainingModal.isOpen = !scheduleTrainingModal.isOpen;
  },
  [toggleLicenseAgreementModal.type]: ({ licenseAgreementModal }) => {
    licenseAgreementModal.isOpen = !licenseAgreementModal.isOpen;
  },
  [toggleRequestServiceModal.type]: ({ bookServiceModal }) => {
    bookServiceModal.isOpen = !bookServiceModal.isOpen;
  },
  [togglePumpHoursModal.type]: ({ pumpHoursModal }) => {
    pumpHoursModal.isOpen = !pumpHoursModal.isOpen;
  },
  [toggleEditBillingInfoSuccessModal.type]: ({
    editBillingInfoSuccessModal,
  }) => {
    editBillingInfoSuccessModal.isOpen = !editBillingInfoSuccessModal.isOpen;
  },
  [toggleEditInstallationAddressSuccessModal.type]: ({
    editInstallationAddressSuccessModal,
  }) => {
    editInstallationAddressSuccessModal.isOpen =
      !editInstallationAddressSuccessModal.isOpen;
  },
  [toggleEditInstallationAliasSuccessModal.type]: ({
    editInstallationAliasSuccessModal,
  }) => {
    editInstallationAliasSuccessModal.isOpen =
      !editInstallationAliasSuccessModal.isOpen;
  },
  [toggleSimulationModal.type]: ({ simulationModal }) => {
    simulationModal.isOpen = !simulationModal.isOpen;
  },
  [toggleSimulationExitConfirmationModal.type]: ({
    simulationExitConfirmationModal,
  }) => {
    simulationExitConfirmationModal.isOpen =
      !simulationExitConfirmationModal.isOpen;
  },
  [togglePumpHoursSuccessModal.type]: ({ pumpHoursSuccessModal }) => {
    pumpHoursSuccessModal.isOpen = !pumpHoursSuccessModal.isOpen;
  },
  [toggleRequestServiceSuccessModal.type]: ({ requestServiceSuccessModal }) => {
    requestServiceSuccessModal.isOpen = !requestServiceSuccessModal.isOpen;
  },
});

export default modals;
