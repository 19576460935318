import { createReducer } from '@reduxjs/toolkit';

const initialState = [];

/**
 * Keeps customer data state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const customerData = createReducer(initialState, {
  GET_CUSTOMERDATA: (state, { value }) => value.payload,
  SET_CUSTOMERDATA: () => [],
});

export default customerData;
