import { createReducer } from "@reduxjs/toolkit";

const initialState = "";

/**
 * Keeps service plan state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const downloadPumpHoursReport = createReducer(initialState, {
  GET_DOWNLOAD_PUMP_HOURS_REPORT: (state, { value }) => value.payload,
});

export default downloadPumpHoursReport;
