import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isUserCustomerUserOnly } from 'store/reducers/index';
import { v4 } from 'uuid';
import AccountInfoPrimaryTile from '../AccountInfoPrimaryTile/AccountInfoPrimaryTile';
import EditInstallationAddressModal from '../EditInstallationAddressModal/EditInstallationAddressModal';
import InstallationSite from './InstallationSite/InstallationSite';

import './Installation.scss';

/**
 * Component which holds installation information - address, machines
 *
 * @param {Object} installation site installation
 */
const Installation = ({ installation }) => {
  const { address, machines, siteId } = installation;
  const {
    street, postalCode, city, state,
  } = address;
  const [openEditAddressModal, setOpenEditAddressModal] = useState(false);
  const isCustomerUserOnly = useSelector(isUserCustomerUserOnly);

  const onSendRequestHandler = () => {
    setOpenEditAddressModal(false);
  };

  const heading = () => (
    <>
      <div>{street}</div>
      <div>{`${city}, ${state} ${postalCode}`}</div>
    </>
  );

  return (
    <>
      <AccountInfoPrimaryTile heading={heading()} className="installation-wrapper" showEditButton={!isCustomerUserOnly} onEdit={() => setOpenEditAddressModal(true)}>
        {machines.map((machine) => (
          <InstallationSite key={v4()} machine={machine} />
        ))}
      </AccountInfoPrimaryTile>
      <EditInstallationAddressModal
        open={openEditAddressModal}
        onCloseHandler={() => setOpenEditAddressModal(false)}
        onSendRequestHandler={onSendRequestHandler}
        installationAddress={address}
        siteId={siteId}
      />
    </>
  );
};

export default Installation;
