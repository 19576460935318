import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Logout = ({ viewBox }) => (
  <SvgIcon viewBox={viewBox}>
    <path fill="currentColor" d="M3.33333 3.33333H7.33333C7.7 3.33333 8 3.03333 8 2.66667C8 2.3 7.7 2 7.33333 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H7.33333C7.7 14 8 13.7 8 13.3333C8 12.9667 7.7 12.6667 7.33333 12.6667H3.33333V3.33333Z" />
    <path fill="currentColor" d="M13.7667 7.76669L11.9067 5.90669C11.6933 5.69335 11.3333 5.84002 11.3333 6.14002V7.33335H6.66667C6.3 7.33335 6 7.63335 6 8.00002C6 8.36669 6.3 8.66669 6.66667 8.66669H11.3333V9.86002C11.3333 10.16 11.6933 10.3067 11.9 10.0934L13.76 8.23335C13.8933 8.10669 13.8933 7.89335 13.7667 7.76669Z" />
  </SvgIcon>
);

export default Logout;
