import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import MuiLink from "@material-ui/core/Link";
import { useTheme } from "@material-ui/core/styles";
import LogoSwitch from "components/LogoSwitch/LogoSwitch";
import Paper from "components/generic/Paper/Paper";
import { cMSResources } from "index";

import "./LandingPageTile.scss";

function findArrayElementByKey(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const TileContent = ({
  title,
  description,
  tileLinkIcon,
  id,
  theme,
  disabled,
  padding,
  tileIcon,
  tileTitleColor,
  tileDescriptionColor,
}) => (
  <div
    className={classNames("landing-page-tile", {
      "landing-page-tile--disabled": disabled,
    })}
  >
    <Paper className="landing-page-tile-inner" padding={padding}>
      <span className="tile-image">
        <span
          className="logo-wrapper"
          style={{ color: theme.palette.primary.main }}
        >
          {/* <LogoSwitch navId={id} /> */}
          {/* <img src={tileIcon} alt="tileIcon" /> */}
          {tileIcon ? (
            <img src={tileIcon} alt="tileIcon" style={{ width: 42 }} />
          ) : (
            <LogoSwitch navId={id} />
          )}
        </span>
      </span>
      <span className="tile-heading" style={{ color: tileTitleColor }}>
        {title}
      </span>
      <span
        className="tile-description"
        style={{ color: tileDescriptionColor }}
      >
        {description}
      </span>
      <span
        className="tile-link-desktop"
        style={{ color: disabled ? "#E1E3E3" : theme.palette.primary.main }}
      >
        <span
          className={classNames("tile-link-icon", {
            "nav-next-icon": tileLinkIcon === "navigate_next",
            "open-in-new-icon": tileLinkIcon === "open_in_new",
          })}
        >
          <LogoSwitch navId={tileLinkIcon} />
        </span>
      </span>
    </Paper>
  </div>
);

const TileWrapper = ({ onClick, link, className, children }) => {
  if (onClick) {
    return (
      <MuiLink onClick={onClick} className={className}>
        {children}
      </MuiLink>
    );
  }
  if (link.indexOf("http") !== -1) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={link} className={className}>
      {children}
    </Link>
  );
};

const LandingPageTile = ({
  title,
  description,
  tileLinkTextMobile,
  tileLinkTextDesktop,
  tileLinkIcon,
  id,
  link = "",
  onClick,
  className,
  order,
  disabled = false,
  padding = 12,
  display = "block",
  tileIcon = undefined,
  tileTitleColor = "#5B6770",
  tileDescriptionColor = "#5B6770",
}) => {
  const theme = useTheme();
  var cmsComingSoon = findArrayElementByKey(cMSResources, "{{ComingSoon}}");
  return (
    <div className="tile-holder" style={{ order, display: display }}>
      <TileWrapper
        onClick={onClick}
        link={link}
        className={classNames("landing-page-tile-wrapper", className)}
      >
        <TileContent
          title={title}
          description={description}
          tileLinkTextMobile={tileLinkTextMobile}
          tileLinkTextDesktop={tileLinkTextDesktop}
          tileLinkIcon={tileLinkIcon}
          id={id}
          theme={theme}
          disabled={disabled}
          padding={padding}
          tileIcon={tileIcon}
          tileTitleColor={tileTitleColor}
          tileDescriptionColor={tileDescriptionColor}
        />
      </TileWrapper>
      {disabled && (
        <div className="tile-coming-soon">{cmsComingSoon.value}</div>
      )}
    </div>
  );
};

export default LandingPageTile;
