import React, { useState } from 'react';
import AccountInfoSecondaryTile from '../../AccountInfoSecondaryTile/AccountInfoSecondaryTile';
import EditSystemAliasModal from '../EditSystemAliasModal/EditSystemAliasModal';

import './InstallationSite.scss';

/**
 * Installation site
 *
 * @param {Object} machine machine name and alias
 */
const InstallationSite = ({ machine }) => {
  const [openInstallationAliasModal, setOpenInstallationAliasModal] = useState(false);

  const onSystemAliasSaved = () => {
    setOpenInstallationAliasModal(false);
  };

  return (
    <>
      <AccountInfoSecondaryTile
        heading={machine.alias || machine.name}
        onEdit={() => setOpenInstallationAliasModal(true)}
        headingBorder={false}
      >
        {machine.alias && machine.name}
      </AccountInfoSecondaryTile>
      <EditSystemAliasModal
        open={openInstallationAliasModal}
        onCloseHandler={() => setOpenInstallationAliasModal(false)}
        onSaveHandler={onSystemAliasSaved}
        machine={machine}
      />
    </>
  );
};

export default InstallationSite;
