import React, { useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { compareIgnoreCase } from "utils/comparators";
import {
  getCustomers,
  getUsers,
  toggleModal,
  disableUsers,
  resendUserInvite,
} from "store/actions";
import Spinner from "../Spinner/Spinner";
import ModalPopup from "../Modal/Modal";
import sortUp from "../../assets/sort_up.svg";
import sortDown from "../../assets/sort_down.svg";
import next from "../../assets/next.svg";
import previous from "../../assets/previous.svg";
import double_next from "../../assets/double_next.svg";
import double_previous from "../../assets/double_previous.svg";
import next_gray from "../../assets/next_gray.svg";
import previous_gray from "../../assets/previous_gray.svg";
import double_next_gray from "../../assets/double_next_gray.svg";
import double_previous_gray from "../../assets/double_previous_gray.svg";
import { cmsLogoAndCollor, cMSResources } from "../../index";
import "./Table.css";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

function Table({ columns, data, screenName, currentRoleAssigned }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) =>
          compareIgnoreCase(row1.values[columnName], row2.values[columnName]),
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 1000);

    var cmsSearch = findArrayElementByTitle(cMSResources, "{{Search}}");

    return (
      <span className="table-search">
        {cmsSearch.value}{" "}
        <input
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={cmsSearch.value}
          style={{
            fontSize: "1.1rem",
            border: "0",
          }}
        />
      </span>
    );
  }
  const inSimulationMode = useSelector((state) => state.inSimulationMode);
  const show = useSelector((state) => state.showModal);
  const [rowData, setRowData] = useState({});
  const [editClicked, setEditClicked] = useState(false);
  const dispatch = useDispatch();
  const userClaims = useSelector((state) => state.claims);
  const currentRole = userClaims
    ? JSON.parse(userClaims.extension_CustomerMapping).CustomerMappings[0].Role
    : null;
  const currentCustomer =
    currentRole && currentRole.includes("GlobalAdmin")
      ? "All"
      : JSON.parse(userClaims.extension_CustomerMapping).CustomerMappings[0]
          .CustomerName;
  const editButton = (rowData) => {
    dispatch(toggleModal(true));
    setRowData(rowData.original);
    setEditClicked(true);
  };
  const toggleAccountButton = (rowData) => {
    const userData = {
      CustomerName: rowData.original["Customer Name"],
      CustomerNumber: rowData.original["Customer Number"],
      Action: rowData.original["Account Enabled"]
        ? "Disable User"
        : "Enable User",
      Id: rowData.original.Id,
      CurrentScreen:
        screenName === "manage-user" ? "Manage Users" : "Manage Customers",
    };
    dispatch(disableUsers(userData)).then(() => {
      screenName === "manage-user"
        ? dispatch(getUsers(currentCustomer, "Manage Users"))
        : dispatch(getCustomers("All", "Manage Customers"));
    });
  };
  const resendInvitationButton = (rowData) => {
    const userData = {
      FirstName: rowData.original["First Name"],
      Email: rowData.original["Primary Contact Email"],
      Action: "Resend Invitation",
      Id: rowData.original.Id,
      Roles: rowData.original.Role.split(","),
      CustomerName: rowData.original["Customer Name"],
      CustomerNumber: rowData.original["Customer Number"],
    };
    dispatch(resendUserInvite(userData));
  };

  const ResendIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Icon_ionic-md-refresh"
        data-name="Icon ionic-md-refresh"
        d="M13.625,19.625a6,6,0,1,1,0-12,5.813,5.813,0,0,1,4.2,1.8l-3.2,3.2h7v-7l-2.35,2.35a7.99,7.99,0,1,0,2.05,7.832H19.209A5.958,5.958,0,0,1,13.625,19.625Z"
        transform="translate(-5.625 -5.625)"
        fill={props.fill}
      />
    </svg>
  );

  const ResendDisabledIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Icon_ionic-md-refresh"
        data-name="Icon ionic-md-refresh"
        d="M13.625,19.625a6,6,0,1,1,0-12,5.813,5.813,0,0,1,4.2,1.8l-3.2,3.2h7v-7l-2.35,2.35a7.99,7.99,0,1,0,2.05,7.832H19.209A5.958,5.958,0,0,1,13.625,19.625Z"
        transform="translate(-5.625 -5.625)"
        fill={props.fill}
      />
    </svg>
  );

  const EditIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.504"
      height="20"
      viewBox="0 0 22.504 20"
    >
      <path
        id="Icon_awesome-edit"
        data-name="Icon awesome-edit"
        d="M15.73,3.254l3.524,3.524a.382.382,0,0,1,0,.539L10.721,15.85l-3.626.4a.76.76,0,0,1-.84-.84l.4-3.626L15.19,3.254A.382.382,0,0,1,15.73,3.254Zm6.329-.895L20.152.452A1.528,1.528,0,0,0,18,.452L16.613,1.836a.382.382,0,0,0,0,.539L20.137,5.9a.382.382,0,0,0,.539,0l1.383-1.383a1.528,1.528,0,0,0,0-2.157ZM15,13.529v3.977H2.5V5h8.978a.48.48,0,0,0,.332-.137L13.374,3.3a.469.469,0,0,0-.332-.8H1.875A1.876,1.876,0,0,0,0,4.379V18.132a1.876,1.876,0,0,0,1.875,1.875H15.628A1.876,1.876,0,0,0,17.5,18.132V11.966a.47.47,0,0,0-.8-.332L15.14,13.2A.48.48,0,0,0,15,13.529Z"
        transform="translate(0 -0.007)"
        fill={props.fill}
      />
    </svg>
  );

  const EditDisabledIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.504"
      height="20"
      viewBox="0 0 22.504 20"
    >
      <path
        id="Icon_awesome-edit"
        data-name="Icon awesome-edit"
        d="M15.73,3.254l3.524,3.524a.382.382,0,0,1,0,.539L10.721,15.85l-3.626.4a.76.76,0,0,1-.84-.84l.4-3.626L15.19,3.254A.382.382,0,0,1,15.73,3.254Zm6.329-.895L20.152.452A1.528,1.528,0,0,0,18,.452L16.613,1.836a.382.382,0,0,0,0,.539L20.137,5.9a.382.382,0,0,0,.539,0l1.383-1.383a1.528,1.528,0,0,0,0-2.157ZM15,13.529v3.977H2.5V5h8.978a.48.48,0,0,0,.332-.137L13.374,3.3a.469.469,0,0,0-.332-.8H1.875A1.876,1.876,0,0,0,0,4.379V18.132a1.876,1.876,0,0,0,1.875,1.875H15.628A1.876,1.876,0,0,0,17.5,18.132V11.966a.47.47,0,0,0-.8-.332L15.14,13.2A.48.48,0,0,0,15,13.529Z"
        transform="translate(0 -0.007)"
        fill={props.fill}
      />
    </svg>
  );

  const EnableIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 400 400"
    >
      <defs>
        <clipPath id="clip-Web_1920_1">
          <rect width="400" height="400" />
        </clipPath>
      </defs>
      <g
        id="Web_1920_1"
        data-name="Web 1920 – 1"
        clip-path="url(#clip-Web_1920_1)"
      >
        <rect width="400" height="400" fill="#fff" />
        <g
          id="Group_1"
          data-name="Group 1"
          transform="translate(106.222 -604.701)"
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M8.8,147.535q188.866,250.12,400.094,0Q197.423-102.585,8.8,147.535ZM209.09,248.652A100.631,100.631,0,1,1,309.722,148.021,100.521,100.521,0,0,1,209.09,248.652Z"
            transform="translate(-115.022 657.495)"
            fill={props.fill}
          />
          <ellipse
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="74"
            cy="73.5"
            rx="74"
            ry="73.5"
            transform="translate(19.778 731.701)"
            fill={props.fill}
          />
        </g>
      </g>
    </svg>
  );

  const EnableDisabledIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 400 400"
    >
      <defs>
        <clipPath id="clip-Web_1920_1">
          <rect width="400" height="400" />
        </clipPath>
      </defs>
      <g
        id="Web_1920_1"
        data-name="Web 1920 – 1"
        clip-path="url(#clip-Web_1920_1)"
      >
        <rect width="400" height="400" fill="#fff" />
        <g
          id="Group_1"
          data-name="Group 1"
          transform="translate(106.222 -604.701)"
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M8.8,147.535q188.866,250.12,400.094,0Q197.423-102.585,8.8,147.535ZM209.09,248.652A100.631,100.631,0,1,1,309.722,148.021,100.521,100.521,0,0,1,209.09,248.652Z"
            transform="translate(-115.022 657.495)"
            fill={props.fill}
          />
          <ellipse
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="74"
            cy="73.5"
            rx="74"
            ry="73.5"
            transform="translate(19.778 731.701)"
            fill={props.fill}
          />
        </g>
      </g>
    </svg>
  );

  const VisibilityIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 25 20"
    >
      <path
        id="Icon_awesome-eye-slash"
        data-name="Icon awesome-eye-slash"
        d="M12.5,15.625a5.6,5.6,0,0,1-5.582-5.2L2.82,7.258A13.019,13.019,0,0,0,1.386,9.43a1.264,1.264,0,0,0,0,1.14A12.529,12.529,0,0,0,12.5,17.5a12.133,12.133,0,0,0,3.043-.409l-2.027-1.568a5.63,5.63,0,0,1-1.016.1Zm12.258,2.27L20.44,14.557a12.939,12.939,0,0,0,3.174-3.987,1.264,1.264,0,0,0,0-1.14A12.529,12.529,0,0,0,12.5,2.5,12.037,12.037,0,0,0,6.745,3.972L1.776.132A.625.625,0,0,0,.9.241l-.767.987a.625.625,0,0,0,.11.877L23.224,19.868a.625.625,0,0,0,.877-.11l.767-.987a.625.625,0,0,0-.11-.877Zm-7.176-5.547-1.535-1.187A3.7,3.7,0,0,0,11.511,6.4a1.861,1.861,0,0,1,.364,1.1,1.822,1.822,0,0,1-.06.391L8.939,5.668A5.559,5.559,0,0,1,12.5,4.375,5.622,5.622,0,0,1,18.125,10a5.491,5.491,0,0,1-.543,2.348Z"
        transform="translate(0 0)"
        fill={props.fill}
      />
    </svg>
  );

  const VisibilityDisabledIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 25 20"
    >
      <path
        id="Icon_awesome-eye-slash"
        data-name="Icon awesome-eye-slash"
        d="M12.5,15.625a5.6,5.6,0,0,1-5.582-5.2L2.82,7.258A13.019,13.019,0,0,0,1.386,9.43a1.264,1.264,0,0,0,0,1.14A12.529,12.529,0,0,0,12.5,17.5a12.133,12.133,0,0,0,3.043-.409l-2.027-1.568a5.63,5.63,0,0,1-1.016.1Zm12.258,2.27L20.44,14.557a12.939,12.939,0,0,0,3.174-3.987,1.264,1.264,0,0,0,0-1.14A12.529,12.529,0,0,0,12.5,2.5,12.037,12.037,0,0,0,6.745,3.972L1.776.132A.625.625,0,0,0,.9.241l-.767.987a.625.625,0,0,0,.11.877L23.224,19.868a.625.625,0,0,0,.877-.11l.767-.987a.625.625,0,0,0-.11-.877Zm-7.176-5.547-1.535-1.187A3.7,3.7,0,0,0,11.511,6.4a1.861,1.861,0,0,1,.364,1.1,1.822,1.822,0,0,1-.06.391L8.939,5.668A5.559,5.559,0,0,1,12.5,4.375,5.622,5.622,0,0,1,18.125,10a5.491,5.491,0,0,1-.543,2.348Z"
        transform="translate(0 0)"
        fill={props.fill}
      />
    </svg>
  );

  var cmsResendInvitation = findArrayElementByTitle(
    cMSResources,
    "{{ResendInvitation}}"
  );
  var cmsEnableUser = findArrayElementByTitle(cMSResources, "{{EnableUser}}");
  var cmsDisabledUser = findArrayElementByTitle(
    cMSResources,
    "{{DisabledUser}}"
  );
  var cmsPage = findArrayElementByTitle(cMSResources, "{{Page}}");
  var cmsOf = findArrayElementByTitle(cMSResources, "{{Of}}");
  var cmsTotalRows = findArrayElementByTitle(cMSResources, "{{TotalRows}}");
  var cmsGoToPage = findArrayElementByTitle(cMSResources, "{{GoToPage}}");
  var cmsShow = findArrayElementByTitle(cMSResources, "{{Show}}");

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  return (
    <>
      {rows.length >= 0 ? (
        <>
          <table {...getTableProps()} style={{ border: "none" }}>
            <thead>
              <tr>
                <th
                  colSpan={2}
                  className="table-search"
                  style={{
                    textAlign: "left",
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        borderBottom: "solid 1px #007681",
                        paddingBottom: "10px",
                        paddingTop: "20px",
                        cursor: "pointer",
                        width: "220px",
                        background: "white",
                        color: "#244C5A",
                        fontWeight: "bold",
                      }}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img
                              className="table-sort"
                              alt="flow-logo"
                              title="sort descending"
                              src={sortDown}
                            />
                          ) : (
                            <img
                              className="table-sort"
                              alt="flow-logo"
                              title="sort ascending"
                              src={sortUp}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                  <th
                    style={{
                      borderBottom: "solid 1px #007681",
                      paddingBottom: "10px",
                      width: "300px",
                      background: "white",
                      color: "#ffffff",
                      fontWeight: "bold",
                      pointerEvents: "none",
                    }}
                  />
                  <th
                    style={{
                      borderBottom: "solid 1px #007681",
                      paddingBottom: "10px",
                      width: "5%",
                      background: "white",
                      color: "#ffffff",
                      fontWeight: "bold",
                      pointerEvents: "none",
                    }}
                  />
                  <th
                    style={{
                      borderBottom: "solid 1px #007681",
                      paddingBottom: "10px",
                      width: "10%",
                      background: "white",
                      color: "#ffffff",
                      fontWeight: "bold",
                      pointerEvents: "none",
                    }}
                  />
                </tr>
              ))}
            </thead>
            {rows.length === 0}
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "4px",
                          color: "#5B6770",
                          borderBottom: "solid 1px #E7E8E8",
                          background: "white",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                    {row.original["Invitation Status"] === "Registered" ? (
                      <td
                        style={{
                          padding: "4px",
                          cursor: "pointer",
                          color: "#5B6770",
                          borderBottom: "solid 1px #E7E8E8",
                          background: "white",
                        }}
                      />
                    ) : (
                      <td
                        style={{
                          padding: "4px",
                          cursor: "pointer",
                          color: "#5B6770",
                          borderBottom: "solid 1px #E7E8E8",
                          background: "white",
                        }}
                      >
                        <span className="table-img-status">
                          {inSimulationMode ? (
                            <ResendDisabledIcon fill={cmsLogoAndCollor.color} />
                          ) : (
                            <ResendIcon fill={cmsLogoAndCollor.color} />
                          )}
                        </span>
                        <span
                          onClick={() =>
                            !inSimulationMode && resendInvitationButton(row)
                          }
                        >
                          {cmsResendInvitation.value}
                        </span>
                      </td>
                    )}

                    <td
                      style={{
                        padding: "4px",
                        marginLeft: "10px",
                        color: "#5B6770",
                        borderBottom: "solid 1px #E7E8E8",
                        background: "white",
                      }}
                    >
                      {" "}
                      <span
                        className="table-img"
                        onClick={() => !inSimulationMode && editButton(row)}
                      >
                        {inSimulationMode ? (
                          <EditDisabledIcon fill={cmsLogoAndCollor.color} />
                        ) : (
                          <EditIcon fill={cmsLogoAndCollor.color} />
                        )}
                      </span>
                    </td>
                    {userClaims.emails[0] !==
                    row.original["Primary Contact Email"] ? (
                      <td
                        style={{
                          padding: "4px",
                          color: "#5B6770",
                          marginLeft: "10px",
                          borderBottom: "solid 1px #E7E8E8",
                          background: "white",
                        }}
                      >
                        <span
                          className="enabled"
                          onClick={() =>
                            !inSimulationMode && toggleAccountButton(row)
                          }
                        >
                          {row.original["Account Enabled"] === true
                            ? cmsDisabledUser.value
                            : cmsEnableUser.value}
                        </span>
                        <span
                          className={
                            row.original["Account Enabled"] === true
                              ? "table-img"
                              : "table-img-enable"
                          }
                          onClick={() =>
                            !inSimulationMode && toggleAccountButton(row)
                          }
                        >
                          {row.original["Account Enabled"] === true ? (
                            inSimulationMode ? (
                              <VisibilityDisabledIcon
                                fill={cmsLogoAndCollor.color}
                              />
                            ) : (
                              <VisibilityIcon fill={cmsLogoAndCollor.color} />
                            )
                          ) : inSimulationMode ? (
                            <EnableDisabledIcon fill={cmsLogoAndCollor.color} />
                          ) : (
                            <EnableIcon fill={cmsLogoAndCollor.color} />
                          )}
                        </span>
                      </td>
                    ) : (
                      <td
                        style={{
                          padding: "4px",
                          color: "#5B6770",
                          marginLeft: "10px",
                          borderBottom: "solid 1px #E7E8E8",
                          background: "white",
                        }}
                      />
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <span>
              {cmsTotalRows.value} :{rows.length}
            </span>
            <button
              className="table-pagination-buttons"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <img
                className="table-pagination-button-img"
                alt="first-page"
                title="first page"
                src={!canPreviousPage ? double_previous_gray : double_previous}
              />
            </button>
            <button
              className="table-pagination-buttons"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <img
                className="table-pagination-button-img"
                alt="previous page"
                title="previous page"
                src={!canPreviousPage ? previous_gray : previous}
              />
            </button>
            <button
              className="table-pagination-buttons"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <img
                className="table-pagination-button-img"
                alt="next page"
                title="next page"
                src={!canNextPage ? next_gray : next}
              />
            </button>
            <button
              className="table-pagination-buttons"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <img
                className="table-pagination-button-img"
                alt="last page"
                title="last page"
                src={!canNextPage ? double_next_gray : double_next}
              />
            </button>
            <span className="table-pagination-page-count">
              {cmsPage.value}{" "}
              <strong>
                {" "}
                {pageIndex + 1} {cmsOf.value}{" "}
                {pageOptions.length > 1000
                  ? `${pageOptions.length / 1000}K`
                  : pageOptions.length}
              </strong>
            </span>
            <span className="table-pagination-goto">
              | {cmsGoToPage.value}:
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </span>
            <select
              className="table-pagination-select"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {cmsShow.value} {pageSize}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : (
        <Spinner show />
      )}
      {editClicked ? (
        <ModalPopup
          show={show}
          rowData={rowData}
          currentDevice="desktop"
          screenName={screenName}
          isEdit
          onHide={() => {
            dispatch(toggleModal(false));
            setEditClicked(false);
          }}
        />
      ) : null}
    </>
  );
}

function TableComponent(props) {
  const dispatch = useDispatch();
  if (localStorage.getItem("inSimulationMode") === "true") {
    const { customerName } = JSON.parse(localStorage.getItem("simulatedUser"));
    // dispatch(getUsers(customerName, "Manage Users")); //fix for production issue
  }
  const userClaims = useSelector((state) => state.claims);

  const currentRole = userClaims
    ? JSON.parse(userClaims.extension_CustomerMapping).CustomerMappings[0].Role
    : null;
  let data = null;
  const customerGridData = useSelector((state) => state.customerGridData);
  const userGridData = useSelector((state) => state.userGridData);
  let currentGridData;

  if (props.screenName === "manage-customer") {
    currentGridData = customerGridData;
  } else {
    currentGridData = userGridData;
  }

  var cmsInvited = findArrayElementByTitle(cMSResources, "{{Invited}}");
  var cmsRegistered = findArrayElementByTitle(cMSResources, "{{Registered}}");

  data = React.useMemo(() =>
    currentGridData.map((item) => ({
      "Customer Name":
        item.extension_CustomerMapping.CustomerMappings[0].CustomerName,
      "Customer Number":
        item.extension_CustomerMapping.CustomerMappings[0].CustomerNumber,
      "Primary Contact Email": item.mail,
      "Invitation Status":
        item.extension_Registered === true
          ? cmsRegistered.value
          : cmsInvited.value,
      "First Name": item.givenName,
      "Last Name": item.surname,
      "Primary Contact": item.extension_PrimaryContact,
      Role: item.extension_CustomerMapping.CustomerMappings[0].Role.join(","),
      Id: item.id,
      Registered: item.extension_Registered,
      "Account Enabled": item.accountEnabled,
      "User Name": `${item.givenName} ${item.surname}`,
      "Mobile Phone": `${item.mobilePhone}`,
    }))
  );

  var cmsCustomerName = findArrayElementByTitle(
    cMSResources,
    "{{CustomerName}}"
  );
  var cmsCustomerNumber = findArrayElementByTitle(
    cMSResources,
    "{{CustomerNumber}}"
  );
  var cmsUserName = findArrayElementByTitle(cMSResources, "{{UserName}}");
  var cmsRole = findArrayElementByTitle(cMSResources, "{{Role}}");
  var cmsEmailString = findArrayElementByTitle(cMSResources, "{{EmailString}}");
  var cmsInvitationStatus = findArrayElementByTitle(
    cMSResources,
    "{{InvitationStatus}}"
  );

  const columns = React.useMemo(
    () => [
      {
        Header: cmsCustomerName.value,
        accessor: "Customer Name",
      },
      {
        Header: cmsCustomerNumber.value,
        accessor: "Customer Number",
      },
      {
        Header:
          props.screenName === "manage-user"
            ? cmsUserName.value
            : "Primary Contact Name",
        accessor: "User Name",
      },
      {
        Header: cmsRole.value,
        accessor: "Role",
      },
      {
        Header:
          props.screenName === "manage-user"
            ? cmsEmailString.value
            : "Primary Contact Email",
        accessor: "Primary Contact Email",
      },
      {
        Header: cmsInvitationStatus.value,
        accessor: "Invitation Status",
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      screenName={props.screenName}
      currentRoleAssigned={currentRole}
    />
  );
}

export default TableComponent;
