import React from 'react';
import MuiModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import './Modal.scss';

/**
 * Generic Modal component
 *
 * @param {Boolean}  open           indicates if the modal is opened
 * @param {Function} onClose        called when the modal is closed
 * @param {String}   title          the title of the modal
 * @param {Object}   footer         component rendered in the footer of the modal
 * @param {String}   className      additional classes
 * @param {Object}   children       children
 */
const Modal = ({
  open, onClose, title, footer, className, children,
}) => (
  <MuiModal
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
    className={`generic-modal ${className}`}
  >
    <Fade in={open}>
      <div className="generic-modal-window">
        <div className="generic-modal-header">
          {title}
        </div>
        <div className="generic-modal-body">
          {children}
        </div>
        <div className="generic-modal-footer">
          {footer}
        </div>
      </div>
    </Fade>
  </MuiModal>
);

export default Modal;
