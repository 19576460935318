import React from "react";
import MuiTablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import DoubleArrow from "components/generic/Icon/DoubleArrow";
import PaginationArrow from "components/generic/Icon/PaginationArrow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { cMSResources } from "index";

import "./TablePagination.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const BottomPaginationActions = (props) => {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  var cmsPage = findArrayElementByTitle(cMSResources, "{{Page}}");
  var cmsOf = findArrayElementByTitle(cMSResources, "{{Of}}");

  return (
    <div className="shape_flex shape_flex--align-center">
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        classes={{
          disabled: "generic-table-pagination-disabled",
          root: "generic-table-pagination-button generic-table-first-page",
        }}
      >
        <DoubleArrow />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        classes={{
          disabled: "generic-table-pagination-disabled",
          root: "generic-table-pagination-button generic-table-pagination-previous-page",
        }}
      >
        <PaginationArrow />
      </IconButton>
      <span className="generic-table-pagination-text">
        {cmsPage.value} {page + 1} {cmsOf.value}{" "}
        {Math.ceil(count / rowsPerPage) || 1}
      </span>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        classes={{
          disabled: "generic-table-pagination-disabled",
          root: "generic-table-pagination-button generic-table-pagination-next-page",
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        classes={{
          disabled: "generic-table-pagination-disabled",
          root: "generic-table-pagination-button generic-table-pagination-last-page",
        }}
      >
        <DoubleArrow />
      </IconButton>
    </div>
  );
};

const TablePagination = ({
  count,
  handleChangePage,
  rowsPerPage,
  page,
  handleChangeRowsPerPage,
}) => (
  <div>
    <MuiTablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={BottomPaginationActions}
      classes={{
        caption: "generic-table-pagination-caption",
        toolbar: "generic-table-pagination-toolbar",
        spacer: "generic-table-pagination-spacer",
        root: "generic-table-pagination-bottom",
      }}
    />
  </div>
);

export default TablePagination;
