import Swal from "sweetalert2";
import { createAction } from "@reduxjs/toolkit";
import request from "api/request";

export const toggleSimulationModal = createAction("simulationModal/toggle");
export const toggleSimulationExitConfirmationModal = createAction(
  "simulationExitConfirmationPopup/toggle"
);
export const toggleEditBillingInfoSuccessModal = createAction(
  "editBillingInfoSuccessModal/toggle"
);
export const toggleEditInstallationAddressSuccessModal = createAction(
  "editInstallationAddressSuccessModal/toggle"
);
export const toggleEditInstallationAliasSuccessModal = createAction(
  "editInstallationAliasSuccessModal/toggle"
);
export const togglePumpHoursModal = createAction("pumpHoursModal/toggle");
export const togglePumpHoursSuccessModal = createAction(
  "pumpHoursSuccessModal/toggle"
);
export const toggleRequestServiceModal = createAction(
  "requestServiceModal/toggle"
);
export const toggleRequestServiceSuccessModal = createAction(
  "requestServiceSuccessModal/toggle"
);
export const toggleLicenseAgreementModal = createAction(
  "licenseAgreementModal/toggle"
);
export const toggleScheduleTrainingModal = createAction(
  "scheduleTrainingModal/toggle"
);
export const addVimeoData = createAction("vimeo/addData");
export const addAllVideosInShowcase = createAction("vimeo/allVideosInShowcase");
export const setOrders = createAction("orders/set");
export const setServiceHistory = createAction("serviceHistory/set");
export const setMachineSystems = createAction("machineSystems/set");
export const setAlias = createAction("alias/set");
export const setTileFeatureHistory = createAction("setTileFeatureHistory/set");
export const setLogoAndColorHistory = createAction(
  "setLogoAndColorHistory/set"
);

export const toggleModal = (show) => async (dispatch) => {
  try {
    dispatch({
      type: "TOGGLE_MODAL",
      value: { payload: show },
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const setClaims = (claims) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_CLAIMS",
      value: { payload: claims },
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getCustomers =
  (customerName, currentScreen) => async (dispatch) => {
    try {
      let response;
      await request({
        urlSlug: `api/UserData/GetUsers?customerName=${encodeURIComponent(
          customerName
        )}&currentScreen=${encodeURIComponent(currentScreen)}`,
      }).then((results) => {
        response = results;
      });
      dispatch({
        type: "GET_CUSTOMERS",
        value: { payload: response },
      });
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

export const getUsers = (customerName, currentScreen) => async (dispatch) => {
  try {
    let response;
    await request({
      urlSlug: `api/UserData/GetUsers?customerName=${encodeURIComponent(
        customerName
      )}&currentScreen=${encodeURIComponent(currentScreen)}`,
    }).then((results) => {
      response = results;
    });

    dispatch({
      type: "GET_USERS",
      value: { payload: response },
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const disableUsers = (userData) => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  try {
    await request({
      urlSlug: "api/UserData/UpdateUsers",
      payload: userData,
      method: "POST",
    })
      .then((results) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        if (!!results && results.success === true) {
          Swal.fire({
            title: "Success",
            text:
              userData.Action === "Disable User"
                ? "user disabled successfully"
                : "user enabled successfully",
            icon: "success",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "Internal Error",
            text: results.errorMessage || "please try again after sometime",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
    return true;
  } catch (err) {
    dispatch({
      type: "SET_SPINNER",
      value: { payload: false },
    });
    console.log(err);
    return false;
  }
};

export const getCustomersData = (fieldType, fieldValue) => async (dispatch) => {
  try {
    let response;
    await request({
      urlSlug: `api/UserData/GetCustomerData?fieldType=${encodeURIComponent(
        fieldType
      )}&fieldValue=${encodeURIComponent(fieldValue)}`,
    }).then((results) => {
      response = results;
    });
    dispatch({
      type: "GET_CUSTOMERDATA",
      value: { payload: response },
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const setCustomerData = () => async (dispatch) => {
  dispatch({
    type: "SET_CUSTOMERDATA",
  });
};

export const resendUserInvite = (userData) => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  try {
    await request({
      urlSlug: "api/UserData/UpdateUsers",
      payload: userData,
      method: "POST",
    })
      .then((results) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        if (!!results && results.success === true) {
          Swal.fire({
            title: "Success",
            text: "email sent successfully",
            icon: "success",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "Internal Error",
            text: results.errorMessage || "please try again after sometime",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
    return true;
  } catch (err) {
    dispatch({
      type: "SET_SPINNER",
      value: { payload: false },
    });
    console.log(err);
    return false;
  }
};

export const updateRegistrationStatus = () => async () => {
  try {
    await request({
      urlSlug: "api/UserData/RegisterUser",
      method: "POST",
    }).then(() => {});
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const toggleSideNav = () => async (dispatch) => {
  try {
    dispatch({
      type: "SET_SIDENAV",
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const spinnerToggle = (show) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_SPINNER",
      value: { payload: show },
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const setCurrentPage = (pageName) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_CURRENTPAGE",
      value: { payload: pageName },
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const setSimulationMode = (show) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_SIMULATION_MODE",
      value: { payload: show },
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const setSimulatedUserClaims =
  (simulatedUserClaims) => async (dispatch) => {
    try {
      dispatch({
        type: "SET_SIMULATEDUSER_CLAIMS",
        value: { payload: simulatedUserClaims },
      });
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

export const getCustomerInfo = () => async (dispatch) => {
  try {
    await request({ urlSlug: "api/customer/my" })
      .then((response) => {
        dispatch({
          type: "GET_CUSTOMER_INFO",
          value: { payload: response },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  } catch (err) {
    console.log(err);
  }
};

export const getAccountInfo = () => async (dispatch) => {
  try {
    await request({ urlSlug: "api/accountInfo" })
      .then((response) => {
        dispatch({
          type: "GET_ACCOUNT_INFO",
          value: { payload: response.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  } catch (err) {
    console.log(err);
  }
};

export const getBannersText = (culture) => async (dispatch) => {
  try {
    await request({ urlSlug: `api/UserData/GetCarouselBlob/${culture}` })
      .then((response) => {
        dispatch({
          type: "GET_BANNERS_TEXT",
          value: { payload: response },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  } catch (err) {
    console.log(err);
  }
};

export const changeBillingInfo = (billingInfo, culture) => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  await request({
    urlSlug: `api/accountinfo/billingInfo/${culture}`,
    payload: billingInfo,
    method: "PUT",
  })
    .then((response) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      if (response.success) {
        dispatch({
          type: toggleEditBillingInfoSuccessModal.type,
        });
      } else {
        Swal.fire({
          title: "Internal Error",
          text: response.errorMessage || "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};

export const changeInstallationAddress =
  (installationAddressData, siteId, defaultLanguage) => async (dispatch) => {
    dispatch({
      type: "SET_SPINNER",
      value: { payload: true },
    });
    await request({
      urlSlug: `api/accountinfo/InstallationAddress/${siteId}/${defaultLanguage}`,
      payload: installationAddressData,
      method: "PUT",
    })
      .then((response) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        if (response.success) {
          dispatch({
            type: toggleEditInstallationAddressSuccessModal.type,
          });
        } else {
          Swal.fire({
            title: "Internal Error",
            text: response.errorMessage || "please try again after sometime",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

export const changeInstallationAlias =
  (aliasData, systemId) => async (dispatch) => {
    dispatch({
      type: "SET_SPINNER",
      value: { payload: true },
    });
    await request({
      urlSlug: `api/accountinfo/systemAlias/${systemId}`,
      payload: aliasData,
      method: "PUT",
    })
      .then((response) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        if (response.success) {
          dispatch({
            type: toggleEditInstallationAliasSuccessModal.type,
          });
          dispatch({
            type: setAlias.type,
            value: { payload: { systemId, alias: aliasData.alias } },
          });
        } else {
          Swal.fire({
            title: "Internal Error",
            text: response.errorMessage || "please try again after sometime",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

export const submitPumpHours = (data) => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  await request({
    urlSlug: "api/pumpHours/submitHours",
    payload: data,
    method: "POST",
  })
    .then((response) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      if (response.success) {
        dispatch({
          type: togglePumpHoursSuccessModal.type,
        });
      } else {
        Swal.fire({
          title: "Internal Error",
          text: response.errorMessage || "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};

export const submitLicenseAgreement = () => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  await request({
    urlSlug: "api/LicenseAgreement/SaveLicenseAgreement",
    method: "POST",
  })
    .then((response) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
    })
    .catch((err) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};

export const requestService = (data) => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  await request({
    urlSlug: `api/requestService/CreateRequestService`,
    payload: data,
    method: "POST",
  })
    .then((response) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      if (response.success) {
        dispatch({
          type: toggleRequestServiceSuccessModal.type,
        });
      } else {
        Swal.fire({
          title: "Internal Error",
          text: response.errorMessage || "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};

export const scheduleTraining = (data, culture) => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  await request({
    urlSlug: `api/scheduleTraining/createScheduleTraining/${culture}`,
    payload: data,
    method: "POST",
  })
    .then((response) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      if (response.success) {
        dispatch({
          type: toggleRequestServiceSuccessModal.type,
        });
      } else {
        Swal.fire({
          title: "Internal Error",
          text: response.errorMessage || "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};

const getShowcaseData = async ({
  showcaseId,
  page,
  perPage,
  externalToken,
}) => {
  let title = "";
  let videos = [];
  let totalCount = 0;

  const pageQuery = page ? `&page=${page}&per_page=${perPage}` : "";

  await request({
    baseUrl: "https://api.vimeo.com/",
    urlSlug: `me/albums/${showcaseId}`,
    externalToken,
  }).then((response) => {
    title = response.name;
  });
  await request({
    baseUrl: "https://api.vimeo.com/",
    urlSlug: `me/albums/${showcaseId}/videos?sort=manual${pageQuery}`,
    externalToken,
  }).then(({ data, total }) => {
    videos = data;
    totalCount = total;
  });

  return { title, videos, totalCount };
};

export const getInitialVideos =
  ({ showcase1, showcase2, externalToken }) =>
  async (dispatch) => {
    dispatch({
      type: "SET_SPINNER",
      value: { payload: true },
    });
    try {
      const section1Data = await getShowcaseData({
        showcaseId: showcase1,
        page: 1,
        perPage: 3,
        externalToken,
      });
      const section2Data = await getShowcaseData({
        showcaseId: showcase2,
        page: 1,
        perPage: 3,
        externalToken,
      });
      dispatch({
        type: addVimeoData.type,
        value: [
          {
            videos: section2Data.videos,
            title: {
              id: showcase2,
              name: section2Data.title,
            },
            totalCount: section2Data.totalCount,
          },
          {
            videos: section1Data.videos,
            title: {
              id: showcase1,
              name: section1Data.title,
            },
            totalCount: section1Data.totalCount,
          },
        ],
      });
    } catch (err) {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    } finally {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
    }
  };

export const getAllVideos =
  ({ showcaseId, externalToken }) =>
  async (dispatch) => {
    dispatch({
      type: "SET_SPINNER",
      value: { payload: true },
    });
    try {
      const data = await getShowcaseData({
        showcaseId,
        externalToken,
      });

      dispatch({
        type: addAllVideosInShowcase.type,
        value: {
          videos: data.videos,
          title: {
            id: showcaseId,
            name: data.title,
          },
          totalCount: data.totalCount,
        },
      });

      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
    } catch (err) {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

export const getOrderHistory = (customerNumber) => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  await request({
    urlSlug: `api/CustomerPartsOrder/GetPartsOrder?customerNumber=${customerNumber}`,
  })
    .then((response) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      if (response) {
        dispatch({
          type: setOrders.type,
          value: { response },
        });
      } else {
        Swal.fire({
          title: "Internal Error",
          text: response.errorMessage || "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};

export const getOrderHistoryDetails = (orderNumber) => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  await request({
    urlSlug: `api/CustomerPartsOrder/GetPartsOrderDetails?orderNumber=${orderNumber}`,
  })
    .then((response) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      if (response) {
        dispatch({
          type: setOrders.type,
          value: { response },
        });
      } else {
        Swal.fire({
          title: "Internal Error",
          text: response.errorMessage || "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};

export const getServiceHistory = (customerNumber) => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  await request({
    urlSlug: `api/serviceActivity/GetServiceActivityHistory?customerNumber=${customerNumber}`,
  })
    .then((response) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      if (response) {
        dispatch({
          type: setServiceHistory.type,
          value: { response },
        });
      } else {
        Swal.fire({
          title: "Internal Error",
          text: response.errorMessage || "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};

export const getMachineSystems = (customerNumber) => async (dispatch) => {
  dispatch({
    type: "SET_SPINNER",
    value: { payload: true },
  });
  await request({
    urlSlug: `api/serviceActivity/GetMachineSystems?customerNumber=${customerNumber}`,
  })
    .then((response) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      if (response) {
        dispatch({
          type: setMachineSystems.type,
          value: { response },
        });
      } else {
        Swal.fire({
          title: "Internal Error",
          text: response.errorMessage || "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_SPINNER",
        value: { payload: false },
      });
      Swal.fire({
        title: "Internal Error",
        text: err,
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};

export const getLogoAndColor = (culture) => async (dispatch) => {
  try {
    await request({ urlSlug: `api/CmsData/GetLogoAndColor/${culture}` })
      .then((response) => {
        dispatch({
          type: "GET_LOGO_AND_COLOR",
          value: { payload: response },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  } catch (err) {
    console.log(err);
  }
};

export const getOverwriteResources = (culture) => async (dispatch) => {
  try {
    await request({ urlSlug: `api/CmsData/GetOverwriteResources/${culture}` })
      .then((response) => {
        dispatch({
          type: "GET_OVERWRITE_RESOURCES",
          value: { payload: response },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  } catch (err) {
    console.log(err);
  }
};

export const getPumpHoursReport =
  (dateFrom, dateTo, customerNumber) => async (dispatch) => {
    try {
      await request({
        urlSlug: `api/reports/GetPumpHoursReport/${dateFrom}/${dateTo}?customerNumber=${customerNumber}`,
      })
        .then((response) => {
          dispatch({
            type: "GET_PUMP_HOURS_REPORT",
            value: { payload: response },
          });
        })
        .catch((err) => {
          dispatch({
            type: "SET_SPINNER",
            value: { payload: false },
          });
          Swal.fire({
            title: "Internal Error",
            text: err,
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

export const getAnyMachine = (customerNumber) => async (dispatch) => {
  try {
    await request({
      urlSlug: `api/reports/GetAnyMachine?customerNumber=${customerNumber}`,
    })
      .then((response) => {
        dispatch({
          type: "GET_ANY_MACHINE",
          value: { payload: response },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  } catch (err) {
    console.log(err);
  }
};

export const getDownloadPumpHoursReport = (
  dateFrom,
  dateTo,
  customerNumber
) => {
  return async (dispatch) => {
    try {
      const downloadUrl = await request({
        urlSlug: `api/reports/DownloadPumpHoursReport/${dateFrom}/${dateTo}?customerNumber=${customerNumber}`,
      });

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "pump_hours_report.csv");
      document.body.appendChild(link);
      link.click();

      dispatch({ type: "GET_DOWNLOAD_PUMP_HOURS_REPORT_SUCCESS" });
    } catch (error) {
      dispatch({ type: "GET_DOWNLOAD_PUMP_HOURS_REPORT_ERROR", error });
    }
  };
};

export const getWebPages = (culture) => async (dispatch) => {
  try {
    await request({ urlSlug: `api/CmsData/GetWebPages/${culture}` })
      .then((response) => {
        dispatch({
          type: "GET_WEB_PAGES",
          value: { payload: response },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_SPINNER",
          value: { payload: false },
        });
        Swal.fire({
          title: "Internal Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  } catch (err) {
    console.log(err);
  }
};
