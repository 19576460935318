import React from 'react';
import classNames from 'classnames';
import Select from 'components/generic/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import './BorderedHeading.scss';

/**
 * Heading with border bellow it. Icon in front is optional.
 *
 * @param {String}  text      heading text
 * @param {Object}  icon      icon node
 * @param {Boolean} bold      id the heading bold
 * @param {String}  className additional classes
 * @param {Object}  button    button
 */
const BorderedHeading = ({
  text, icon, bold = true, className, button, hasFilter = false,
}) => {
  const selectedPeriod = 'last 7 days';

  return (
    <div
      className={classNames('bordered-heading', className, {
        'bordered-heading-bold': bold,
      })}
    >
      <span>
        {icon && icon}
        {' '}
        {text}
      </span>
      {button && button}
      {hasFilter && (
        <>
          <div className="filter">
            <InputLabel
              classes={{ root: 'generic_select__label' }}
              id="waterjet-select"
            >
              Date Range:
            </InputLabel>
            <Select
              labelId="waterjet-select"
              displayEmpty
              value={selectedPeriod}
              // onChange={handleSelectChanged}
              variant="outlined"
              placeholderSelected={!selectedPeriod}
            >
              <MenuItem key="last 7 days" value="last 7 days">
                last 7 days
              </MenuItem>
            </Select>
          </div>
        </>
      )}
    </div>
  );
};

export default BorderedHeading;
