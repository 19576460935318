import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import HighLightAlt from "components/generic/Icon/HighLightAlt";
import Logout from "components/generic/Icon/Logout";
import LogoSwitch from "components/LogoSwitch/LogoSwitch";
import PhoneIcon from "@material-ui/icons/Phone";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import RequestServiceModal from "components/RequestServiceModal/RequestServiceModal";
import InfoModal from "components/generic/InfoModal/InfoModal";
import Modal from "components/generic/Modal/Modal";
import PumpHoursModal from "components/PumpHoursModal/PumpHoursModal";
import LicenseAgreementModal from "components/LicenseAgreementModal/LicenseAgreementModal";
import ScheduleTrainingModal from "components/ScheduleTrainingModal/ScheduleTrainingModal";
import ModalButtons from "components/generic/Modal/ModalButtons/ModalButtons";
import {
  setSimulationMode,
  toggleSimulationModal,
  toggleSimulationExitConfirmationModal,
  toggleRequestServiceModal,
  togglePumpHoursModal as togglePumpHoursModalAction,
  togglePumpHoursSuccessModal,
  toggleRequestServiceSuccessModal,
  toggleLicenseAgreementModal as toggleLicenseAgreementModalAction,
  toggleScheduleTrainingModal as toggleScheduleTrainingModalAction,
} from "store/actions";
import { getCurrentRoleConfig } from "store/reducers/index";
import {
  manageAuth,
  cMSResources,
  tilesDetails,
  cmsLicenseAgreement,
} from "../../../index";

/**
 * Drawer at the left hand-side of the site which contains links
 *
 * @param {Object}   classes            styles classes
 * @param {Function} handleDrawerClose  closes the drawer
 * @param {Boolean}  isGlobalAdminRole  flag which determines if the user has Global Admin Role
 * @param {Boolean}  open               is the drawer open
 * @param {Boolean}  inSimulationMode   is the simulation mode "on"
 */

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

function findArrayElementBySegment(array, segment) {
  return array.find((element) => {
    return element.segment === segment;
  });
}

const DrawerNavSideBar = ({
  classes,
  handleDrawerClose,
  isGlobalAdminRole,
  open,
  inSimulationMode,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const roleConfig = useSelector(getCurrentRoleConfig);
  const openBookServiceModalOpen = useSelector(
    (state) => state.modals.bookServiceModal.isOpen
  );
  const openLicenseAgreementModalOpen = useSelector(
    (state) => state.modals.licenseAgreementModal.isOpen
  );
  const openScheduleTrainingOpen = useSelector(
    (state) => state.modals.scheduleTrainingModal.isOpen
  );
  const pumpHoursModalOpen = useSelector(
    (state) => state.modals.pumpHoursModal.isOpen
  );
  const pumpHoursSuccessModalOpen = useSelector(
    (state) => state.modals.pumpHoursSuccessModal.isOpen
  );
  const requestServiceSuccessModalOpen = useSelector(
    (state) => state.modals.requestServiceSuccessModal.isOpen
  );

  var cmsPumpHours = findArrayElementByTitle(cMSResources, "{{PumpHours}}");
  var cmsRequestService = findArrayElementByTitle(
    cMSResources,
    "{{RequestService}}"
  );
  var cmsChangePassword = findArrayElementByTitle(
    cMSResources,
    "{{ChangePassword}}"
  );
  var cmsSignOut = findArrayElementByTitle(cMSResources, "{{SignOut}}");

  var pumpHoursTile = findArrayElementBySegment(tilesDetails, "pump-hours");
  var requestServiceTile = findArrayElementBySegment(
    tilesDetails,
    "request-service"
  );
  var contactUsTile = findArrayElementBySegment(tilesDetails, "contact-us");

  var cmsPumpHoursDisplay = pumpHoursTile.isAdminHidden == true ? "none" : "";
  var cmsRequestServiceDisplay =
    requestServiceTile.isAdminHidden == true ? "none" : "";

  const toggleServiceModal = () => {
    handleDrawerClose();
    dispatch(toggleRequestServiceModal());
  };

  const togglePumpHoursModal = () => {
    dispatch(togglePumpHoursModalAction());
  };

  const toggleLicenseAgreementModal = () => {
    dispatch(toggleLicenseAgreementModalAction());
  };

  const toggleSchedTrainingModal = () => {
    dispatch(toggleScheduleTrainingModalAction());
  };

  const renderTopLinks = () =>
    Object.keys(roleConfig.drawerItems).map((navItem) => {
      if (roleConfig.drawerItems[navItem].link.indexOf("http") !== -1) {
        return (
          <div style={{ display: roleConfig.drawerItems[navItem].display }}>
            <a
              href={roleConfig.drawerItems[navItem].link}
              target="_blank"
              rel="noopener noreferrer"
              key={roleConfig.drawerItems[navItem].link}
              className={classes.drawerLink}
              style={{ order: roleConfig.drawerItems[navItem].drawerOrder }}
            >
              <ListItem className={classes.listItem} button>
                <ListItemIcon className={classes.listItemIcon}>
                  <span className={classes.materialIcon}>
                    <LogoSwitch navId={roleConfig.drawerItems[navItem].id} />
                  </span>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={roleConfig.drawerItems[navItem].drawerTitle}
                />
              </ListItem>
            </a>
          </div>
        );
      }

      return (
        <div style={{ display: roleConfig.drawerItems[navItem].display }}>
          <Link
            to={roleConfig.drawerItems[navItem].link}
            className={classes.drawerLink}
            key={roleConfig.drawerItems[navItem].id}
            style={{ order: roleConfig.drawerItems[navItem].drawerOrder }}
          >
            <ListItem
              className={classes.listItem}
              button
              key={roleConfig.drawerItems[navItem].link}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <span className={classes.materialIcon}>
                  <LogoSwitch navId={roleConfig.drawerItems[navItem].id} />
                </span>
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={roleConfig.drawerItems[navItem].drawerTitle}
              />
            </ListItem>
          </Link>
        </div>
      );
    });

  return (
    <>
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={handleDrawerClose}
            className={classes.drawerChevronIconButton}
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider className={classes.drawerDivider} />
        <List
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
          classes={{ root: classes.drawerListRoot }}
        >
          {renderTopLinks()}
          {(!isGlobalAdminRole || inSimulationMode) && (
            <>
              <ListItem
                className={classes.listItem}
                button
                style={{
                  order: cmsRequestService.sortOrder,
                  display: cmsRequestServiceDisplay,
                }}
                onClick={toggleServiceModal}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <span className={classes.materialIcon}>
                    <EventIcon />
                  </span>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={requestServiceTile.tileTitle}
                />
              </ListItem>
              <ListItem
                className={classes.listItem}
                button
                style={{
                  order: cmsPumpHours.sortOrder,
                  display: cmsPumpHoursDisplay,
                }}
                onClick={togglePumpHoursModal}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <span className={classes.materialIcon}>
                    <ScheduleIcon />
                  </span>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={pumpHoursTile.tileTitle}
                />
              </ListItem>
            </>
          )}
        </List>
        <Divider
          className={classNames(
            classes.drawerDivider,
            classes.drawerDividerBottom
          )}
        />
        {isGlobalAdminRole && (
          <Link
            to="/"
            key="simulation-mode-item"
            className={classes.drawerLink}
            onClick={() => {
              if (inSimulationMode) {
                if (window.matchMedia("(max-width: 639px)").matches) {
                  dispatch(setSimulationMode(false));
                  localStorage.clear("inSimulationMode");
                  localStorage.clear("simulatedUser");
                  return;
                }
                dispatch(toggleSimulationExitConfirmationModal());
              } else {
                dispatch(toggleSimulationModal());
              }
              handleDrawerClose();
            }}
          >
            <ListItem className={classes.listItem} button>
              <ListItemIcon className={classes.listItemIcon}>
                <span
                  className={`${classes.materialIcon} ${classes.simulateIcon}`}
                >
                  <HighLightAlt />
                </span>
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={inSimulationMode ? "Exit simulation" : "Simulate"}
              />
            </ListItem>
          </Link>
        )}
        <List onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
          {(!isGlobalAdminRole || inSimulationMode) && (
            <>
              <Link
                to="/contactUs"
                className={classes.drawerLink}
                onClick={handleDrawerClose}
                onKeyDown={handleDrawerClose}
              >
                <ListItem button className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <span className={classes.materialIcon}>
                      <PhoneIcon />
                    </span>
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={contactUsTile.tileTitle}
                  />
                </ListItem>
              </Link>
              <ListItem
                button
                className={classes.listItem}
                onClick={() => {
                  !inSimulationMode && manageAuth("reset-password");
                }}
                disabled={inSimulationMode}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <span className={classes.materialIcon}>
                    <LockOutlinedIcon />
                  </span>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={cmsChangePassword.value}
                />
              </ListItem>
            </>
          )}
          {isGlobalAdminRole &&
            !inSimulationMode &&
            Object.keys(roleConfig.drawerItemsBottom).map((navItem) => (
              <Link
                to={roleConfig.drawerItemsBottom[navItem].link}
                className={classes.drawerLink}
                key={roleConfig.drawerItemsBottom[navItem].id}
              >
                <ListItem
                  className={classes.listItem}
                  button
                  key={roleConfig.drawerItemsBottom[navItem].link}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <span className={classes.materialIcon}>
                      <LogoSwitch
                        navId={roleConfig.drawerItemsBottom[navItem].id}
                      />
                    </span>
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={roleConfig.drawerItemsBottom[navItem].drawerTitle}
                  />
                </ListItem>
              </Link>
            ))}
          <ListItem
            button
            className={classes.listItem}
            onClick={() => {
              !inSimulationMode && manageAuth("logout");
            }}
            disabled={inSimulationMode}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <span className={classes.materialIcon}>
                <Logout viewBox="0 0 18 18" />
              </span>
            </ListItemIcon>
            <ListItemText disableTypography primary={cmsSignOut.value} />
          </ListItem>
        </List>
      </Drawer>
      <RequestServiceModal
        open={openBookServiceModalOpen}
        onClose={toggleServiceModal}
      />
      <PumpHoursModal
        open={pumpHoursModalOpen}
        onClose={togglePumpHoursModal}
      />
      <InfoModal
        open={pumpHoursSuccessModalOpen}
        title="Success"
        onClose={() => dispatch(togglePumpHoursSuccessModal())}
      >
        {cmsPumpHours.value}
      </InfoModal>
      <InfoModal
        open={requestServiceSuccessModalOpen}
        title="Success"
        onClose={() => dispatch(toggleRequestServiceSuccessModal())}
      >
        {cmsRequestService.value}
      </InfoModal>
      <LicenseAgreementModal
        open={openLicenseAgreementModalOpen}
        onClose={toggleLicenseAgreementModal}
      />
      <ScheduleTrainingModal
        open={openScheduleTrainingOpen}
        onClose={toggleSchedTrainingModal}
      />
    </>
  );
};

export default DrawerNavSideBar;
