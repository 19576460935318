import React from "react";
import { useLocation } from "react-router-dom";
import { webPages } from "../../index";

import "./CMSPage.scss";

function findArrayElementByKey(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const CMSPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const key = queryParams.get("key");

  var webPage = findArrayElementByKey(webPages, key);

  return (
    <>
      <div className="custom-CMS-info--wrapper">
        <div className="custom-CMS-info--title">
          {/* <h2 className="custom-CMS-info--title-heading">{webPage.pageTitle}</h2> */}
          <div
            dangerouslySetInnerHTML={{ __html: webPage.pageFlowContent }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default CMSPage;
