import { createReducer } from '@reduxjs/toolkit';
import {
  addVimeoData, addAllVideosInShowcase,
} from '../actions';

const initialState = [];

/**
 * Keeps vimeo data state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const vimeo = createReducer(initialState, {
  [addVimeoData.type]: (state, { value }) => value,
  [addAllVideosInShowcase.type]: (state, { value }) => {
    const currentVideoData = state.find((data) => data.title.id === Number(value.title.id));

    if (currentVideoData) {
      currentVideoData.title.name = value.title.name;
      currentVideoData.videos = value.videos;
    } else {
      return [...state, value];
    }
  },
});

export default vimeo;
