import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  GlobalAdmin: {
    first_slide: {
      text: '',
      subtext: '',
    },
    second_slide: {
      text: '',
      subtext: '',
    },
    third_slide: {
      text: '',
      subtext: '',
    },
  },
  CustomerTeamAdmin: {
    first_slide: {
      text: '',
      subtext: '',
    },
    second_slide: {
      text: '',
      subtext: '',
    },
    third_slide: {
      text: '',
      subtext: '',
    },
  },
  CustomerUser: {
    first_slide: {
      text: '',
      subtext: '',
    },
    second_slide: {
      text: '',
      subtext: '',
    },
    third_slide: {
      text: '',
      subtext: '',
    },
  },
};

/**
 * Keeps banner text configuration state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const bannerTextConfig = createReducer(initialState, {
  GET_BANNERS_TEXT: (state, { value }) => value.payload,
});

export default bannerTextConfig;
