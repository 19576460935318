import { createReducer } from '@reduxjs/toolkit';

const initialState = false;

/**
 * Keeps show side nav state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const showSideNav = createReducer(initialState, {
  SET_SIDENAV: (state) => !state,
});

export default showSideNav;
