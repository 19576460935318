import { createReducer } from '@reduxjs/toolkit';
import {
  setMachineSystems,
} from '../actions';

const initialState = null;

/**
 * Keeps machine systems state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const machineSystems = createReducer(initialState, {
  [setMachineSystems.type]: (state, { value }) => value.response,
});

export default machineSystems;
