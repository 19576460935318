import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/generic/Modal/Modal";
import Input from "components/generic/Input/Input";
import { useForm } from "react-hook-form";
import { changeBillingInfo } from "store/actions";
import { getEmail } from "store/reducers/index";
import { statesInitialsMap } from "../../../constants";
import AddressSection from "../AddressSection/AddressSection";
import ModalButtons from "../ModalButtons/ModalButtons";
import { cMSResources } from "index";

import "./EditBillingInfoModal.scss";

/**
 * Modal used to edit the user's billing information
 *
 * @param {Boolean}  open                 indicates if the modal is opened
 * @param {Function} onCloseHandler       called when the modal is closed
 * @param {Function} onSendRequestHandler called when edit address request is send
 * @param {Object}   accountInfo          account info data
 */

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const EditBillingInfoModal = ({
  open,
  onCloseHandler,
  onSendRequestHandler,
  accountInfo,
}) => {
  const email = useSelector(getEmail);
  const { address, contact } = accountInfo;
  const defaultValues = {
    street: address.street || "",
    city: address.city || "",
    state: statesInitialsMap[address.state] || "",
    postalCode: address.postalCode || "",
    name: contact.name || "",
    phone: contact.phone || "",
    email: contact.email || "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", reValidateMode: "onBlur", defaultValues });
  const dispatch = useDispatch();
  const defaultLanguage = sessionStorage.getItem("defaultLanguage");
  const onSubmit = ({ street, postalCode, city, state, name, phone }) => {
    onSendRequestHandler();
    dispatch(
      changeBillingInfo(
        {
          address: {
            street,
            postalCode,
            city,
            state,
            country: address.country,
          },
          contact: {
            name,
            phone,
            email: contact.email || email,
          },
        },
        defaultLanguage
      )
    );
  };

  const onModalClose = (event, reason) => {
    if (reason === "backdropClick") {
      onCloseHandler();
    }
  };

  var cmsSendRequest = findArrayElementByTitle(cMSResources, "{{SendRequest}}");
  var cmsCancel = findArrayElementByTitle(cMSResources, "{{Cancel}}");

  const buttonsConfig = [
    {
      text: cmsCancel.value,
      handler: onCloseHandler,
    },
    {
      text: cmsSendRequest.value,
      handler: handleSubmit(onSubmit),
    },
  ];

  var cmsRequestBillingInformationChange = findArrayElementByTitle(
    cMSResources,
    "{{RequestBillingInformationChange}}"
  );
  var cmsPrimaryContact = findArrayElementByTitle(
    cMSResources,
    "{{PrimaryContact}}"
  );
  var cmsPhone = findArrayElementByTitle(cMSResources, "{{PhoneLabel}}");
  var cmsName = findArrayElementByTitle(cMSResources, "{{Name}}");
  var cmsEmailString = findArrayElementByTitle(cMSResources, "{{EmailString}}");

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      className="billing-modal"
      title={cmsRequestBillingInformationChange.value}
      footer={<ModalButtons config={buttonsConfig} />}
    >
      <form className="billing-modal-form" noValidate>
        <AddressSection register={register} errors={errors} />
        <div className="billing-modal-contact-wrapper">
          <div className="billing-modal-heading">{cmsPrimaryContact.value}</div>
          <div className="billing-modal-input-line">
            <Input
              inputRef={register()}
              name="name"
              type="text"
              variant="outlined"
              label={cmsName.value}
            />
          </div>
          <div className="billing-modal-input-line billing-modal-input-line-phone">
            <Input
              inputRef={register({
                required: "The field is required",
              })}
              name="phone"
              required
              type="text"
              error={errors.phone?.message}
              variant="outlined"
              label={cmsPhone.value}
            />
            <div className="error-message">{errors.phone?.message}</div>
          </div>
          <div className="billing-modal-input-line">
            <Input
              inputRef={register()}
              name="email"
              type="text"
              variant="outlined"
              label={cmsEmailString.value}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditBillingInfoModal;
