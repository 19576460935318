import { createReducer } from "@reduxjs/toolkit";
import { ROLE_CONFIG } from "../../constants";
import {
  config,
  tilesDetails,
  customTilesDetails,
  cMSResources,
} from "../../index";
import { isCustomerAdmin } from "store/reducers";

const initialState = null;

const flowPartsLink = "https://flowparts.com/";

function findArrayElementBySegment(array, segment) {
  return array.find((element) => {
    return element.segment === segment;
  });
}

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const getRoleConfig = (claims) => {
  var waterjetConnectIQ = findArrayElementBySegment(
    tilesDetails,
    "waterjet-connect-iq"
  );
  var purchaseParts = findArrayElementBySegment(tilesDetails, "purchase-parts");
  var reports = findArrayElementBySegment(tilesDetails, "reports");
  var partsOrder = findArrayElementBySegment(tilesDetails, "parts-orders");
  var scheduleTraining = findArrayElementBySegment(
    tilesDetails,
    "schedule-training"
  );
  var serviceActivity = findArrayElementBySegment(
    tilesDetails,
    "service-activity"
  );
  var docsAndVideo = findArrayElementBySegment(tilesDetails, "docs-and-videos");
  var upcomingEvents = findArrayElementBySegment(
    tilesDetails,
    "upcoming-events"
  );
  var manageUsers = findArrayElementBySegment(tilesDetails, "manage-users");
  var contactUs = findArrayElementBySegment(tilesDetails, "contact-us");
  var customPage = findArrayElementBySegment(tilesDetails, "custom-page");
  var dynamiPage1 = findArrayElementBySegment(
    customTilesDetails,
    "dynamic-page-0"
  );
  var dynamiPage2 = findArrayElementBySegment(
    customTilesDetails,
    "dynamic-page-1"
  );
  var dynamiPage3 = findArrayElementBySegment(
    customTilesDetails,
    "dynamic-page-2"
  );
  var dynamiPage4 = findArrayElementBySegment(
    customTilesDetails,
    "dynamic-page-3"
  );
  var dynamiPage5 = findArrayElementBySegment(
    customTilesDetails,
    "dynamic-page-4"
  );
  var dynamiPage6 = findArrayElementBySegment(
    customTilesDetails,
    "dynamic-page-5"
  );
  var dynamiPage7 = findArrayElementBySegment(
    customTilesDetails,
    "dynamic-page-6"
  );
  var dynamiPage8 = findArrayElementBySegment(
    customTilesDetails,
    "dynamic-page-7"
  );
  var dynamiPage9 = findArrayElementBySegment(
    customTilesDetails,
    "dynamic-page-8"
  );

  var cmsHome = findArrayElementByTitle(cMSResources, "{{Home}}");
  const getUserRole = (role) => {
    const userRoles = {
      GlobalAdmin: { value: "GlobalAdmin", name: "Global Admin" },
      CustomerUser: { value: "CustomerUser", name: "Customer User" },
      CustomerTeamAdmin: {
        value: "CustomerTeamAdmin",
        name: "Customer Team Admin",
      },
      CustomerTeamAdminCustomerUser: {
        value: "CustomerTeamAdmin",
        name: "Customer User & Admin",
      },
    };

    if (role.length === 2) {
      return userRoles.CustomerTeamAdminCustomerUser.name;
    }

    return userRoles[role[0]].name;
  };

  const customerTeamAdminCustomerUserConfig = {
    ...ROLE_CONFIG.CustomerTeamAdminCustomerUser,
  };
  customerTeamAdminCustomerUserConfig.drawerItems.flowGoHome.drawerTitle =
    cmsHome.value;

  customerTeamAdminCustomerUserConfig.navItems.iotDashboard.link =
    waterjetConnectIQ.tileLink;
  customerTeamAdminCustomerUserConfig.navItems.iotDashboard.tileTitle =
    waterjetConnectIQ.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.iotDashboard.description =
    waterjetConnectIQ.tileDescription;
  customerTeamAdminCustomerUserConfig.drawerItems.iotDashboard.link =
    waterjetConnectIQ.tileLink;
  customerTeamAdminCustomerUserConfig.drawerItems.iotDashboard.drawerTitle =
    waterjetConnectIQ.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.iotDashboard.tileIcon =
    waterjetConnectIQ.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.iotDashboard.tileOrder =
    waterjetConnectIQ.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.iotDashboard.tileTitleColor =
    waterjetConnectIQ.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.iotDashboard.tileDescriptionColor =
    waterjetConnectIQ.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.iotDashboard.disabled =
    waterjetConnectIQ.isComingSoon;

  customerTeamAdminCustomerUserConfig.navItems.purchase.link =
    purchaseParts.tileLink || flowPartsLink;
  customerTeamAdminCustomerUserConfig.navItems.purchase.tileTitle =
    purchaseParts.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.purchase.description =
    purchaseParts.tileDescription;
  customerTeamAdminCustomerUserConfig.drawerItems.purchase.link =
    purchaseParts.tileLink || flowPartsLink;
  customerTeamAdminCustomerUserConfig.drawerItems.purchase.drawerTitle =
    purchaseParts.tileTitle || flowPartsLink;
  customerTeamAdminCustomerUserConfig.navItems.purchase.tileIcon =
    purchaseParts.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.purchase.tileOrder =
    purchaseParts.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.purchase.tileTitleColor =
    purchaseParts.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.purchase.tileDescriptionColor =
    purchaseParts.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.purchase.disabled =
    purchaseParts.isComingSoon;

  customerTeamAdminCustomerUserConfig.navItems.orderHistory.tileTitle =
    partsOrder.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.orderHistory.description =
    partsOrder.tileDescription;
  customerTeamAdminCustomerUserConfig.navItems.orderHistory.tileIcon =
    partsOrder.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.orderHistory.tileOrder =
    purchaseParts.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.orderHistory.tileTitleColor =
    partsOrder.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.orderHistory.tileDescriptionColor =
    purchaseParts.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.orderHistory.disabled =
    purchaseParts.isComingSoon;

  customerTeamAdminCustomerUserConfig.navItems.scheduleTraining.tileTitle =
    scheduleTraining.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.scheduleTraining.description =
    scheduleTraining.tileDescription;
  customerTeamAdminCustomerUserConfig.navItems.scheduleTraining.tileIcon =
    scheduleTraining.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.scheduleTraining.link =
    scheduleTraining.tileLink;
  customerTeamAdminCustomerUserConfig.navItems.scheduleTraining.tileOrder =
    scheduleTraining.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.scheduleTraining.tileTitleColor =
    scheduleTraining.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.scheduleTraining.tileDescriptionColor =
    scheduleTraining.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.scheduleTraining.disabled =
    scheduleTraining.isComingSoon;

  customerTeamAdminCustomerUserConfig.navItems.serviceHistory.tileTitle =
    serviceActivity.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.serviceHistory.description =
    serviceActivity.tileDescription;
  customerTeamAdminCustomerUserConfig.navItems.serviceHistory.tileIcon =
    serviceActivity.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.serviceHistory.tileOrder =
    serviceActivity.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.serviceHistory.tileTitleColor =
    serviceActivity.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.serviceHistory.tileDescriptionColor =
    serviceActivity.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.serviceHistory.disabled =
    serviceActivity.isComingSoon;

  customerTeamAdminCustomerUserConfig.navItems.docVideo.tileTitle =
    docsAndVideo.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.docVideo.drawerTitle =
    docsAndVideo.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.docVideo.description =
    docsAndVideo.tileDescription;
  customerTeamAdminCustomerUserConfig.navItems.docVideo.tileIcon =
    docsAndVideo.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.docVideo.tileOrder =
    docsAndVideo.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.docVideo.tileTitleColor =
    docsAndVideo.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.docVideo.tileDescriptionColor =
    docsAndVideo.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.docVideo.disabled =
    docsAndVideo.isComingSoon;

  if (docsAndVideo.tileLink.length) {
    customerTeamAdminCustomerUserConfig.navItems.docVideo.link =
      docsAndVideo.tileLink;
  }

  customerTeamAdminCustomerUserConfig.navItems.comingAttractions.tileTitle =
    upcomingEvents.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.comingAttractions.description =
    upcomingEvents.tileDescription;
  customerTeamAdminCustomerUserConfig.navItems.comingAttractions.tileIcon =
    upcomingEvents.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.comingAttractions.link =
    upcomingEvents.tileLink;
  customerTeamAdminCustomerUserConfig.navItems.comingAttractions.tileOrder =
    upcomingEvents.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.comingAttractions.tileTitleColor =
    upcomingEvents.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.comingAttractions.tileDescriptionColor =
    upcomingEvents.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.comingAttractions.disabled =
    upcomingEvents.isComingSoon;

  customerTeamAdminCustomerUserConfig.navItems.manageUsers.tileTitle =
    manageUsers.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.manageUsers.description =
    manageUsers.tileDescription;
  customerTeamAdminCustomerUserConfig.navItems.manageUsers.tileIcon =
    manageUsers.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.manageUsers.tileOrder =
    manageUsers.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.manageUsers.tileTitleColor =
    manageUsers.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.manageUsers.tileDescriptionColor =
    manageUsers.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.manageUsers.disabled =
    manageUsers.isComingSoon;

  customerTeamAdminCustomerUserConfig.navItems.contactUs.tileTitle =
    contactUs.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.contactUs.description =
    contactUs.tileDescription;
  customerTeamAdminCustomerUserConfig.navItems.contactUs.tileIcon =
    contactUs.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.contactUs.tileOrder =
    contactUs.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.contactUs.tileTitleColor =
    contactUs.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.contactUs.tileDescriptionColor =
    contactUs.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.contactUs.disabled =
    contactUs.isComingSoon;

  customerTeamAdminCustomerUserConfig.navItems.reports.tileTitle =
    reports.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.reports.description =
    reports.tileDescription;
  customerTeamAdminCustomerUserConfig.navItems.reports.tileIcon =
    reports.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.reports.tileOrder =
    reports.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.reports.tileTitleColor =
    reports.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.reports.tileDescriptionColor =
    reports.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.reports.disabled =
    reports.isComingSoon;

  const customerTeamAdminConfig = { ...ROLE_CONFIG.CustomerTeamAdmin };

  customerTeamAdminConfig.drawerItems.flowGoHome.drawerTitle = cmsHome.value;

  customerTeamAdminConfig.navItems.iotDashboard.link =
    waterjetConnectIQ.tileLink;
  customerTeamAdminConfig.navItems.iotDashboard.tileTitle =
    waterjetConnectIQ.tileTitle;
  customerTeamAdminConfig.navItems.iotDashboard.description =
    waterjetConnectIQ.tileDescription;
  customerTeamAdminConfig.drawerItems.iotDashboard.link =
    waterjetConnectIQ.tileLink;
  customerTeamAdminConfig.drawerItems.iotDashboard.drawerTitle =
    waterjetConnectIQ.tileTitle;
  customerTeamAdminConfig.navItems.iotDashboard.tileIcon =
    waterjetConnectIQ.tileIcon;
  customerTeamAdminConfig.navItems.iotDashboard.tileOrder =
    waterjetConnectIQ.sortOrder;
  customerTeamAdminConfig.navItems.iotDashboard.tileTitleColor =
    waterjetConnectIQ.tileTitleColor;
  customerTeamAdminConfig.navItems.iotDashboard.tileDescriptionColor =
    waterjetConnectIQ.tileDescriptionColor;
  customerTeamAdminConfig.navItems.iotDashboard.disabled =
    waterjetConnectIQ.isComingSoon;

  customerTeamAdminConfig.navItems.purchase.link =
    purchaseParts.tileLink || flowPartsLink;
  customerTeamAdminConfig.navItems.purchase.tileTitle = purchaseParts.tileTitle;
  customerTeamAdminConfig.navItems.purchase.description =
    purchaseParts.tileDescription;
  customerTeamAdminConfig.drawerItems.purchase.link =
    purchaseParts.tileLink || flowPartsLink;
  customerTeamAdminConfig.drawerItems.purchase.drawerTitle =
    purchaseParts.tileTitle || flowPartsLink;
  customerTeamAdminConfig.navItems.purchase.tileIcon = purchaseParts.tileIcon;
  customerTeamAdminConfig.navItems.purchase.tileOrder = purchaseParts.sortOrder;
  customerTeamAdminConfig.navItems.purchase.tileTitleColor =
    purchaseParts.tileTitleColor;
  customerTeamAdminConfig.navItems.purchase.tileDescriptionColor =
    purchaseParts.tileDescriptionColor;
  customerTeamAdminConfig.navItems.purchase.disabled =
    purchaseParts.isComingSoon;

  customerTeamAdminConfig.navItems.orderHistory.tileTitle =
    partsOrder.tileTitle;
  customerTeamAdminConfig.navItems.orderHistory.description =
    partsOrder.tileDescription;
  customerTeamAdminConfig.navItems.orderHistory.tileIcon = partsOrder.tileIcon;
  customerTeamAdminConfig.navItems.orderHistory.tileOrder =
    partsOrder.sortOrder;
  customerTeamAdminConfig.navItems.orderHistory.tileTitleColor =
    partsOrder.tileTitleColor;
  customerTeamAdminConfig.navItems.orderHistory.tileDescriptionColor =
    partsOrder.tileDescriptionColor;
  customerTeamAdminConfig.navItems.orderHistory.disabled =
    partsOrder.isComingSoon;

  customerTeamAdminConfig.navItems.scheduleTraining.tileTitle =
    scheduleTraining.tileTitle;
  customerTeamAdminConfig.navItems.scheduleTraining.description =
    scheduleTraining.tileDescription;
  customerTeamAdminConfig.navItems.scheduleTraining.tileIcon =
    scheduleTraining.tileIcon;
  customerTeamAdminConfig.navItems.scheduleTraining.link =
    scheduleTraining.tileLink;
  customerTeamAdminConfig.navItems.scheduleTraining.tileOrder =
    scheduleTraining.sortOrder;
  customerTeamAdminConfig.navItems.scheduleTraining.tileTitleColor =
    scheduleTraining.tileTitleColor;
  customerTeamAdminConfig.navItems.scheduleTraining.tileDescriptionColor =
    scheduleTraining.tileDescriptionColor;
  customerTeamAdminConfig.navItems.scheduleTraining.disabled =
    scheduleTraining.isComingSoon;

  customerTeamAdminConfig.navItems.serviceHistory.tileTitle =
    serviceActivity.tileTitle;
  customerTeamAdminConfig.navItems.serviceHistory.description =
    serviceActivity.tileDescription;
  customerTeamAdminConfig.navItems.serviceHistory.tileIcon =
    serviceActivity.tileIcon;
  customerTeamAdminConfig.navItems.serviceHistory.tileOrder =
    serviceActivity.sortOrder;
  customerTeamAdminConfig.navItems.serviceHistory.tileTitleColor =
    serviceActivity.tileTitleColor;
  customerTeamAdminConfig.navItems.serviceHistory.tileDescriptionColor =
    serviceActivity.tileDescriptionColor;
  customerTeamAdminConfig.navItems.serviceHistory.disabled =
    serviceActivity.isComingSoon;

  customerTeamAdminConfig.navItems.docVideo.tileTitle = docsAndVideo.tileTitle;
  customerTeamAdminConfig.navItems.docVideo.drawerTitle =
    docsAndVideo.tileTitle;
  customerTeamAdminConfig.navItems.docVideo.description =
    docsAndVideo.tileDescription;
  customerTeamAdminConfig.navItems.docVideo.tileIcon = docsAndVideo.tileIcon;
  customerTeamAdminConfig.navItems.docVideo.tileOrder = docsAndVideo.sortOrder;
  customerTeamAdminConfig.navItems.docVideo.tileTitleColor =
    docsAndVideo.tileTitleColor;
  customerTeamAdminConfig.navItems.docVideo.tileDescriptionColor =
    docsAndVideo.tileDescriptionColor;
  customerTeamAdminConfig.navItems.docVideo.disabled =
    docsAndVideo.isComingSoon;

  if (docsAndVideo.tileLink.length) {
    customerTeamAdminConfig.navItems.docVideo.link = docsAndVideo.tileLink;
  }

  customerTeamAdminConfig.navItems.comingAttractions.tileTitle =
    upcomingEvents.tileTitle;
  customerTeamAdminConfig.navItems.comingAttractions.description =
    upcomingEvents.tileDescription;
  customerTeamAdminConfig.navItems.comingAttractions.tileIcon =
    upcomingEvents.tileIcon;
  customerTeamAdminConfig.navItems.comingAttractions.link =
    upcomingEvents.tileLink;
  customerTeamAdminConfig.navItems.comingAttractions.tileOrder =
    upcomingEvents.sortOrder;
  customerTeamAdminConfig.navItems.comingAttractions.tileTitleColor =
    upcomingEvents.tileTitleColor;
  customerTeamAdminConfig.navItems.comingAttractions.tileDescriptionColor =
    upcomingEvents.tileDescriptionColor;
  customerTeamAdminConfig.navItems.comingAttractions.disabled =
    upcomingEvents.isComingSoon;

  customerTeamAdminConfig.navItems.manageUsers.tileTitle =
    manageUsers.tileTitle;
  customerTeamAdminConfig.navItems.manageUsers.description =
    manageUsers.tileDescription;
  customerTeamAdminConfig.navItems.manageUsers.tileIcon = manageUsers.tileIcon;
  customerTeamAdminConfig.navItems.manageUsers.tileOrder =
    manageUsers.sortOrder;
  customerTeamAdminConfig.navItems.manageUsers.tileTitleColor =
    manageUsers.tileTitleColor;
  customerTeamAdminConfig.navItems.manageUsers.tileDescriptionColor =
    manageUsers.tileDescriptionColor;
  customerTeamAdminConfig.navItems.manageUsers.disabled =
    manageUsers.isComingSoon;

  customerTeamAdminConfig.navItems.contactUs.tileTitle = contactUs.tileTitle;
  customerTeamAdminConfig.navItems.contactUs.description =
    contactUs.tileDescription;
  customerTeamAdminConfig.navItems.contactUs.tileIcon = contactUs.tileIcon;
  customerTeamAdminConfig.navItems.contactUs.tileOrder = contactUs.sortOrder;
  customerTeamAdminConfig.navItems.contactUs.tileTitleColor =
    contactUs.tileTitleColor;
  customerTeamAdminConfig.navItems.contactUs.tileDescriptionColor =
    contactUs.tileDescriptionColor;
  customerTeamAdminConfig.navItems.contactUs.disabled = contactUs.isComingSoon;

  customerTeamAdminConfig.navItems.reports.tileTitle = reports.tileTitle;
  customerTeamAdminConfig.navItems.reports.description =
    reports.tileDescription;
  customerTeamAdminConfig.navItems.reports.tileIcon = reports.tileIcon;
  customerTeamAdminConfig.navItems.reports.tileOrder = reports.sortOrder;
  customerTeamAdminConfig.navItems.reports.tileTitleColor =
    reports.tileTitleColor;
  customerTeamAdminConfig.navItems.reports.tileDescriptionColor =
    reports.tileDescriptionColor;
  customerTeamAdminConfig.navItems.reports.disabled = reports.isComingSoon;

  const customerUserConfig = { ...ROLE_CONFIG.CustomerUser };
  customerUserConfig.drawerItems.flowGoHome.drawerTitle = cmsHome.value;

  customerUserConfig.navItems.iotDashboard.link = waterjetConnectIQ.tileLink;
  customerUserConfig.navItems.iotDashboard.tileTitle =
    waterjetConnectIQ.tileTitle;
  customerUserConfig.navItems.iotDashboard.description =
    waterjetConnectIQ.tileDescription;
  customerUserConfig.drawerItems.iotDashboard.link = waterjetConnectIQ.tileLink;
  customerUserConfig.drawerItems.iotDashboard.drawerTitle =
    waterjetConnectIQ.tileTitle;
  customerUserConfig.navItems.iotDashboard.tileIcon =
    waterjetConnectIQ.tileIcon;
  customerUserConfig.navItems.iotDashboard.tileOrder =
    waterjetConnectIQ.sortOrder;
  customerUserConfig.navItems.iotDashboard.tileTitleColor =
    waterjetConnectIQ.tileTitleColor;
  customerUserConfig.navItems.iotDashboard.tileDescriptionColor =
    waterjetConnectIQ.tileDescriptionColor;
  customerUserConfig.navItems.iotDashboard.disabled =
    waterjetConnectIQ.isComingSoon;

  customerUserConfig.navItems.purchase.link =
    purchaseParts.tileLink || flowPartsLink;
  customerUserConfig.navItems.purchase.tileTitle = purchaseParts.tileTitle;
  customerUserConfig.navItems.purchase.description =
    purchaseParts.tileDescription;
  customerUserConfig.drawerItems.purchase.link =
    purchaseParts.tileLink || flowPartsLink;
  customerUserConfig.drawerItems.purchase.drawerTitle =
    purchaseParts.tileTitle || flowPartsLink;
  customerUserConfig.navItems.purchase.tileIcon = purchaseParts.tileIcon;
  customerUserConfig.navItems.purchase.tileOrder = purchaseParts.sortOrder;
  customerUserConfig.navItems.purchase.tileTitleColor =
    purchaseParts.tileTitleColor;
  customerUserConfig.navItems.purchase.tileDescriptionColor =
    purchaseParts.tileDescriptionColor;
  customerUserConfig.navItems.purchase.disabled = purchaseParts.isComingSoon;

  customerUserConfig.navItems.scheduleTraining.tileTitle =
    scheduleTraining.tileTitle;
  customerUserConfig.navItems.scheduleTraining.description =
    scheduleTraining.tileDescription;
  customerUserConfig.navItems.scheduleTraining.tileIcon =
    scheduleTraining.tileIcon;
  customerUserConfig.navItems.scheduleTraining.link = scheduleTraining.tileLink;
  customerUserConfig.navItems.scheduleTraining.tileOrder =
    scheduleTraining.sortOrder;
  customerUserConfig.navItems.scheduleTraining.tileTitleColor =
    scheduleTraining.tileTitleColor;
  customerUserConfig.navItems.scheduleTraining.tileDescriptionColor =
    scheduleTraining.tileDescriptionColor;
  customerUserConfig.navItems.scheduleTraining.disabled =
    scheduleTraining.isComingSoon;

  customerUserConfig.navItems.serviceHistory.tileTitle =
    serviceActivity.tileTitle;
  customerUserConfig.navItems.serviceHistory.description =
    serviceActivity.tileDescription;
  customerUserConfig.navItems.serviceHistory.tileIcon =
    serviceActivity.tileIcon;
  customerUserConfig.navItems.serviceHistory.tileOrder =
    serviceActivity.sortOrder;
  customerUserConfig.navItems.serviceHistory.tileTitleColor =
    serviceActivity.tileTitleColor;
  customerUserConfig.navItems.serviceHistory.tileDescriptionColor =
    serviceActivity.tileDescriptionColor;
  customerUserConfig.navItems.serviceHistory.disabled =
    serviceActivity.isComingSoon;

  customerUserConfig.navItems.docVideo.tileTitle = docsAndVideo.tileTitle;
  customerUserConfig.navItems.docVideo.drawerTitle = docsAndVideo.tileTitle;
  customerUserConfig.navItems.docVideo.description =
    docsAndVideo.tileDescription;
  customerUserConfig.navItems.docVideo.tileIcon = docsAndVideo.tileIcon;
  customerUserConfig.navItems.docVideo.tileOrder = docsAndVideo.sortOrder;
  customerUserConfig.navItems.docVideo.tileTitleColor =
    docsAndVideo.tileTitleColor;
  customerUserConfig.navItems.docVideo.tileDescriptionColor =
    docsAndVideo.tileDescriptionColor;
  customerUserConfig.navItems.docVideo.disabled = docsAndVideo.isComingSoon;

  if (docsAndVideo.tileLink.length) {
    customerUserConfig.navItems.docVideo.link = docsAndVideo.tileLink;
  }

  customerUserConfig.navItems.comingAttractions.tileTitle =
    upcomingEvents.tileTitle;
  customerUserConfig.navItems.comingAttractions.description =
    upcomingEvents.tileDescription;
  customerUserConfig.navItems.comingAttractions.tileIcon =
    upcomingEvents.tileIcon;
  customerUserConfig.navItems.comingAttractions.link = upcomingEvents.tileLink;
  customerUserConfig.navItems.comingAttractions.tileOrder =
    upcomingEvents.sortOrder;
  customerUserConfig.navItems.comingAttractions.tileTitleColor =
    upcomingEvents.tileTitleColor;
  customerUserConfig.navItems.comingAttractions.tileDescriptionColor =
    upcomingEvents.tileDescriptionColor;
  customerUserConfig.navItems.comingAttractions.disabled =
    upcomingEvents.isComingSoon;

  customerUserConfig.navItems.contactUs.tileTitle = contactUs.tileTitle;
  customerUserConfig.navItems.contactUs.description = contactUs.tileDescription;
  customerUserConfig.navItems.contactUs.tileIcon = contactUs.tileIcon;
  customerUserConfig.navItems.contactUs.tileOrder = contactUs.sortOrder;
  customerUserConfig.navItems.contactUs.tileTitleColor =
    contactUs.tileTitleColor;
  customerUserConfig.navItems.contactUs.tileDescriptionColor =
    contactUs.tileDescriptionColor;
  customerUserConfig.navItems.contactUs.disabled = contactUs.isComingSoon;

  customerUserConfig.navItems.reports.tileTitle = reports.tileTitle;
  customerUserConfig.navItems.reports.description = reports.tileDescription;
  customerUserConfig.navItems.reports.tileIcon = reports.tileIcon;
  customerUserConfig.navItems.reports.tileOrder = reports.sortOrder;
  customerUserConfig.navItems.reports.tileTitleColor = reports.tileTitleColor;
  customerUserConfig.navItems.reports.tileDescriptionColor =
    reports.tileDescriptionColor;
  customerUserConfig.navItems.reports.disabled = reports.isComingSoon;

  customerTeamAdminCustomerUserConfig.navItems.customCMSTile1.link =
    customerTeamAdminCustomerUserConfig.navItems.customCMSTile1.link +
    "?key=" +
    customPage.dynamicUrlSelector;
  customerTeamAdminCustomerUserConfig.navItems.customCMSTile1.tileTitle =
    customPage.tileTitle;
  customerTeamAdminCustomerUserConfig.navItems.customCMSTile1.description =
    customPage.tileDescription;
  customerTeamAdminCustomerUserConfig.navItems.customCMSTile1.tileIcon =
    customPage.tileIcon;
  customerTeamAdminCustomerUserConfig.navItems.customCMSTile1.tileOrder =
    customPage.sortOrder;
  customerTeamAdminCustomerUserConfig.navItems.customCMSTile1.tileTitleColor =
    customPage.tileTitleColor;
  customerTeamAdminCustomerUserConfig.navItems.customCMSTile1.tileDescriptionColor =
    customPage.tileDescriptionColor;
  customerTeamAdminCustomerUserConfig.navItems.customCMSTile1.disabled =
    customPage.isComingSoon;

  customerTeamAdminConfig.navItems.customCMSTile1.tileTitle =
    customPage.tileTitle;
  customerTeamAdminConfig.navItems.customCMSTile1.description =
    customPage.tileDescription;
  customerTeamAdminConfig.navItems.customCMSTile1.tileIcon =
    customPage.tileIcon;
  customerTeamAdminConfig.navItems.customCMSTile1.tileOrder =
    customPage.sortOrder;
  customerTeamAdminConfig.navItems.customCMSTile1.tileTitleColor =
    customPage.tileTitleColor;
  customerTeamAdminConfig.navItems.customCMSTile1.tileDescriptionColor =
    customPage.tileDescriptionColor;
  customerTeamAdminConfig.navItems.customCMSTile1.disabled =
    customPage.isComingSoon;

  customerUserConfig.navItems.customCMSTile1.tileTitle = customPage.tileTitle;
  customerUserConfig.navItems.customCMSTile1.description =
    customPage.tileDescription;
  customerUserConfig.navItems.customCMSTile1.tileIcon = customPage.tileIcon;
  customerUserConfig.navItems.customCMSTile1.tileOrder = customPage.sortOrder;
  customerUserConfig.navItems.customCMSTile1.tileTitleColor =
    customPage.tileTitleColor;
  customerUserConfig.navItems.customCMSTile1.tileDescriptionColor =
    customPage.tileDescriptionColor;
  customerUserConfig.navItems.customCMSTile1.disabled = customPage.isComingSoon;

  if (customPage.isAdminHidden == true && isAdmin) {
    customerTeamAdminCustomerUserConfig.navItems.customCMSTile1.display =
      "none";
    customerTeamAdminConfig.navItems.customCMSTile1.display = "none";
  }
  if (customPage.isUserHidden == true) {
    customerUserConfig.navItems.customCMSTile1.display = "none";
  }
  if (dynamiPage1 != undefined) {
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.display =
      "block";
    customerTeamAdminConfig.navItems.dynamicCMSTile1.display = "block";
    customerUserConfig.navItems.dynamicCMSTile1.display = "block";

    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.tileTitle =
      dynamiPage1.tileTitle;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.description =
      dynamiPage1.tileDescription;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.tileIcon =
      dynamiPage1.tileIcon;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.tileOrder =
      dynamiPage1.sortOrder;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.link =
      dynamiPage1.tileLink;
    if (dynamiPage1.dynamicUrlSelector) {
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.link =
        "/CMSPage" + "?key=" + dynamiPage1.dynamicUrlSelector;
    }
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.tileTitleColor =
      dynamiPage1.tileTitleColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.tileDescriptionColor =
      dynamiPage1.tileDescriptionColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.disabled =
      dynamiPage1.isComingSoon;

    customerTeamAdminConfig.navItems.dynamicCMSTile1.tileTitle =
      dynamiPage1.tileTitle;
    customerTeamAdminConfig.navItems.dynamicCMSTile1.description =
      dynamiPage1.tileDescription;
    customerTeamAdminConfig.navItems.dynamicCMSTile1.tileIcon =
      dynamiPage1.tileIcon;
    customerTeamAdminConfig.navItems.dynamicCMSTile1.tileOrder =
      dynamiPage1.sortOrder;
    customerTeamAdminConfig.navItems.dynamicCMSTile1.link =
      dynamiPage1.tileLink;
    if (dynamiPage1.dynamicUrlSelector) {
      customerTeamAdminConfig.navItems.dynamicCMSTile1.link =
        "/CMSPage" + "?key=" + dynamiPage1.dynamicUrlSelector;
    }
    customerTeamAdminConfig.navItems.dynamicCMSTile1.tileTitleColor =
      dynamiPage1.tileTitleColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile1.tileDescriptionColor =
      dynamiPage1.tileDescriptionColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile1.disabled =
      dynamiPage1.isComingSoon;

    customerUserConfig.navItems.dynamicCMSTile1.tileTitle =
      dynamiPage1.tileTitle;
    customerUserConfig.navItems.dynamicCMSTile1.description =
      dynamiPage1.tileDescription;
    customerUserConfig.navItems.dynamicCMSTile1.tileIcon = dynamiPage1.tileIcon;
    customerUserConfig.navItems.dynamicCMSTile1.tileOrder =
      dynamiPage1.sortOrder;
    customerUserConfig.navItems.dynamicCMSTile1.link = dynamiPage1.tileLink;
    if (dynamiPage1.dynamicUrlSelector) {
      customerUserConfig.navItems.dynamicCMSTile1.link =
        "/CMSPage" + "?key=" + dynamiPage1.dynamicUrlSelector;
    }
    customerUserConfig.navItems.dynamicCMSTile1.tileTitleColor =
      dynamiPage1.tileTitleColor;
    customerUserConfig.navItems.dynamicCMSTile1.tileDescriptionColor =
      dynamiPage1.tileDescriptionColor;
    customerUserConfig.navItems.dynamicCMSTile1.disabled =
      dynamiPage1.isComingSoon;

    if (dynamiPage1.isAdminHidden == true && isAdmin) {
      customerTeamAdminConfig.navItems.dynamicCMSTile1.display = "none";
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile1.display =
        "none";
    }
    if (dynamiPage1.isUserHidden == true) {
      customerUserConfig.navItems.dynamicCMSTile1.display = "none";
    }
  }

  if (dynamiPage2 != undefined) {
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.display =
      "block";
    customerTeamAdminConfig.navItems.dynamicCMSTile2.display = "block";
    customerUserConfig.navItems.dynamicCMSTile2.display = "block";

    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.tileTitle =
      dynamiPage2.tileTitle;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.description =
      dynamiPage2.tileDescription;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.tileIcon =
      dynamiPage2.tileIcon;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.tileOrder =
      dynamiPage2.sortOrder;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.link =
      dynamiPage2.tileLink;
    if (dynamiPage2.dynamicUrlSelector) {
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.link =
        "/CMSPage" + "?key=" + dynamiPage2.dynamicUrlSelector;
    }
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.tileTitleColor =
      dynamiPage2.tileTitleColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.tileDescriptionColor =
      dynamiPage2.tileDescriptionColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.disabled =
      dynamiPage2.isComingSoon;

    customerTeamAdminConfig.navItems.dynamicCMSTile2.tileTitle =
      dynamiPage2.tileTitle;
    customerTeamAdminConfig.navItems.dynamicCMSTile2.description =
      dynamiPage2.tileDescription;
    customerTeamAdminConfig.navItems.dynamicCMSTile2.tileIcon =
      dynamiPage2.tileIcon;
    customerTeamAdminConfig.navItems.dynamicCMSTile2.tileOrder =
      dynamiPage2.sortOrder;
    customerTeamAdminConfig.navItems.dynamicCMSTile2.link =
      dynamiPage2.tileLink;
    if (dynamiPage2.dynamicUrlSelector) {
      customerTeamAdminConfig.navItems.dynamicCMSTile2.link =
        "/CMSPage" + "?key=" + dynamiPage2.dynamicUrlSelector;
    }
    customerTeamAdminConfig.navItems.dynamicCMSTile2.tileTitleColor =
      dynamiPage2.tileTitleColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile2.tileDescriptionColor =
      dynamiPage2.tileDescriptionColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile2.disabled =
      dynamiPage2.isComingSoon;

    customerUserConfig.navItems.dynamicCMSTile2.tileTitle =
      dynamiPage2.tileTitle;
    customerUserConfig.navItems.dynamicCMSTile2.description =
      dynamiPage2.tileDescription;
    customerUserConfig.navItems.dynamicCMSTile2.tileIcon = dynamiPage2.tileIcon;
    customerUserConfig.navItems.dynamicCMSTile2.tileOrder =
      dynamiPage2.sortOrder;
    customerUserConfig.navItems.dynamicCMSTile2.link = dynamiPage2.tileLink;
    if (dynamiPage2.dynamicUrlSelector) {
      customerUserConfig.navItems.dynamicCMSTile2.link =
        "/CMSPage" + "?key=" + dynamiPage2.dynamicUrlSelector;
    }
    customerUserConfig.navItems.dynamicCMSTile2.tileTitleColor =
      dynamiPage2.tileTitleColor;
    customerUserConfig.navItems.dynamicCMSTile2.tileDescriptionColor =
      dynamiPage2.tileDescriptionColor;
    customerUserConfig.navItems.dynamicCMSTile2.disabled =
      dynamiPage2.isComingSoon;

    if (dynamiPage2.isAdminHidden == true && isAdmin) {
      customerTeamAdminConfig.navItems.dynamicCMSTile2.display = "none";
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile2.display =
        "none";
    }
    if (dynamiPage2.isUserHidden == true) {
      customerUserConfig.navItems.dynamicCMSTile2.display = "none";
    }
  }

  if (dynamiPage3 != undefined) {
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.display =
      "block";
    customerTeamAdminConfig.navItems.dynamicCMSTile3.display = "block";
    customerUserConfig.navItems.dynamicCMSTile3.display = "block";

    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.tileTitle =
      dynamiPage3.tileTitle;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.description =
      dynamiPage3.tileDescription;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.tileIcon =
      dynamiPage3.tileIcon;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.tileOrder =
      dynamiPage3.sortOrder;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.link =
      dynamiPage3.tileLink;
    if (dynamiPage3.dynamicUrlSelector) {
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.link =
        "/CMSPage" + "?key=" + dynamiPage3.dynamicUrlSelector;
    }
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.tileTitleColor =
      dynamiPage3.tileTitleColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.tileDescriptionColor =
      dynamiPage3.tileDescriptionColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.disabled =
      dynamiPage3.isComingSoon;

    customerTeamAdminConfig.navItems.dynamicCMSTile3.tileTitle =
      dynamiPage3.tileTitle;
    customerTeamAdminConfig.navItems.dynamicCMSTile3.description =
      dynamiPage3.tileDescription;
    customerTeamAdminConfig.navItems.dynamicCMSTile3.tileIcon =
      dynamiPage3.tileIcon;
    customerTeamAdminConfig.navItems.dynamicCMSTile3.tileOrder =
      dynamiPage3.sortOrder;
    customerTeamAdminConfig.navItems.dynamicCMSTile3.link =
      dynamiPage3.tileLink;
    if (dynamiPage3.dynamicUrlSelector) {
      customerTeamAdminConfig.navItems.dynamicCMSTile3.link =
        "/CMSPage" + "?key=" + dynamiPage3.dynamicUrlSelector;
    }
    customerTeamAdminConfig.navItems.dynamicCMSTile3.tileTitleColor =
      dynamiPage3.tileTitleColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile3.tileDescriptionColor =
      dynamiPage3.tileDescriptionColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile3.disabled =
      dynamiPage3.isComingSoon;

    customerUserConfig.navItems.dynamicCMSTile3.tileTitle =
      dynamiPage3.tileTitle;
    customerUserConfig.navItems.dynamicCMSTile3.description =
      dynamiPage3.tileDescription;
    customerUserConfig.navItems.dynamicCMSTile3.tileIcon = dynamiPage3.tileIcon;
    customerUserConfig.navItems.dynamicCMSTile3.tileOrder =
      dynamiPage3.sortOrder;
    customerUserConfig.navItems.dynamicCMSTile3.link = dynamiPage3.tileLink;
    if (dynamiPage3.dynamicUrlSelector) {
      customerUserConfig.navItems.dynamicCMSTile3.link =
        "/CMSPage" + "?key=" + dynamiPage3.dynamicUrlSelector;
    }
    customerUserConfig.navItems.dynamicCMSTile3.tileTitleColor =
      dynamiPage3.tileTitleColor;
    customerUserConfig.navItems.dynamicCMSTile3.tileDescriptionColor =
      dynamiPage3.tileLtileDescriptionColorink;
    customerUserConfig.navItems.dynamicCMSTile3.disabled =
      dynamiPage3.isComingSoon;

    if (dynamiPage3.isAdminHidden == true && isAdmin) {
      customerTeamAdminConfig.navItems.dynamicCMSTile3.display = "none";
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile3.display =
        "none";
    }
    if (dynamiPage3.isUserHidden == true) {
      customerUserConfig.navItems.dynamicCMSTile3.display = "none";
    }
  }

  if (dynamiPage4 != undefined) {
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.display =
      "block";
    customerTeamAdminConfig.navItems.dynamicCMSTile4.display = "block";
    customerUserConfig.navItems.dynamicCMSTile4.display = "block";

    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.tileTitle =
      dynamiPage4.tileTitle;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.description =
      dynamiPage4.tileDescription;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.tileIcon =
      dynamiPage4.tileIcon;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.tileOrder =
      dynamiPage4.sortOrder;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.link =
      dynamiPage4.tileLink;
    if (dynamiPage4.dynamicUrlSelector) {
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.link =
        "/CMSPage" + "?key=" + dynamiPage4.dynamicUrlSelector;
    }
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.tileTitleColor =
      dynamiPage4.tileTitleColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.tileDescriptionColor =
      dynamiPage4.tileDescriptionColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.disabled =
      dynamiPage4.isComingSoon;

    customerTeamAdminConfig.navItems.dynamicCMSTile4.tileTitle =
      dynamiPage4.tileTitle;
    customerTeamAdminConfig.navItems.dynamicCMSTile4.description =
      dynamiPage4.tileDescription;
    customerTeamAdminConfig.navItems.dynamicCMSTile4.tileIcon =
      dynamiPage4.tileIcon;
    customerTeamAdminConfig.navItems.dynamicCMSTile4.tileOrder =
      dynamiPage4.sortOrder;
    customerTeamAdminConfig.navItems.dynamicCMSTile4.link =
      dynamiPage4.tileLink;
    if (dynamiPage4.dynamicUrlSelector) {
      customerTeamAdminConfig.navItems.dynamicCMSTile4.link =
        "/CMSPage" + "?key=" + dynamiPage4.dynamicUrlSelector;
    }
    customerTeamAdminConfig.navItems.dynamicCMSTile4.tileTitleColor =
      dynamiPage4.tileTitleColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile4.tileDescriptionColor =
      dynamiPage4.tileDescriptionColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile4.disabled =
      dynamiPage4.isComingSoon;

    customerUserConfig.navItems.dynamicCMSTile4.tileTitle =
      dynamiPage4.tileTitle;
    customerUserConfig.navItems.dynamicCMSTile4.description =
      dynamiPage4.tileDescription;
    customerUserConfig.navItems.dynamicCMSTile4.tileIcon = dynamiPage4.tileIcon;
    customerUserConfig.navItems.dynamicCMSTile4.tileOrder =
      dynamiPage4.sortOrder;
    customerUserConfig.navItems.dynamicCMSTile4.link = dynamiPage4.tileLink;
    if (dynamiPage4.dynamicUrlSelector) {
      customerUserConfig.navItems.dynamicCMSTile4.link =
        "/CMSPage" + "?key=" + dynamiPage4.dynamicUrlSelector;
    }
    customerUserConfig.navItems.dynamicCMSTile4.tileTitleColor =
      dynamiPage4.tileTitleColor;
    customerUserConfig.navItems.dynamicCMSTile4.tileDescriptionColor =
      dynamiPage4.tileDescriptionColor;
    customerUserConfig.navItems.dynamicCMSTile4.disabled =
      dynamiPage4.isComingSoon;

    if (dynamiPage4.isAdminHidden == true && isAdmin) {
      customerTeamAdminConfig.navItems.dynamicCMSTile4.display = "none";
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile4.display =
        "none";
    }
    if (dynamiPage4.isUserHidden == true) {
      customerUserConfig.navItems.dynamicCMSTile4.display = "none";
    }
  }

  if (dynamiPage5 != undefined) {
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.display =
      "block";
    customerTeamAdminConfig.navItems.dynamicCMSTile5.display = "block";
    customerUserConfig.navItems.dynamicCMSTile5.display = "block";

    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.tileTitle =
      dynamiPage5.tileTitle;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.description =
      dynamiPage5.tileDescription;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.tileIcon =
      dynamiPage5.tileIcon;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.tileOrder =
      dynamiPage5.sortOrder;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.link =
      dynamiPage5.tileLink;
    if (dynamiPage5.dynamicUrlSelector) {
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.link =
        "/CMSPage" + "?key=" + dynamiPage5.dynamicUrlSelector;
    }
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.tileTitleColor =
      dynamiPage5.tileTitleColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.tileDescriptionColor =
      dynamiPage5.tileDescriptionColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.disabled =
      dynamiPage5.isComingSoon;

    customerTeamAdminConfig.navItems.dynamicCMSTile5.tileTitle =
      dynamiPage5.tileTitle;
    customerTeamAdminConfig.navItems.dynamicCMSTile5.description =
      dynamiPage5.tileDescription;
    customerTeamAdminConfig.navItems.dynamicCMSTile5.tileIcon =
      dynamiPage5.tileIcon;
    customerTeamAdminConfig.navItems.dynamicCMSTile5.tileOrder =
      dynamiPage5.sortOrder;
    customerTeamAdminConfig.navItems.dynamicCMSTile5.link =
      dynamiPage5.tileLink;
    if (dynamiPage5.dynamicUrlSelector) {
      customerTeamAdminConfig.navItems.dynamicCMSTile5.link =
        "/CMSPage" + "?key=" + dynamiPage5.dynamicUrlSelector;
    }
    customerTeamAdminConfig.navItems.dynamicCMSTile5.tileTitleColor =
      dynamiPage5.tileTitleColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile5.tileDescriptionColor =
      dynamiPage5.tileDescriptionColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile5.disabled =
      dynamiPage5.isComingSoon;

    customerUserConfig.navItems.dynamicCMSTile5.tileTitle =
      dynamiPage5.tileTitle;
    customerUserConfig.navItems.dynamicCMSTile5.description =
      dynamiPage5.tileDescription;
    customerUserConfig.navItems.dynamicCMSTile5.tileIcon = dynamiPage5.tileIcon;
    customerUserConfig.navItems.dynamicCMSTile5.tileOrder =
      dynamiPage5.sortOrder;
    customerUserConfig.navItems.dynamicCMSTile5.link = dynamiPage5.tileLink;
    if (dynamiPage5.dynamicUrlSelector) {
      customerUserConfig.navItems.dynamicCMSTile5.link =
        "/CMSPage" + "?key=" + dynamiPage5.dynamicUrlSelector;
    }
    customerUserConfig.navItems.dynamicCMSTile5.tileTitleColor =
      dynamiPage5.tileTitleColor;
    customerUserConfig.navItems.dynamicCMSTile5.tileDescriptionColor =
      dynamiPage5.tileDescriptionColor;
    customerUserConfig.navItems.dynamicCMSTile5.disabled =
      dynamiPage5.isComingSoon;

    if (dynamiPage5.isAdminHidden == true && isAdmin) {
      customerTeamAdminConfig.navItems.dynamicCMSTile5.display = "none";
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile5.display =
        "none";
    }
    if (dynamiPage5.isUserHidden == true) {
      customerUserConfig.navItems.dynamicCMSTile5.display = "none";
    }
  }

  if (dynamiPage6 != undefined) {
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.display =
      "block";
    customerTeamAdminConfig.navItems.dynamicCMSTile6.display = "block";
    customerUserConfig.navItems.dynamicCMSTile6.display = "block";

    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.tileTitle =
      dynamiPage6.tileTitle;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.description =
      dynamiPage6.tileDescription;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.tileIcon =
      dynamiPage6.tileIcon;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.tileOrder =
      dynamiPage6.sortOrder;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.link =
      dynamiPage6.tileLink;
    if (dynamiPage6.dynamicUrlSelector) {
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.link =
        "/CMSPage" + "?key=" + dynamiPage6.dynamicUrlSelector;
    }
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.tileTitleColor =
      dynamiPage6.tileTitleColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.tileDescriptionColor =
      dynamiPage6.tileDescriptionColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.disabled =
      dynamiPage6.isComingSoon;

    customerTeamAdminConfig.navItems.dynamicCMSTile6.tileTitle =
      dynamiPage6.tileTitle;
    customerTeamAdminConfig.navItems.dynamicCMSTile6.description =
      dynamiPage6.tileDescription;
    customerTeamAdminConfig.navItems.dynamicCMSTile6.tileIcon =
      dynamiPage6.tileIcon;
    customerTeamAdminConfig.navItems.dynamicCMSTile6.tileOrder =
      dynamiPage6.sortOrder;
    customerTeamAdminConfig.navItems.dynamicCMSTile6.link =
      dynamiPage6.tileLink;
    if (dynamiPage6.dynamicUrlSelector) {
      customerTeamAdminConfig.navItems.dynamicCMSTile6.link =
        "/CMSPage" + "?key=" + dynamiPage6.dynamicUrlSelector;
    }
    customerTeamAdminConfig.navItems.dynamicCMSTile6.tileTitleColor =
      dynamiPage6.tileTitleColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile6.tileDescriptionColor =
      dynamiPage6.tileDescriptionColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile6.disabled =
      dynamiPage6.isComingSoon;

    customerUserConfig.navItems.dynamicCMSTile6.tileTitle =
      dynamiPage6.tileTitle;
    customerUserConfig.navItems.dynamicCMSTile6.description =
      dynamiPage6.tileDescription;
    customerUserConfig.navItems.dynamicCMSTile6.tileIcon = dynamiPage6.tileIcon;
    customerUserConfig.navItems.dynamicCMSTile6.tileOrder =
      dynamiPage6.sortOrder;
    customerUserConfig.navItems.dynamicCMSTile6.link = dynamiPage6.tileLink;
    if (dynamiPage6.dynamicUrlSelector) {
      customerUserConfig.navItems.dynamicCMSTile6.link =
        "/CMSPage" + "?key=" + dynamiPage6.dynamicUrlSelector;
    }
    customerUserConfig.navItems.dynamicCMSTile6.tileTitleColor =
      dynamiPage6.tileTitleColor;
    customerUserConfig.navItems.dynamicCMSTile6.tileDescriptionColor =
      dynamiPage6.tileDescriptionColor;
    customerUserConfig.navItems.dynamicCMSTile6.disabled =
      dynamiPage6.isComingSoon;

    if (dynamiPage6.isAdminHidden == true && isAdmin) {
      customerTeamAdminConfig.navItems.dynamicCMSTile6.display = "none";
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile6.display =
        "none";
    }
    if (dynamiPage6.isUserHidden == true) {
      customerUserConfig.navItems.dynamicCMSTile6.display = "none";
    }
  }

  if (dynamiPage7 != undefined) {
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.display =
      "block";
    customerTeamAdminConfig.navItems.dynamicCMSTile7.display = "block";
    customerUserConfig.navItems.dynamicCMSTile7.display = "block";

    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.tileTitle =
      dynamiPage7.tileTitle;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.description =
      dynamiPage7.tileDescription;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.tileIcon =
      dynamiPage7.tileIcon;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.tileOrder =
      dynamiPage7.sortOrder;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.link =
      dynamiPage7.tileLink;
    if (dynamiPage7.dynamicUrlSelector) {
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.link =
        "/CMSPage" + "?key=" + dynamiPage7.dynamicUrlSelector;
    }
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.tileTitleColor =
      dynamiPage7.tileTitleColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.tileDescriptionColor =
      dynamiPage7.tileDescriptionColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.disabled =
      dynamiPage7.isComingSoon;

    customerTeamAdminConfig.navItems.dynamicCMSTile7.tileTitle =
      dynamiPage7.tileTitle;
    customerTeamAdminConfig.navItems.dynamicCMSTile7.description =
      dynamiPage7.tileDescription;
    customerTeamAdminConfig.navItems.dynamicCMSTile7.tileIcon =
      dynamiPage7.tileIcon;
    customerTeamAdminConfig.navItems.dynamicCMSTile7.tileOrder =
      dynamiPage7.sortOrder;
    customerTeamAdminConfig.navItems.dynamicCMSTile7.link =
      dynamiPage7.tileLink;
    if (dynamiPage7.dynamicUrlSelector) {
      customerTeamAdminConfig.navItems.dynamicCMSTile7.link =
        "/CMSPage" + "?key=" + dynamiPage7.dynamicUrlSelector;
    }
    customerTeamAdminConfig.navItems.dynamicCMSTile7.tileTitleColor =
      dynamiPage7.tileTitleColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile7.tileDescriptionColor =
      dynamiPage7.tileDescriptionColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile7.disabled =
      dynamiPage7.isComingSoon;

    customerUserConfig.navItems.dynamicCMSTile7.tileTitle =
      dynamiPage7.tileTitle;
    customerUserConfig.navItems.dynamicCMSTile7.description =
      dynamiPage7.tileDescription;
    customerUserConfig.navItems.dynamicCMSTile7.tileIcon = dynamiPage7.tileIcon;
    customerUserConfig.navItems.dynamicCMSTile7.tileOrder =
      dynamiPage7.sortOrder;
    customerUserConfig.navItems.dynamicCMSTile7.link = dynamiPage7.tileLink;
    if (dynamiPage7.dynamicUrlSelector) {
      customerUserConfig.navItems.dynamicCMSTile7.link =
        "/CMSPage" + "?key=" + dynamiPage7.dynamicUrlSelector;
    }
    customerUserConfig.navItems.dynamicCMSTile7.tileTitleColor =
      dynamiPage7.tileTitleColor;
    customerUserConfig.navItems.dynamicCMSTile7.tileDescriptionColor =
      dynamiPage7.tileDescriptionColor;
    customerUserConfig.navItems.dynamicCMSTile7.disabled =
      dynamiPage7.isComingSoon;

    if (dynamiPage7.isAdminHidden == true && isAdmin) {
      customerTeamAdminConfig.navItems.dynamicCMSTile7.display = "none";
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile7.display =
        "none";
    }
    if (dynamiPage7.isUserHidden == true) {
      customerUserConfig.navItems.dynamicCMSTile7.display = "none";
    }
  }

  if (dynamiPage8 != undefined) {
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.display =
      "block";
    customerTeamAdminConfig.navItems.dynamicCMSTile8.display = "block";
    customerUserConfig.navItems.dynamicCMSTile8.display = "block";

    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.tileTitle =
      dynamiPage8.tileTitle;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.description =
      dynamiPage8.tileDescription;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.tileIcon =
      dynamiPage8.tileIcon;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.tileOrder =
      dynamiPage8.sortOrder;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.link =
      dynamiPage8.tileLink;
    if (dynamiPage8.dynamicUrlSelector) {
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.link =
        "/CMSPage" + "?key=" + dynamiPage8.dynamicUrlSelector;
    }
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.tileTitleColor =
      dynamiPage8.tileTitleColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.tileDescriptionColor =
      dynamiPage8.tileDescriptionColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.disabled =
      dynamiPage8.isComingSoon;

    customerTeamAdminConfig.navItems.dynamicCMSTile8.tileTitle =
      dynamiPage8.tileTitle;
    customerTeamAdminConfig.navItems.dynamicCMSTile8.description =
      dynamiPage8.tileDescription;
    customerTeamAdminConfig.navItems.dynamicCMSTile8.tileIcon =
      dynamiPage8.tileIcon;
    customerTeamAdminConfig.navItems.dynamicCMSTile8.tileOrder =
      dynamiPage8.sortOrder;
    customerTeamAdminConfig.navItems.dynamicCMSTile8.link =
      dynamiPage8.tileLink;
    if (dynamiPage8.dynamicUrlSelector) {
      customerTeamAdminConfig.navItems.dynamicCMSTile8.link =
        "/CMSPage" + "?key=" + dynamiPage8.dynamicUrlSelector;
    }
    customerTeamAdminConfig.navItems.dynamicCMSTile8.tileTitleColor =
      dynamiPage8.tileTitleColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile8.tileDescriptionColor =
      dynamiPage8.tileDescriptionColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile8.disabled =
      dynamiPage8.isComingSoon;

    customerUserConfig.navItems.dynamicCMSTile8.tileTitle =
      dynamiPage8.tileTitle;
    customerUserConfig.navItems.dynamicCMSTile8.description =
      dynamiPage8.tileDescription;
    customerUserConfig.navItems.dynamicCMSTile8.tileIcon = dynamiPage8.tileIcon;
    customerUserConfig.navItems.dynamicCMSTile8.tileOrder =
      dynamiPage8.sortOrder;
    customerUserConfig.navItems.dynamicCMSTile8.link = dynamiPage8.tileLink;
    if (dynamiPage8.dynamicUrlSelector) {
      customerUserConfig.navItems.dynamicCMSTile8.link =
        "/CMSPage" + "?key=" + dynamiPage8.dynamicUrlSelector;
    }
    customerUserConfig.navItems.dynamicCMSTile8.tileTitleColor =
      dynamiPage8.tileTitleColor;
    customerUserConfig.navItems.dynamicCMSTile8.tileDescriptionColor =
      dynamiPage8.tileDescriptionColor;
    customerUserConfig.navItems.dynamicCMSTile8.disabled =
      dynamiPage8.isComingSoon;

    if (dynamiPage8.isAdminHidden == true && isAdmin) {
      customerTeamAdminConfig.navItems.dynamicCMSTile8.display = "none";
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile8.display =
        "none";
    }
    if (dynamiPage8.isUserHidden == true) {
      customerUserConfig.navItems.dynamicCMSTile8.display = "none";
    }
  }

  if (dynamiPage9 != undefined) {
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.display =
      "block";
    customerTeamAdminConfig.navItems.dynamicCMSTile9.display = "block";
    customerUserConfig.navItems.dynamicCMSTile9.display = "block";

    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.tileTitle =
      dynamiPage9.tileTitle;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.description =
      dynamiPage9.tileDescription;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.tileIcon =
      dynamiPage9.tileIcon;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.tileOrder =
      dynamiPage9.sortOrder;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.link =
      dynamiPage9.tileLink;
    if (dynamiPage9.dynamicUrlSelector) {
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.link =
        "/CMSPage" + "?key=" + dynamiPage9.dynamicUrlSelector;
    }
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.tileTitleColor =
      dynamiPage9.tileTitleColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.tileDescriptionColor =
      dynamiPage9.tileDescriptionColor;
    customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.disabled =
      dynamiPage9.isComingSoon;

    customerTeamAdminConfig.navItems.dynamicCMSTile9.tileTitle =
      dynamiPage9.tileTitle;
    customerTeamAdminConfig.navItems.dynamicCMSTile9.description =
      dynamiPage9.tileDescription;
    customerTeamAdminConfig.navItems.dynamicCMSTile9.tileIcon =
      dynamiPage9.tileIcon;
    customerTeamAdminConfig.navItems.dynamicCMSTile9.tileOrder =
      dynamiPage9.sortOrder;
    customerTeamAdminConfig.navItems.dynamicCMSTile9.link =
      dynamiPage9.tileLink;
    if (dynamiPage9.dynamicUrlSelector) {
      customerTeamAdminConfig.navItems.dynamicCMSTile9.link =
        "/CMSPage" + "?key=" + dynamiPage9.dynamicUrlSelector;
    }
    customerTeamAdminConfig.navItems.dynamicCMSTile9.tileTitleColor =
      dynamiPage9.tileTitleColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile9.tileDescriptionColor =
      dynamiPage9.tileDescriptionColor;
    customerTeamAdminConfig.navItems.dynamicCMSTile9.disabled =
      dynamiPage9.isComingSoon;

    customerUserConfig.navItems.dynamicCMSTile9.tileTitle =
      dynamiPage9.tileTitle;
    customerUserConfig.navItems.dynamicCMSTile9.description =
      dynamiPage9.tileDescription;
    customerUserConfig.navItems.dynamicCMSTile9.tileIcon = dynamiPage9.tileIcon;
    customerUserConfig.navItems.dynamicCMSTile9.tileOrder =
      dynamiPage9.sortOrder;
    customerUserConfig.navItems.dynamicCMSTile9.link = dynamiPage9.tileLink;
    if (dynamiPage9.dynamicUrlSelector) {
      customerUserConfig.navItems.dynamicCMSTile9.link =
        "/CMSPage" + "?key=" + dynamiPage9.dynamicUrlSelector;
    }
    customerUserConfig.navItems.dynamicCMSTile9.tileTitleColor =
      dynamiPage9.tileTitleColor;
    customerUserConfig.navItems.dynamicCMSTile9.tileDescriptionColor =
      dynamiPage9.tileDescriptionColor;
    customerUserConfig.navItems.dynamicCMSTile9.disabled =
      dynamiPage9.isComingSoon;

    if (dynamiPage9.isAdminHidden == true && isAdmin) {
      customerTeamAdminConfig.navItems.dynamicCMSTile9.display = "none";
      customerTeamAdminCustomerUserConfig.navItems.dynamicCMSTile9.display =
        "none";
    }
    if (dynamiPage9.isUserHidden == true) {
      customerUserConfig.navItems.dynamicCMSTile9.display = "none";
    }
  }

  var isAdmin = false;
  const role = JSON.parse(claims.extension_CustomerMapping).CustomerMappings[0]
    .Role[0];
  if (role == "CustomerTeamAdmin") {
    isAdmin = true;
  }

  if (waterjetConnectIQ.isAdminHidden == true && isAdmin) {
    customerTeamAdminCustomerUserConfig.navItems.iotDashboard.display = "none";
    customerTeamAdminConfig.navItems.iotDashboard.display = "none";
    customerTeamAdminCustomerUserConfig.drawerItems.iotDashboard.display =
      "none";
    customerTeamAdminConfig.drawerItems.iotDashboard.display = "none";
  }
  if (waterjetConnectIQ.isUserHidden == true) {
    customerUserConfig.navItems.iotDashboard.display = "none";
    customerUserConfig.drawerItems.iotDashboard.display = "none";
  }

  if (purchaseParts.isAdminHidden == true && isAdmin) {
    customerTeamAdminCustomerUserConfig.navItems.purchase.display = "none";
    customerTeamAdminConfig.navItems.purchase.display = "none";
  }
  if (purchaseParts.isUserHidden == true) {
    customerUserConfig.navItems.purchase.display = "none";
  }

  if (partsOrder.isAdminHidden == true && isAdmin) {
    customerTeamAdminCustomerUserConfig.navItems.orderHistory.display = "none";
    customerTeamAdminConfig.navItems.orderHistory.display = "none";
  }

  if (scheduleTraining.isAdminHidden == true && isAdmin) {
    customerTeamAdminCustomerUserConfig.navItems.scheduleTraining.display =
      "none";
    customerTeamAdminConfig.navItems.scheduleTraining.display = "none";
  }
  if (scheduleTraining.isUserHidden == true) {
    customerUserConfig.navItems.scheduleTraining.display = "none";
  }

  if (serviceActivity.isAdminHidden == true && isAdmin) {
    customerTeamAdminCustomerUserConfig.navItems.serviceHistory.display =
      "none";
    customerTeamAdminConfig.navItems.serviceHistory.display = "none";
  }
  if (serviceActivity.isUserHidden == true) {
    customerUserConfig.navItems.serviceHistory.display = "none";
  }

  if (docsAndVideo.isAdminHidden == true && isAdmin) {
    customerTeamAdminConfig.navItems.docVideo.display = "none";
    customerTeamAdminCustomerUserConfig.navItems.docVideo.display = "none";
  }
  if (docsAndVideo.isUserHidden == true) {
    customerUserConfig.navItems.docVideo.display = "none";
  }

  if (upcomingEvents.isAdminHidden == true && isAdmin) {
    customerTeamAdminConfig.navItems.comingAttractions.display = "none";
    customerTeamAdminCustomerUserConfig.navItems.comingAttractions.display =
      "none";
  }
  if (upcomingEvents.isUserHidden == true) {
    customerUserConfig.navItems.comingAttractions.display = "none";
  }

  if (manageUsers.isAdminHidden == true && isAdmin) {
    customerTeamAdminConfig.navItems.manageUsers.display = "none";
    customerTeamAdminCustomerUserConfig.navItems.manageUsers.display = "none";
  }

  if (contactUs.isAdminHidden == true && isAdmin) {
    customerTeamAdminCustomerUserConfig.navItems.contactUs.display = "none";
    customerTeamAdminConfig.navItems.contactUs.display = "none";
  }
  if (contactUs.isUserHidden == true) {
    customerUserConfig.navItems.contactUs.display = "none";
  }

  if (reports.isAdminHidden == true && isAdmin) {
    customerTeamAdminCustomerUserConfig.navItems.reports.display = "none";
    customerTeamAdminConfig.navItems.reports.display = "none";
  }
  if (reports.isUserHidden == true) {
    customerUserConfig.navItems.reports.display = "none";
  }

  return {
    globalAdmin: ROLE_CONFIG.GlobalAdmin,
    customerTeamAdmin: customerTeamAdminConfig,
    customerTeamAdminCustomerUser: customerTeamAdminCustomerUserConfig,
    customerUser: customerUserConfig,
  };
};

/**
 * Keeps roles config state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const claims = createReducer(initialState, {
  SET_CLAIMS: (state, { value }) => getRoleConfig(value.payload),
});

export default claims;
