import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSimulationMode } from "store/actions";
import DialogBox from "../Modal/Dialogbox";

const SimulationExitConfirmationPopup = ({ open, onClose }) => {
  const inSimulationMode = useSelector((state) => state.inSimulationMode);
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(setSimulationMode(false));
    onClose();
    localStorage.clear("inSimulationMode");
    localStorage.clear("simulatedUser");
  };

  return (
    inSimulationMode && (
      <>
        <DialogBox
          checkOpen={open}
          handleToClose={onClose}
          onSubmit={onSubmit}
          enteringSimulation={false}
          displayMessage="Exit simulation mode?"
        />
      </>
    )
  );
};

export default SimulationExitConfirmationPopup;
