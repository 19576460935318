import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HighLightAlt = () => (
  <SvgIcon width="20px" height="20px" viewBox="0 0 20 20">
    <path fill="currentColor" d="M12.4444 1.77778H10.6667V0H12.4444V1.77778ZM10.6667 10.6667V16L12.7022 13.9644L14.7467 16L16 14.7467L13.9644 12.7111L16 10.6667H10.6667ZM14.2222 5.33333H16V3.55556H14.2222V5.33333ZM14.2222 8.88889H16V7.11111H14.2222V8.88889ZM7.11111 16H8.88889V14.2222H7.11111V16ZM3.55556 1.77778H5.33333V0H3.55556V1.77778ZM0 12.4444H1.77778V10.6667H0V12.4444ZM1.77778 16V14.2222H0C0 15.2 0.8 16 1.77778 16ZM14.2222 0V1.77778H16C16 0.8 15.2 0 14.2222 0ZM7.11111 1.77778H8.88889V0H7.11111V1.77778ZM0 5.33333H1.77778V3.55556H0V5.33333ZM3.55556 16H5.33333V14.2222H3.55556V16ZM0 8.88889H1.77778V7.11111H0V8.88889ZM0 1.77778H1.77778V0C0.8 0 0 0.8 0 1.77778Z" />
  </SvgIcon>
);

export default HighLightAlt;
