import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getUsers,
  setSimulationMode,
  toggleSimulationModal,
  toggleSimulationExitConfirmationModal,
  getBannersText,
  toggleRequestServiceModal,
  togglePumpHoursModal,
  getLogoAndColor,
  toggleLicenseAgreementModal,
  toggleScheduleTrainingModal,
} from "store/actions";
import {
  getCurrentRoleConfig,
  isUserRoleGlobalAdmin,
  isUserCustomerUser,
  isCustomerAdmin,
} from "store/reducers/index";
import LandingPageTile from "components/LandingPageTile/LandingPageTile";
import SwiperLanding from "components/SwiperLanding/SwiperLanding";
import Payments from "components/generic/Icon/Payments";
import {
  appInsights,
  tilesDetails,
  cMSResources,
  hasAcceptEULA,
} from "../../index";

import "./LandingPage.scss";

function findArrayElementByTitle(array, segment) {
  return array.find((element) => {
    return element.segment === segment;
  });
}

function findArrayElementByKey(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const getUserRole = (role) => {
  var cmsGlobalAdmin = findArrayElementByKey(cMSResources, "{{GlobalAdmin}}");
  var cmsCustomerUser = findArrayElementByKey(cMSResources, "{{CustomerUser}}");
  var cmsCustomerTeamAdmin = findArrayElementByKey(
    cMSResources,
    "{{CustomerTeamAdmin}}"
  );
  var cmsCustomerUserAndAdmin = findArrayElementByKey(
    cMSResources,
    "{{CustomerUserAndAdmin}}"
  );
  const userRoles = {
    GlobalAdmin: { value: "GlobalAdmin", name: cmsGlobalAdmin.value },
    CustomerUser: { value: "CustomerUser", name: cmsCustomerUser.value },
    CustomerTeamAdmin: {
      value: "CustomerTeamAdmin",
      name: cmsCustomerTeamAdmin.value,
    },
    CustomerTeamAdminCustomerUser: {
      value: "CustomerTeamAdmin",
      name: cmsCustomerUserAndAdmin.value,
    },
  };

  if (role.length === 2) {
    return userRoles.CustomerTeamAdminCustomerUser.name;
  }

  return userRoles[role[0]].name;
};

const LandingPage = () => {
  const roleConfig = useSelector(getCurrentRoleConfig);
  const isGlobalAdminRole = useSelector(isUserRoleGlobalAdmin);
  const hasUserRole = useSelector(isUserCustomerUser);
  const isCustomerAdminRole = useSelector(isCustomerAdmin);
  const inSimulationMode = useSelector((state) => state.inSimulationMode);
  const simulatedUserClaims = useSelector((state) => state.simulatedUserClaims);
  const globalAdminName = useSelector((state) => state.claims.given_name);
  const userName = useSelector((state) => state.claims.name);
  const bannersText = useSelector((state) => state.bannerTextConfig);
  const logoAndColor = useSelector((state) => state.logoAndColor);
  const defaultLanguage = sessionStorage.getItem("defaultLanguage");

  var pumpHours = findArrayElementByTitle(tilesDetails, "pump-hours");
  var waterjetConnectIq = findArrayElementByTitle(
    tilesDetails,
    "waterjet-connect-iq"
  );
  var requestService = findArrayElementByTitle(tilesDetails, "request-service");
  var scheduleTraining = findArrayElementByTitle(
    tilesDetails,
    "schedule-training"
  );
  var accountInfo = findArrayElementByTitle(tilesDetails, "account-info");

  var cmsWelcome = findArrayElementByKey(cMSResources, "{{Welcome}}");

  const { role, customerName } = useSelector(
    (state) => state.claims.userDetails
  );
  const dispatch = useDispatch();
  appInsights.startTrackPage(roleConfig.appInsightsId);
  appInsights.stopTrackPage(roleConfig.appInsightsId, null, { userName });

  if (isGlobalAdminRole && !inSimulationMode) {
    // to be removed after simulation is reworked
    dispatch(getUsers("All", "Manage Users"));
  }

  useEffect(() => {
    dispatch(getBannersText(defaultLanguage));
    dispatch(getLogoAndColor(defaultLanguage));
  }, []);

  const openServiceModal = () => {
    dispatch(toggleRequestServiceModal());
  };

  const openPumpHoursModal = () => {
    dispatch(togglePumpHoursModal());
  };

  const openScheduleTraining = () => {
    if (!scheduleTraining?.tileLink.length > 0) {
      dispatch(toggleScheduleTrainingModal());
    } else {
      window.open(scheduleTraining.tileLink, "_blank");
    }
  };

  const openLicenseAgreementModal = () => {
    if (hasAcceptEULA) {
      window.open(waterjetConnectIq.tileLink, "_blank");
    } else {
      dispatch(toggleLicenseAgreementModal());
    }
  };

  const background = logoAndColor.landingPageBackgroundImage;
  return (
    <div className="landing-page-wrapper">
      {/* <div
        className="landing-page--user-info-details"
        style={{
          backgroundImage: `url(${logoAndColor.landingPageBackgroundImage})`,
        }}
      >
        <div className="landing-page--user-info-overlay" />
        <div className="welcome-user-text">
          <div className="welcome-customer-name">
            {inSimulationMode ? simulatedUserClaims.customerName : customerName}
          </div>
          <div>
            <span className="welcome-label">{cmsWelcome.value}, </span>
            <span className="text-bold welcome-user-name">
              {inSimulationMode
                ? simulatedUserClaims.userName
                : isGlobalAdminRole
                ? globalAdminName
                : userName}
            </span>
            |
            <span className="user-role">
              {inSimulationMode
                ? getUserRole(simulatedUserClaims.role)
                : getUserRole(role)}
            </span>
          </div>
        </div>
      </div> */}
      <SwiperLanding
        imagesId={roleConfig.banner.imagesId}
        slidesText={bannersText[roleConfig.banner.textId]}
      />
      <div className="landing-page--tiles-container">
        {logoAndColor.quickAccessName && (
          <div className="landing-page--tiles-container-heading shape_flex shape_flex--align-center">
            <Payments />
            <span className="landing-page--tiles-container-heading-text">
              {logoAndColor.quickAccessName}
            </span>
          </div>
        )}
        <div className="landing-page--tiles">
          {(isCustomerAdminRole || hasUserRole || inSimulationMode) && (
            <>
              <LandingPageTile
                title={requestService.tileTitle}
                description={requestService.tileDescription}
                tileLinkIcon="navigate_next"
                id="scheduleService"
                onClick={openServiceModal}
                key="scheduleService"
                order={requestService.sortOrder}
                tileTitleColor={requestService.tileTitleColor}
                tileDescriptionColor={requestService.tileDescriptionColor}
              />
              {!pumpHours.isCompanyHidden && (
                <LandingPageTile
                  title={pumpHours.tileTitle}
                  description={pumpHours.tileDescription}
                  tileLinkIcon="navigate_next"
                  id="pumpHours"
                  onClick={openPumpHoursModal}
                  key="pumpHours"
                  order={pumpHours.sortOrder}
                  tileTitleColor={pumpHours.tileTitleColor}
                  tileDescriptionColor={pumpHours.tileDescriptionColor}
                />
              )}
              {!scheduleTraining.isCompanyHidden && (
                <LandingPageTile
                  title={scheduleTraining.tileTitle}
                  description={scheduleTraining.tileDescription}
                  tileLinkIcon="navigate_next"
                  id="scheduleTraining"
                  onClick={openScheduleTraining}
                  key="scheduleTraining"
                  order={scheduleTraining.sortOrder}
                  tileTitleColor={scheduleTraining.tileTitleColor}
                  tileDescriptionColor={scheduleTraining.tileDescriptionColor}
                />
              )}
            </>
          )}
          {!waterjetConnectIq.isCompanyHidden && (
            <LandingPageTile
              title={waterjetConnectIq.tileTitle}
              description={waterjetConnectIq.tileDescription}
              tileLinkIcon="navigate_next"
              id="iotDashboard"
              onClick={openLicenseAgreementModal}
              key="iotDashboard"
              order={waterjetConnectIq.sortOrder}
              tileTitleColor={waterjetConnectIq.tileTitleColor}
              tileDescriptionColor={waterjetConnectIq.tileDescriptionColor}
              disabled={waterjetConnectIq.isComingSoon}
            />
          )}
          {Object.keys(roleConfig.navItems).map((navItem) => {
            const {
              tileTitle,
              description,
              tileLinkIcon,
              id,
              link,
              disabled,
              tileOrder,
              padding = 12,
              display = "block",
              tileIcon = undefined,
              tileTitleColor = "#5B6770",
              tileDescriptionColor = "#5B6770",
            } = roleConfig.navItems[navItem];

            return (
              <LandingPageTile
                title={tileTitle.split("\n").map((line, idx) => (
                  <p key={idx}>{line}</p>
                ))}
                description={description}
                tileLinkIcon={tileLinkIcon}
                id={id}
                link={link}
                key={navItem}
                disabled={disabled}
                order={tileOrder}
                padding={padding}
                display={display}
                tileIcon={tileIcon}
                tileTitleColor={tileTitleColor}
                tileDescriptionColor={tileDescriptionColor}
              />
            );
          })}
          {isGlobalAdminRole && (
            <LandingPageTile
              title={inSimulationMode ? "Exit simulation" : "Simulate"}
              description="As a Global Administrator, you can simulate as other users or customers"
              tileLinkIcon="navigate_next"
              padding={10}
              order={12}
              id="simulate"
              link="/"
              key="simulate"
              onClick={() => {
                if (inSimulationMode) {
                  if (window.matchMedia("(max-width: 639px)").matches) {
                    localStorage.clear("inSimulationMode");
                    localStorage.clear("simulatedUser");
                    return;
                  }
                  dispatch(toggleSimulationExitConfirmationModal());
                } else {
                  dispatch(toggleSimulationModal());
                }
              }}
            />
          )}
          {!isGlobalAdminRole && (
            <LandingPageTile
              title={accountInfo.tileTitle}
              description={accountInfo.tileDescription}
              tileLinkIcon="navigate_next"
              id="accountInfo"
              link="/accountinfo"
              key="accountInfo"
              order={accountInfo.sortOrder}
              tileTitleColor={accountInfo.tileTitleColor}
              tileDescriptionColor={accountInfo.tileDescriptionColor}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
