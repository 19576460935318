import { createReducer } from '@reduxjs/toolkit';
import {
  setOrders,
} from '../actions';

const initialState = null;

/**
 * Keeps orders state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const orders = createReducer(initialState, {
  [setOrders.type]: (state, { value }) => value.response,
});

export default orders;
