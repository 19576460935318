import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Payments from "components/generic/Icon/Payments";
import { toggleEditBillingInfoSuccessModal } from "store/actions";
import { isUserCustomerUserOnly } from "store/reducers/index";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import InfoModal from "components/generic/InfoModal/InfoModal";
import AccountInfoPrimaryTile from "../AccountInfoPrimaryTile/AccountInfoPrimaryTile";
import AccountInfoSecondaryTile from "../AccountInfoSecondaryTile/AccountInfoSecondaryTile";
import EditBillingInfoModal from "../EditBillingInfoModal/EditBillingInfoModal";

import { cMSResources } from "../../../index";
import "./BillingInformation.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

/**
 * Billing information section
 *
 * @param {Object} accountInfo account info data
 */
const BillingInformation = ({ accountInfo }) => {
  const { customerName, address, contact } = accountInfo;
  const [openEditModal, setOpenEditModal] = useState(false);
  const openConfirmationModal = useSelector(
    (state) => state.modals.editBillingInfoSuccessModal.isOpen
  );
  const isCustomerUserOnly = useSelector(isUserCustomerUserOnly);
  const dispatch = useDispatch();
  var cmsBillingInfo = findArrayElementByTitle(cMSResources, "{{BillingInfo}}");
  var cmsBillingInformationTitle = findArrayElementByTitle(
    cMSResources,
    "{{BillingInformationTitle}}"
  );
  var cmsYourorganization = findArrayElementByTitle(
    cMSResources,
    "{{Yourorganization}}"
  );
  var cmsPrimaryContact = findArrayElementByTitle(
    cMSResources,
    "{{PrimaryContact}}"
  );

  var cmsNone = findArrayElementByTitle(cMSResources, "{{None}}");

  const onSendRequestHandler = () => {
    setOpenEditModal(false);
  };

  return (
    <div className="account-info--billing-address">
      <div className="account-info--title-wrapper">
        <Payments />
        <span className="billing-title">
          {cmsBillingInformationTitle.value}
        </span>
      </div>
      <AccountInfoPrimaryTile
        heading={cmsYourorganization.value}
        className="account-info-your-organization-tile"
        showEditButton={!isCustomerUserOnly}
        onEdit={() => setOpenEditModal(true)}
      >
        <AccountInfoSecondaryTile
          heading={customerName || cmsNone.value}
          className="account-info-billing-address-tile"
        >
          <HomeWorkIcon
            color="primary"
            className="account-info-icon-left-align"
          />
          <address className="account-info--billing-address">
            <span>{address?.street || cmsNone.value}</span>
            <span>
              {address?.city || cmsNone.value},{" "}
              {address?.postalCode || cmsNone.value}
            </span>
          </address>
        </AccountInfoSecondaryTile>
        <AccountInfoSecondaryTile
          heading={cmsPrimaryContact.value}
          className="account-info-primary-contact-tile"
        >
          <div className="account-info-primary-contact-row">
            <PersonIcon
              color="primary"
              className="account-info-icon-left-align"
            />
            <span>{contact.name ? contact.name : cmsNone.value}</span>
          </div>
          <div className="account-info-primary-contact-row">
            <PhoneIcon
              color="primary"
              className="account-info-icon-left-align"
            />
            <span>{contact.phone ? contact.phone : cmsNone.value}</span>
          </div>
          <div className="account-info-primary-contact-row">
            <MailIcon
              color="primary"
              className="account-info-icon-left-align"
            />
            {contact.email ? (
              <a
                className="primary-contact-mail"
                href={`mailto:${contact.mail}`}
              >
                {contact.email}
              </a>
            ) : (
              cmsNone.value
            )}
          </div>
        </AccountInfoSecondaryTile>
      </AccountInfoPrimaryTile>
      <EditBillingInfoModal
        open={openEditModal}
        onCloseHandler={() => setOpenEditModal(false)}
        accountInfo={accountInfo}
        onSendRequestHandler={onSendRequestHandler}
      />
      <InfoModal
        open={openConfirmationModal}
        title="Success"
        onClose={() => dispatch(toggleEditBillingInfoSuccessModal())}
      >
        {cmsBillingInfo.value}
      </InfoModal>
    </div>
  );
};

export default BillingInformation;
