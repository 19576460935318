import { createReducer } from '@reduxjs/toolkit';
import {
  setAlias,
} from '../actions';

const initialState = null;

/**
 * Keeps account info state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const accountInfo = createReducer(initialState, {
  GET_ACCOUNT_INFO: (state, { value }) => value.payload,
  [setAlias.type]: (state, { value }) => {
    const { systemId, alias } = value.payload;

    const currentSite = state.sites.find((site) => site.machines.find((machine) => machine.systemId === systemId));
    const mach = currentSite.machines.find((machine) => machine.systemId === systemId);
    mach.alias = alias;
  },
});

export default accountInfo;
