import React, { useState, useEffect } from 'react';
import { useGlobalEvent, useDebouncedFn } from 'beautiful-react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal, getCustomers, setCurrentPage } from 'store/actions';
import Plus from 'components/generic/Icon/Plus';
import Button from 'components/generic/Button/Button';
import ModalPopup from '../../components/Modal/Modal';
import TableComponent from '../../components/Table/Table';
import TableMobile from '../../components/TableMobile/TableMobile';
import { appInsights } from '../../index';

import './ManageCustomer.scss';

const ManageCustomer = () => {
  appInsights.startTrackPage('ManageCustomer');
  const show = useSelector((state) => state.showModal);
  const [popupClicked, clickPopup] = useState(false);
  const dispatch = useDispatch();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const onWindowResize = useGlobalEvent('resize');

  const onWindowResizeHandler = useDebouncedFn(() => {
    setWindowWidth(window.innerWidth);
  }, 250);

  onWindowResize(onWindowResizeHandler);

  useEffect(() => {
    dispatch(getCustomers('All', 'Manage Customers'));
  }, []);

  dispatch(setCurrentPage('Manage Customers'));
  const currentPageState = useSelector((state) => state.currentPage);

  appInsights.stopTrackPage('ManageCustomer', null, {
    currentPageState,
  });
  return (
    <>
      {windowWidth
      && windowWidth <= 1080 ? (
        <div className="manage-customer-mobile">
          <div className="onboard-button-container">
            <Button
              text="Onboard Customer"
              variant="contained"
              color="primary"
              size="fullWidth"
              startIcon={<Plus />}
              onClick={() => {
                dispatch(toggleModal(true));
                clickPopup(true);
              }}
            />
          </div>
          {popupClicked ? (
            <ModalPopup
              show={show}
              isEdit={false}
              screenName="manage-customer"
              onHide={() => {
                dispatch(toggleModal(false));
                clickPopup(false);
              }}
            />
          ) : null}
          <div className="manage-table-mobile">
            <TableMobile screenName="manage-customer" />
          </div>
        </div>
        ) : (
          <div className="manage-customer">
            <Button
              text="Onboard Customer"
              variant="contained"
              color="primary"
              startIcon={<Plus />}
              size="onboard"
              onClick={() => {
                dispatch(toggleModal(true));
                clickPopup(true);
              }}
            />
            {popupClicked ? (
              <ModalPopup
                show={show}
                isEdit={false}
                screenName="manage-customer"
                onHide={() => {
                  dispatch(toggleModal(false));
                  clickPopup(false);
                }}
              />
            ) : null}
            <div className="manage-table">
              <TableComponent screenName="manage-customer" />
            </div>
          </div>
        )}
    </>
  );
};

export default ManageCustomer;
