import React from 'react';
import classNames from 'classnames';
import { InputLabel, FormControl } from '@material-ui/core';
import MUISelect from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import './Select.scss';

/**
 * Component wrapping the MaterialUI Select component
 * Adds custom styling for the needs of the project
 * Accepts all props as the MaterialUI Select component
 *
 * @param {String} label      select title label
 * @param {String} labelId    id that maps the label and the select
 * @param {String} className  additional class names for custom styling
 * @param {String} variant    select types (standard, outlined)
 * @param {Object} children   select options as HTML elements
 * @param {Boolean} disabled  is the component disabled
 * @param {String} name       the name of the component
 * @param {Boolean} error     indicates if select has an error
 * @param {String} menuClass  additional class for the menu
 * @param {Boolean} placeholderSelected is placeholder selected (onload)
 */
const Select = ({
  label, labelId, className, variant, children, disabled, name, error, menuClass, placeholderSelected, ...props
}) => (
  <FormControl
    variant={variant}
    classes={{ root: `${classNames('generic_select__form', { 'generic-select-placeholder-selected': placeholderSelected })}` }}
    disabled={disabled}
    error={error}
  >
    <InputLabel
      classes={{ root: 'generic_select__label' }}
      id={labelId}
    >
      {label}
    </InputLabel>
    <MUISelect
      classes={{
        root: 'generic_select__select',
        outlined: 'generic_select__select-outlined',
        icon: 'generic_select__icon',
      }}
      IconComponent={() => <ArrowDropDownIcon className="generic_select__icon" />}
      labelId={labelId}
      MenuProps={{
        classes: {
          paper: `generic_menu-select-paper ${menuClass}`,
        },
        disableScrollLock: true,
      }}
      {...props}
    >
      {children}
    </MUISelect>
  </FormControl>
);

export default Select;
