import React, { useState, useEffect } from "react";
import { useGlobalEvent, useDebouncedFn } from "beautiful-react-hooks";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import { useSelector, useDispatch } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  setSimulationMode,
  setSimulatedUserClaims,
  toggleSimulationModal,
  toggleSimulationExitConfirmationModal,
  getCustomerInfo,
  getLogoAndColor,
} from "store/actions";
import { isUserRoleGlobalAdmin } from "store/reducers/index";
import SimulationModalPopup from "components/Modal/SimulationModal";
import SimulationExitConfirmationPopup from "components/SimulationExitConfirmationPopup/SimulationExitConfirmationPopup";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import PhoneIcon from "@material-ui/icons/Phone";
import PersonIcon from "@material-ui/icons/Person";
import Spinner from "components/Spinner/Spinner";
import DrawerNavSideBar from "./DrawerNavSideBar/DrawerNavSideBar";
import {
  manageAuth,
  cmsLogoAndCollor,
  cMSResources,
  mainSettings,
} from "../../index";

import drawerNavStyles from "./drawerNav.styles";
import "./DrawerNav.scss";

export let defaultLanguage = null;

const getParams = (url) => {
  const params = {};
  const parser = document.createElement("a");
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

/**
 * Contains the navigation at the top of the pages and the drawer/sidebar at the left hand-side of the page
 */

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const DrawerNav = () => {
  var cmsSignOut = findArrayElementByTitle(cMSResources, "{{SignOut}}");
  var cmsAccountInfo = findArrayElementByTitle(
    cMSResources,
    "{{AccountInformation}}"
  );
  var cmsEnglishLanguage = findArrayElementByTitle(
    cMSResources,
    "{{EnglishLanguage}}"
  );
  var cmsGermanLanguage = findArrayElementByTitle(
    cMSResources,
    "{{GermanLanguage}}"
  );
  var cmsFrenchLanguage = findArrayElementByTitle(
    cMSResources,
    "{{FrenchLanguage}}"
  );
  var cmsSpanishLanguage = findArrayElementByTitle(
    cMSResources,
    "{{SpanishLanguage}}"
  );
  var cmsItalianLanguage = findArrayElementByTitle(
    cMSResources,
    "{{ItalianLanguage}}"
  );

  const classes = drawerNavStyles();
  const [open, setOpen] = useState(false);
  const inSimulationMode = useSelector((state) => state.inSimulationMode);
  const simulatedUserClaims = useSelector((state) => state.simulatedUserClaims);
  const isGlobalAdminRole = useSelector(isUserRoleGlobalAdmin);
  const userName = useSelector((state) => state.claims.name);
  const { customerName } = useSelector((state) => state.claims.userDetails);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const showSimulationModal = useSelector(
    (state) => state.modals.simulationModal.isOpen
  );
  const simulationExitConfirmationPopupOpen = useSelector(
    (state) => state.modals.simulationExitConfirmationModal.isOpen
  );
  const servicePlan = useSelector((state) => state.servicePlan);
  const showSpinner = useSelector((state) => state.spinner);
  const logoAndColor = useSelector((state) => state.logoAndColor);

  let selectedLanguage = "en";
  const defaultLanguage = sessionStorage.getItem("defaultLanguage");
  if (defaultLanguage != null) {
    selectedLanguage = defaultLanguage;
  }

  const [language, setLanguage] = React.useState(selectedLanguage);
  const handleChange = (event) => {
    setLanguage(event.target.value);
    sessionStorage.setItem("defaultLanguage", event.target.value);

    manageAuth(event.target.value);
  };

  const onWindowResize = useGlobalEvent("resize");
  const onWindowResizeHandler = useDebouncedFn(() => {
    setWindowWidth(window.innerWidth);
  }, 250);
  onWindowResize(onWindowResizeHandler);

  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // start simulation popup logic
  const params = getParams(window.location.href);
  if (params.insimulationmode === "false" || !isGlobalAdminRole) {
    localStorage.clear("inSimulationMode");
    localStorage.clear("simulatedUser");
  }

  useEffect(() => {
    if (localStorage.getItem("inSimulationMode") === "true") {
      dispatch(setSimulationMode(true));
      dispatch(
        setSimulatedUserClaims(
          JSON.parse(localStorage.getItem("simulatedUser"))
        )
      );
    }
    dispatch(getCustomerInfo());
    dispatch(getLogoAndColor(defaultLanguage));
  }, []);

  // end simulation popup logic
  return (
    <div className="drawer-nav-wrapper">
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        elevation={0}
      >
        <div className={classes.toolbar}>
          <div className={classes.menuButtonWrapper}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={classes.toolBarInner}>
            <span className={classes.dummyHeaderElement} />
            {windowWidth && windowWidth >= 640 ? (
              <Link to="/">
                <img
                  src={logoAndColor.logo}
                  alt="Waterjet Connect logo"
                  className={classes.flowLogoImage}
                />
              </Link>
            ) : (
              <Link to="/">
                <img
                  src={logoAndColor.logo}
                  alt="Waterjet Connect logo"
                  className={classes.flowLogoImageMobile}
                />
              </Link>
            )}

            <div className={classes.userName}>
              {mainSettings.displayMultiLanguage && (
                <div>
                  {windowWidth && windowWidth >= 640 ? (
                    <Select
                      style={{
                        float: "right",
                        marginRight: "52px",
                        width: "200px",
                      }}
                      labelId="language-select"
                      value={language}
                      label="English"
                      onChange={handleChange}
                      // variant="outlined"
                    >
                      <MenuItem value="en">{cmsEnglishLanguage.value}</MenuItem>
                      <MenuItem value={"de"}>
                        {cmsGermanLanguage.value}
                      </MenuItem>
                      <MenuItem value={"fr"}>
                        {cmsFrenchLanguage.value}
                      </MenuItem>
                      <MenuItem value={"esp"}>
                        {cmsSpanishLanguage.value}
                      </MenuItem>
                      <MenuItem value={"it"}>
                        {cmsItalianLanguage.value}
                      </MenuItem>
                    </Select>
                  ) : (
                    <Select
                      style={{
                        float: "right",
                        marginRight: "0px",
                        width: "55px",
                        paddingLeft: "5px",
                      }}
                      labelId="language-select"
                      value={language}
                      label="English"
                      onChange={handleChange}
                      // variant="outlined"
                    >
                      <MenuItem value="en">En</MenuItem>
                      <MenuItem value={"de"}>De</MenuItem>
                      <MenuItem value={"fr"}>Fr</MenuItem>
                      <MenuItem value={"esp"}>Esp</MenuItem>
                      <MenuItem value={"it"}>It</MenuItem>
                    </Select>
                  )}
                </div>
              )}
              {isGlobalAdminRole ? (
                <PersonIcon
                  className={classNames(
                    classes.accountIcon,
                    classes.accountIconDisabled
                  )}
                  color="disabled"
                />
              ) : (
                <>
                  <Link to="/contactUs" className={classes.headerShortcut}>
                    <PhoneIcon color="primary" />
                  </Link>
                  <div class="dropdown-wrapper">
                    <i>
                      <PersonIcon
                        className={classes.accountIcon}
                        color="primary"
                      />
                    </i>
                    <ul class="dropdown-container">
                      <li class="dropdown-list">
                        <a
                          href="/accountinfo"
                          style={{ color: cmsLogoAndCollor.color }}
                        >
                          {cmsAccountInfo.value}
                        </a>
                      </li>
                      <li class="dropdown-list">
                        <a
                          href="#"
                          style={{ color: cmsLogoAndCollor.color }}
                          onClick={() => {
                            !inSimulationMode && manageAuth("logout");
                          }}
                        >
                          {cmsSignOut.value}
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              )}{" "}
              <div className={classes.userNameServicePlan}>
                <span className={classes.userNameText}>
                  {inSimulationMode ? simulatedUserClaims?.userName : userName}
                </span>
                <span className={classes.servicePlan}>{servicePlan}</span>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                width: "100%",
                left: "0",
                bottom: "0",
              }}
            >
              {windowWidth && windowWidth >= 640 ? (
                <span style={{ float: "right", marginRight: "52px" }}>
                  {inSimulationMode
                    ? simulatedUserClaims.customerName
                    : customerName}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </AppBar>
      <DrawerNavSideBar
        classes={classes}
        handleDrawerClose={handleDrawerClose}
        isGlobalAdminRole={isGlobalAdminRole}
        open={open}
        inSimulationMode={inSimulationMode}
      />
      {isGlobalAdminRole && !inSimulationMode && (
        <>
          <SimulationModalPopup
            show={showSimulationModal}
            onHide={() => {
              dispatch(toggleSimulationModal());
            }}
          />
        </>
      )}
      <SimulationExitConfirmationPopup
        open={simulationExitConfirmationPopupOpen}
        onClose={() => dispatch(toggleSimulationExitConfirmationModal())}
      />
      {showSpinner ? <Spinner /> : null}
    </div>
  );
};

export default DrawerNav;
