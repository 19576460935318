import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Payments = () => (
  <SvgIcon color="primary">
    <path fill="currentColor" d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-9-1c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm13-6v11c0 1.1-.9 2-2 2H4v-2h17V7h2z" />
  </SvgIcon>
);

export default Payments;
