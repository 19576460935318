import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation } from "swiper/core";
import { config } from "../../index";

import "./SwiperLanding.scss";

SwiperCore.use([Navigation]);

/**
 * Swiper positioned in he landing page
 *
 * @param {String} imagesId   id related to the role
 * @param {Object} slidesText contains text and subtext for each slide
 */

const SwiperLanding = ({ imagesId, slidesText }) =>
  imagesId && (
    <div>
      {config[`carousel-${imagesId}-slide-two`] == null &&
      config[`carousel-${imagesId}-slide-three`] == null ? (
        <Swiper loop className="landing-page-swiper">
          {slidesText?.first_slide?.bannerLink ? (
            <SwiperSlide>
              <a href={slidesText?.first_slide?.bannerLink} target="_blank">
                <img
                  src={config[`carousel-${imagesId}-slide-one`]}
                  alt="Slide 1"
                  style={{ width: "100%" }}
                />
                <div
                  style={{ color: slidesText?.first_slide?.color }}
                  className="slide-text-wrapper"
                >
                  <span>{slidesText?.first_slide?.text}</span>
                  <span>{slidesText?.first_slide?.subText}</span>
                </div>
              </a>
            </SwiperSlide>
          ) : (
            <SwiperSlide>
              <img
                src={config[`carousel-${imagesId}-slide-one`]}
                alt="Slide 1"
                style={{ width: "100%" }}
              />
              <div
                style={{ color: slidesText?.first_slide?.color }}
                className="slide-text-wrapper"
              >
                <span>{slidesText?.first_slide?.text}</span>
                <span>{slidesText?.first_slide?.subText}</span>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      ) : (
        <Swiper navigation loop className="landing-page-swiper">
          {slidesText?.first_slide?.bannerLink ? (
            <SwiperSlide>
              <a href={slidesText?.first_slide?.bannerLink} target="_blank">
                <img
                  src={config[`carousel-${imagesId}-slide-one`]}
                  alt="Slide 1"
                  style={{ width: "100%" }}
                />
                <div
                  style={{ color: slidesText?.first_slide?.color }}
                  className="slide-text-wrapper"
                >
                  <span>{slidesText?.first_slide?.text}</span>
                  <span>{slidesText?.first_slide?.subText}</span>
                </div>
              </a>
            </SwiperSlide>
          ) : (
            <SwiperSlide>
              <img
                src={config[`carousel-${imagesId}-slide-one`]}
                alt="Slide 1"
                style={{ width: "100%" }}
              />
              <div
                style={{ color: slidesText?.first_slide?.color }}
                className="slide-text-wrapper"
              >
                <span>{slidesText?.first_slide?.text}</span>
                <span>{slidesText?.first_slide?.subText}</span>
              </div>
            </SwiperSlide>
          )}
          {config[`carousel-${imagesId}-slide-two`] != null &&
          slidesText?.second_slide?.bannerLink ? (
            <SwiperSlide>
              <a href={slidesText?.second_slide?.bannerLink} target="_blank">
                <img
                  src={config[`carousel-${imagesId}-slide-two`]}
                  alt="Slide 2"
                  style={{ width: "100%" }}
                />
                <div
                  style={{ color: slidesText?.second_slide?.color }}
                  className="slide-text-wrapper"
                >
                  <span>{slidesText?.second_slide?.text}</span>
                  <span>{slidesText?.second_slide?.subText}</span>
                </div>
              </a>
            </SwiperSlide>
          ) : (
            config[`carousel-${imagesId}-slide-two`] != null && (
              <SwiperSlide>
                <a href={slidesText?.second_slide?.bannerLink} target="_blank">
                  <img
                    src={config[`carousel-${imagesId}-slide-two`]}
                    alt="Slide 2"
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{ color: slidesText?.second_slide?.color }}
                    className="slide-text-wrapper"
                  >
                    <span>{slidesText?.second_slide?.text}</span>
                    <span>{slidesText?.second_slide?.subText}</span>
                  </div>
                </a>
              </SwiperSlide>
            )
          )}

          {config[`carousel-${imagesId}-slide-three`] != null &&
          slidesText?.third_slide?.bannerLink ? (
            <SwiperSlide>
              <a href={slidesText?.third_slide?.bannerLink} target="_blank">
                <img
                  src={config[`carousel-${imagesId}-slide-three`]}
                  alt="Slide 3"
                  style={{ width: "100%" }}
                />
                <div
                  style={{ color: slidesText?.third_slide?.color }}
                  className="slide-text-wrapper"
                >
                  <span>{slidesText?.third_slide?.text}</span>
                  <span>{slidesText?.third_slide?.subText}</span>
                </div>
              </a>
            </SwiperSlide>
          ) : (
            config[`carousel-${imagesId}-slide-three`] != null && (
              <SwiperSlide>
                <img
                  src={config[`carousel-${imagesId}-slide-three`]}
                  alt="Slide 3"
                  style={{ width: "100%" }}
                />
                <div
                  style={{ color: slidesText?.third_slide?.color }}
                  className="slide-text-wrapper"
                >
                  <span>{slidesText?.third_slide?.text}</span>
                  <span>{slidesText?.third_slide?.subText}</span>
                </div>
              </SwiperSlide>
            )
          )}
        </Swiper>
      )}
    </div>
  );

export default SwiperLanding;
