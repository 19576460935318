import React, { useEffect } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { cmsLogoAndCollor } from '../../index';
import './Spinner.css';

const Spinner = () => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.style.paddingRight = '15px';
    body.style.overflow = 'hidden';
    body.style['pointer-events'] = 'none';
    body.style.cursor = 'not-allowed';
    body.style.opacity = 0.9;

    return () => {
      body.style.paddingRight = '';
      body.style.overflow = 'visible';
      body.style['pointer-events'] = 'auto';
      body.style.cursor = 'auto';
      body.style.opacity = 1;
    };
  });
  return (
    <div className="overlay">
      <div className="spinner-image">
        <BounceLoader size={50} color={cmsLogoAndCollor.color} />
      </div>
    </div>
  );
};

export default Spinner;
