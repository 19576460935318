import React from "react";
import { useSelector } from "react-redux";
import LandingPage from "Pages/LandingPage/LandingPage";
import { appInsights } from "../../index";

const Home = () => {
  appInsights.startTrackPage("Home");
  const userClaims = useSelector((state) => state.claims);
  const role = userClaims
    ? JSON.parse(userClaims.extension_CustomerMapping).CustomerMappings[0].Role
    : null;
  const registrationStatus = userClaims
    ? userClaims.extension_Registered
    : null;
  appInsights.stopTrackPage("Home", null, {
    userrole: role,
    registrationStatus,
  });
  return (
    <>
      <LandingPage />
    </>
  );
};

export default Home;
