import { createReducer } from '@reduxjs/toolkit';

const initialState = 'Home';

/**
 * Keeps current page state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const currentPage = createReducer(initialState, {
  SET_CURRENTPAGE: (state, { value }) => value.payload,
});

export default currentPage;
