import Axios from "axios";
import { accessToken } from "../index";

const axios = () => {
  return Axios.create(); // No default Content-Type header here
};

/**
 * Performs a HTTP request.
 *
 * @param   {String}  baseUrl        base url different than the generic one
 * @param   {String}  urlSlug        request URL that comes after base URL
 * @param   {Object}  payload        HTTP request body
 * @param   {String}  method         HTTP method - POST, GET...
 * @param   {String}  externalToken  token used to call any other third party APIs
 * @return  {Promise}
 */
const request = async ({
  baseUrl,
  urlSlug,
  payload,
  method = "GET",
  externalToken,
}) => {
  const axiosInstance = axios();

  // Set Authorization header if token is present
  if (externalToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${externalToken}`;
  } else if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  // Set base URL if provided
  if (baseUrl) {
    axiosInstance.defaults.baseURL = baseUrl;
  }

  // If payload is FormData, do not set Content-Type, let the browser handle it
  if (payload instanceof FormData) {
    delete axiosInstance.defaults.headers.common["Content-Type"];
  }

  const { data } = await axiosInstance.request({
    url: urlSlug,
    method,
    data: payload,
  });

  return data;
};

export default request;
