import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import './AccountInfoPrimaryTile.scss';

/**
 * Primary tile. Used in account info page
 *
 * @param {String}   heading        text in the header
 * @param {Function} onEdit         called when the edit button is clicked
 * @param {String}   className      additional classes
 * @param {Boolean}  showEditButton indicates if the edit button should be shown
 * @param {Object}   children       children
 */
const AccountInfoPrimaryTile = ({
  heading, onEdit, className, showEditButton = true, children,
}) => (
  <div className={`account-info-primary-tile ${className}`}>
    <div className="account-info-primary-tile--header">
      <div>
        {heading}
      </div>
      {showEditButton && (
        <IconButton onClick={onEdit} className="account-info-primary-tile--edit-btn" edge="end" color="primary">
          <EditIcon classes={{ root: 'account-info-edit-icon-root' }} />
        </IconButton>
      )}
    </div>
    <div className="account-info-primary-tile--body">{children}</div>
  </div>
);

export default AccountInfoPrimaryTile;
