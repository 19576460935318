import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 192;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonWrapper: {
    backgroundColor: "#E7E8E8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("tablet")]: {},
    [theme.breakpoints.up("desktop")]: {},
  },
  menuButton: {
    marginLeft: 0,
    backgroundColor: "#E7E8E8",
    "& svg": {
      fontSize: "28px",
      color: theme.palette.primary.main,
    },
    padding: "14px",
    [theme.breakpoints.up("tablet")]: {
      padding: "16px",
      "& svg": {
        fontSize: "32px",
      },
    },
    [theme.breakpoints.up("desktop")]: {
      padding: "22px",
      "& svg": {
        fontSize: "44px",
      },
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "48px",
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: {
    paddingLeft: 0,
    minHeight: "56px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    [theme.breakpoints.up("tablet")]: {
      minHeight: "64px",
    },
    [theme.breakpoints.up("desktop")]: {
      minHeight: "88px",
    },
  },
  toolBarInner: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.primary,
    fontSize: "18px",
    padding: "0 8px",
    backgroundColor: "#F2F2F2",
    [theme.breakpoints.up("tablet")]: {
      padding: "0 52px 0 12px",
    },
  },
  flowLogoImage: {
    width: "100%",
    height: "50px",
    [theme.breakpoints.up("tablet")]: {
      width: "100%",
    },
  },
  flowLogoImageMobile: {
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: "100%",
    },
  },
  accountIcon: {
    fontSize: "29px",
    cursor: "pointer",
    [theme.breakpoints.up("tablet")]: {
      marginRight: "8px",
    },
  },
  accountIconDisabled: {
    cursor: "default",
  },
  userName: {
    display: "flex",
    alignItems: "center",
  },
  userNameText: {
    display: "none",
    [theme.breakpoints.up("tablet")]: {
      display: "inline",
      fontSize: "0.875rem",
      lineHeight: "14px",
    },
  },
  servicePlan: {
    display: "none",
    [theme.breakpoints.up("tablet")]: {
      display: "inline",
      fontSize: "0.625rem",
      lineHeight: "16.48px",
    },
  },
  userNameServicePlan: {
    display: "flex",
    flexDirection: "column",
  },
  headerShortcut: {
    marginRight: "8px",
    position: "relative",
    "& svg": {
      fontSize: "24px",
      [theme.breakpoints.up("tablet")]: {
        fontSize: "25px",
      },
    },
    "&:last-child": {
      marginRight: 0,
      [theme.breakpoints.up("tablet")]: {
        marginRight: "8px",
      },
    },
  },
  dummyHeaderElement: {
    [theme.breakpoints.up("tablet")]: {
      display: "none",
    },
  },
  drawerDivider: {
    backgroundColor: "#F2F2F2",
  },
  drawerDividerBottom: {
    margin: "5px 0 20px",
  },
  drawerChevronIconButton: {
    color: "white",
    padding: "8px",
    "& svg": {
      fontSize: "32px",
    },
  },
  drawerLink: {
    color: "white",
    fontSize: "0.875rem",
    "&:hover": {
      color: "white",
    },
  },
  listItemIcon: {
    minWidth: "25px",
    "& svg": {
      fontSize: "17px",
    },
  },
  listItem: {
    paddingTop: "13px",
    paddingBottom: "13px",
    fontSize: "0.875rem",
  },
  materialIcon: {
    color: "white",
    marginRight: "6px",
  },
  simulateIcon: {
    "& svg": {
      fontSize: "19px",
    },
  },
  drawerListRoot: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyles;
