import React from "react";
import { cmsLogoAndCollor } from "../../index";
import shapelogo from "../../assets/shape-logo-gray.svg";

import "./Footer.scss";
/**
 * Footer
 */
const Footer = () => (
  <div className="footer-div">
    <div className="footer-logo">
      <img className="shape-logo" alt="shape-logo" src={shapelogo} />
    </div>
    <span className="text-bold">{cmsLogoAndCollor.footerHeader}</span>
    <div className="footer-text">
      <span>
        ©{new Date().getFullYear()} {cmsLogoAndCollor.footerBody} &nbsp;
      </span>
      <div className="links-wrapper">
        <a
          className="footer-link-privacy"
          href={cmsLogoAndCollor.footerPrivacyAndPolicyLink}
          target="_blank"
          rel="noreferrer"
        >
          {cmsLogoAndCollor.footerPrivacyAndPolicyName}
        </a>
        |
        <a
          className="footer-link-terms"
          href={cmsLogoAndCollor.footerTermsAndConditionsLink}
          target="_blank"
          rel="noreferrer"
        >
          {cmsLogoAndCollor.footerTermsAndConditionsName}
        </a>
      </div>
    </div>
  </div>
);

export default Footer;
