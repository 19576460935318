import React from 'react';
import MuiTableCell from '@material-ui/core/TableCell';

const TableCell = ({ children, className, ...props }) => (
  <MuiTableCell
    className={className}
    classes={{
      head: 'generic-table-head-cell',
      root: 'generic-table-root-cell',
    }}
    {...props}
  >
    {children}
  </MuiTableCell>
);

export default TableCell;
