import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';

import './Image.scss';

const round = (num) => (Math.ceil(num * 10) / 10).toFixed(1);

const Image = memo(({ file }) => (
  <div className="pump-hours-chosen-image-wrapper shape_flex shape_flex--direction-column shape_flex--space-between">
    <Typography className="pump-hours-chosen-image-name" noWrap>{file.name}</Typography>
    <img
      className="pump-hours-chosen-image"
      width="29.25px"
      height="29.25px"
      src={file ? URL.createObjectURL(file) : null}
      alt={file ? file.name : null}
    />
    <span className="pump-hours-chosen-image-size">{`${round(file.size * 0.00000095367432)}MB - ${file.type.substring(6, file.type.length)}`}</span>
  </div>
));

export default Image;
