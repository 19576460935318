import { createReducer } from '@reduxjs/toolkit';

const initialState = '';

/**
 * Keeps service plan state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const servicePlan = createReducer(initialState, {
  GET_CUSTOMER_INFO: (state, { value }) => value.payload.servicePlan,
});

export default servicePlan;
