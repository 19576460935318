import { createReducer } from '@reduxjs/toolkit';

const initialState = false;

/**
 * Keeps show modal state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const showModal = createReducer(initialState, {
  TOGGLE_MODAL: (state) => !state,
});

export default showModal;
