import React, { useEffect } from 'react';
import Player from '@vimeo/player';

const VimeoVideo = ({ link, id }) => {
  useEffect(() => {
    const options = {
      url: link,
      responsive: true,
    };

    const vimeoPlayer = new Player(`${id}`, options);
  }, []);
  return (
    <div key={id} id={id} />
  );
};

export default VimeoVideo;
