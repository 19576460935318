import React from 'react';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import './Table.scss';

const Table = ({ head, children }) => (
  <MuiTable className="generic-table generic-table-table">
    <TableHead className="generic-table-thead">
      <TableRow classes={{ head: 'generic-table-head-row' }}>
        {head}
      </TableRow>
    </TableHead>
    <TableBody>
      {children}
    </TableBody>
  </MuiTable>
);

export default Table;
