import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DoubleArrow = () => (
  <SvgIcon width="21" height="17" viewBox="0 0 21 17">
    <g clipPath="url(#clip0)">
      <path d="M8.10538 7.67402L15.0309 0.748619C15.1911 0.588314 15.4049 0.5 15.6329 0.5C15.8609 0.5 16.0747 0.588314 16.2349 0.748619L16.7449 1.25851C17.0768 1.59076 17.0768 2.13076 16.7449 2.46251L10.9294 8.27804L16.7514 14.1C16.9116 14.2603 17 14.474 17 14.7019C17 14.93 16.9116 15.1437 16.7514 15.3042L16.2414 15.8139C16.0811 15.9742 15.8674 16.0625 15.6394 16.0625C15.4114 16.0625 15.1975 15.9742 15.0374 15.8139L8.10538 8.88219C7.94483 8.72138 7.85664 8.50667 7.85715 8.27842C7.85664 8.04929 7.94483 7.8347 8.10538 7.67402Z" />
    </g>
    <g clipPath="url(#clip1)">
      <path d="M3.10538 7.67402L10.0309 0.748619C10.1911 0.588314 10.4049 0.5 10.6329 0.5C10.8609 0.5 11.0747 0.588314 11.2349 0.748619L11.7449 1.25851C12.0768 1.59076 12.0768 2.13076 11.7449 2.46251L5.92939 8.27804L11.7514 14.1C11.9116 14.2603 12 14.474 12 14.7019C12 14.93 11.9116 15.1437 11.7514 15.3042L11.2414 15.8139C11.0811 15.9742 10.8674 16.0625 10.6394 16.0625C10.4114 16.0625 10.1975 15.9742 10.0374 15.8139L3.10538 8.88219C2.94483 8.72138 2.85664 8.50667 2.85715 8.27842C2.85664 8.04929 2.94483 7.8347 3.10538 7.67402Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 21 0.5)" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 16 0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default DoubleArrow;
