import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { submitLicenseAgreement } from "store/actions";
import Modal from "components/generic/Modal/Modal";
import ModalButtons from "components/generic/Modal/ModalButtons/ModalButtons";
import {
  cMSResources,
  cmsLicenseAgreement,
  hasAcceptEULA,
  tilesDetails,
} from "../../index";

import "./LicenseAgreementModal.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

function findArrayElementBySegment(array, segment) {
  return array.find((element) => {
    return element.segment === segment;
  });
}

const LicenseAgreementModal = ({ open, onClose }) => {
  const defaultLanguage = sessionStorage.getItem("defaultLanguage");
  const inSimulationMode = useSelector((state) => state.inSimulationMode);
  useState(false);
  const defaultValues = {
    pumpSerialNumber: null,
    pumpHours: null,
    details: null,
    culture: defaultLanguage,
  };

  var waterjetConnectIQ = findArrayElementBySegment(
    tilesDetails,
    "waterjet-connect-iq"
  );
  var cmsSubmit = findArrayElementByTitle(cMSResources, "{{Submit}}");
  var cmsCancel = findArrayElementByTitle(cMSResources, "{{Cancel}}");
  var cmsAgree = findArrayElementByTitle(cMSResources, "{{Agree}}");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur", reValidateMode: "onBlur", defaultValues });
  const dispatch = useDispatch();

  const handleModalClose = () => {
    onClose();
  };

  const onSubmit = () => {
    dispatch(submitLicenseAgreement());
    handleModalClose();
    window.location.href = waterjetConnectIQ.tileLink;
  };

  const buttonsConfig = [
    {
      text: cmsCancel.value,
      handler: handleModalClose,
    },
    {
      text: cmsAgree.value,
      handler: handleSubmit(onSubmit),
      disabled: !isValid || inSimulationMode,
    },
  ];

  const buttonsConfigOnlyCancel = [
    {
      text: cmsCancel.value,
      handler: handleModalClose,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      title={cmsLicenseAgreement.title}
      footer={
        <ModalButtons
          config={hasAcceptEULA ? buttonsConfigOnlyCancel : buttonsConfig}
        />
      }
    >
      <div dangerouslySetInnerHTML={{ __html: cmsLicenseAgreement.body }}></div>
    </Modal>
  );
};

export default LicenseAgreementModal;
