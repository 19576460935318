import { createReducer } from "@reduxjs/toolkit";

const initialState = false;

/**
 * Keeps service plan state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const anyMachine = createReducer(initialState, {
  GET_ANY_MACHINE: (state, { value }) => value.payload,
});

export default anyMachine;
