import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const OrderHistory = ({ className, color }) => (
  <SvgIcon style={{color : color}} className={className} width="32" height="35" viewBox="0 0 32 35">
    <path d="M29.125 2.97919L26.5 0.416687L23.875 2.97919L21.25 0.416687L18.625 2.97919L16 0.416687L13.375 2.97919L10.75 0.416687L8.125 2.97919L5.5 0.416687V24.3334H0.25V29.4584C0.25 32.2942 2.595 34.5834 5.5 34.5834H26.5C29.405 34.5834 31.75 32.2942 31.75 29.4584V0.416687L29.125 2.97919ZM28.25 29.4584C28.25 30.3979 27.4625 31.1667 26.5 31.1667C25.5375 31.1667 24.75 30.3979 24.75 29.4584V24.3334H9V5.54169H28.25V29.4584Z" />
    <path d="M21.25 8.95837H10.75V12.375H21.25V8.95837Z" />
    <path d="M26.5 8.95837H23V12.375H26.5V8.95837Z" />
    <path d="M21.25 14.0834H10.75V17.5H21.25V14.0834Z" />
    <path d="M26.5 14.0834H23V17.5H26.5V14.0834Z" />
  </SvgIcon>
);

export default OrderHistory;
