import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Image from '../Image/Image';
import { cmsLogoAndCollor } from '../../../index';

const ChosenImages = ({ files, onImageRemove }) => (
  files.length !== 0 && (
  <div className="pump-hours-chosen-images shape_flex">
    {files.map((file, index) => (
      <div className="pump-hours-chosen-image-delete-button-wrapper" key={file.name}>
        <span
          style={{backgroundColor: cmsLogoAndCollor.color}} 
          className="pump-hours-chosen-image-delete-button shape_flex shape_flex--align-center shape_flex--center"
          onClick={() => onImageRemove(index)}
        >
          <DeleteIcon className="pump-hours-delete-icon" />
        </span>
        <Image file={file} />
      </div>
    ))}
  </div>
  )
);

export default ChosenImages;
