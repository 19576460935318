import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import Paper from "components/generic/Paper/Paper";
import { getOverwriteResources } from "store/actions";
import { cMSResources, cmsLogoAndCollor, mainSettings } from "../../index";

import "./ContactUs.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const ContactUs = () => {
  const overwriteResources = useSelector((state) => state.overwriteResources);
  const defaultLanguage = sessionStorage.getItem("defaultLanguage");
  const dispatch = useDispatch();
  const [cmsPhoneNumber, setCmsPhoneNumber] = useState("");
  const [cmsEmail, setcmsEmail] = useState("");

  useEffect(() => {
    dispatch(getOverwriteResources(defaultLanguage));
  }, [dispatch, defaultLanguage]);

  useEffect(() => {
    const phoneResource = overwriteResources.find(
      (resource) => resource.key === "{{Phone}}"
    );
    const emailResource = overwriteResources.find(
      (resource) => resource.key === "{{Email}}"
    );
    if (phoneResource) {
      setCmsPhoneNumber(phoneResource.value);
    }
    if (emailResource) {
      setcmsEmail(emailResource.value);
    }
  }, [overwriteResources]);

  var cmsWorkHours = findArrayElementByTitle(
    cMSResources,
    "{{ContactUsWorkHours}}"
  );
  var cmsCustomText = findArrayElementByTitle(
    cMSResources,
    "{{ContactUsCustomText}}"
  );
  var cmsContactUs = findArrayElementByTitle(cMSResources, "{{ContactUs}}");
  var cmsEmailString = findArrayElementByTitle(cMSResources, "{{EmailString}}");
  var cmsCallUs = findArrayElementByTitle(cMSResources, "{{CallUs}}");
  var cmsMondayFriday = findArrayElementByTitle(
    cMSResources,
    "{{MondayFriday}}"
  );
  var cmsHours = findArrayElementByTitle(cMSResources, "{{Hours}}");
  var cmsContactIoTSupport = findArrayElementByTitle(
    cMSResources,
    "{{ContactIoTSupport}}"
  );
  var cmsContactIoTPhone = findArrayElementByTitle(
    cMSResources,
    "{{ContactIoTPhone}}"
  );

  return (
    <div className="contact-us">
      <div className="contact-us-header shape_flex shape_flex--align-center">
        <PhoneIcon color="primary" className="contact-us-body-icon" />
        <div className="shape_flex shape_flex--direction-column">
          <span className="contact-us-header-title">{cmsContactUs.value}</span>
          <span className="contact-us-header-description">
            {cmsCustomText.value}
          </span>
        </div>
      </div>
      <div className="contact-us-body shape_flex">
        <Paper variant="gray" className="contact-us-call-us-wrapper">
          <Paper>
            <div className="shape_flex shape_flex--align-center shape_flex--center">
              <PhoneIcon color="primary" className="contact-us-body-icon" />
              <span
                className="contact-us-body-icon-text"
                style={{ color: cmsLogoAndCollor.color }}
              >
                {cmsCallUs.value}
              </span>
            </div>
            <div
              className="contact-us-phone"
              style={{ color: cmsLogoAndCollor.color }}
            >
              {cmsPhoneNumber}
            </div>
            <div className="shape_flex shape_flex--direction-column">
              <span className="contact-us-hours-label">{cmsHours.value}</span>
              <span className="contact-us-hours-text">
                {cmsMondayFriday.value}
                <span className="contact-us-working-hours-divider">|</span>
                {cmsWorkHours.value}
              </span>
            </div>
          </Paper>
        </Paper>
        {!mainSettings.hideIoTsupport && (
          <Paper variant="gray" className="contact-us-call-us-wrapper">
            <Paper>
              <div className="shape_flex shape_flex--align-center shape_flex--center">
                <PhoneIcon color="primary" className="contact-us-body-icon" />
                <span
                  className="contact-us-body-icon-text"
                  style={{ color: cmsLogoAndCollor.color }}
                >
                  {cmsContactIoTSupport.value}
                </span>
              </div>
              <div
                className="contact-us-phone"
                style={{ color: cmsLogoAndCollor.color }}
              >
                {cmsContactIoTPhone.value}
              </div>
            </Paper>
          </Paper>
        )}
        <Paper variant="gray" className="contact-us-write-us-wrapper">
          <Paper>
            <div className="shape_flex shape_flex--align-center shape_flex--center">
              <MailIcon color="primary" className="contact-us-body-icon" />
              <span
                className="contact-us-body-icon-text"
                style={{ color: cmsLogoAndCollor.color }}
              >
                {cmsEmailString.value}
              </span>
            </div>
            <a
              className="contact-us-mail"
              style={{ color: cmsLogoAndCollor.color }}
              href={"mailto:" + cmsEmail}
            >
              {cmsEmail}
            </a>
          </Paper>
        </Paper>
      </div>
    </div>
  );
};

export default ContactUs;
