import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 } from "uuid";
import {
  getAccountInfo,
  toggleEditInstallationAddressSuccessModal,
  toggleEditInstallationAliasSuccessModal,
} from "store/actions";
import { getCurrentRoleConfig, isCustomerAdmin } from "store/reducers/index";
import RoomIcon from "@material-ui/icons/Room";
import InfoModal from "components/generic/InfoModal/InfoModal";
import Installation from "./Installation/Installation";
import LicenseAgreementSection from "./LicenseAgreementSection/LicenseAgreementSection";
import BillingInformation from "./BillingInformation/BillingInformation";
import { cMSResources } from "../../index";

import "./AccountInfo.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

/**
 * Account info page
 */
const AccountInfo = () => {
  const accountInfo = useSelector((state) => state.accountInfo);
  const roleConfig = useSelector(getCurrentRoleConfig);
  const isCustomerAdminRole = useSelector(isCustomerAdmin);
  const openEditInstallationAddressSuccessModal = useSelector(
    (state) => state.modals.editInstallationAddressSuccessModal.isOpen
  );
  const openEditInstallationAliasSuccessModal = useSelector(
    (state) => state.modals.editInstallationAliasSuccessModal.isOpen
  );
  var cmsAccountInfo = findArrayElementByTitle(cMSResources, "{{AccountInfo}}");
  var cmsInstallations = findArrayElementByTitle(
    cMSResources,
    "{{AccountInformationInstallations}}"
  );
  var cmsAccountInformation = findArrayElementByTitle(
    cMSResources,
    "{{AccountInformation}}"
  );
  var cmsAccountInformationDetail = findArrayElementByTitle(
    cMSResources,
    "{{AccountInformationDetail}}"
  );
  var cmsSystemAliasUpdated = findArrayElementByTitle(
    cMSResources,
    "{{SystemAliasUpdated}}"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccountInfo());
  }, []);
  return (
    accountInfo && (
      <div className="account-info--wrapper">
        <div className="account-info--title">
          <h2 className="account-info--title-heading">
            {cmsAccountInformation.value}
          </h2>
          <div>{cmsAccountInformationDetail.value}</div>
        </div>
        <BillingInformation accountInfo={accountInfo} />
        {accountInfo.isInstallationVisible && (
          <div className="account-info--installations">
            <div className="installations-title-wrapper">
              <RoomIcon color="primary" />
              <span className="installations-title">
                {cmsInstallations.value}
              </span>
            </div>
            <div className="installations-content">
              {accountInfo.sites.map((site) => (
                <Installation key={v4()} installation={site} />
              ))}
            </div>
          </div>
        )}
        {isCustomerAdminRole && (
          <LicenseAgreementSection
            waterJetLink={roleConfig.navItems.iotDashboard.link}
          />
        )}
        <InfoModal
          open={openEditInstallationAddressSuccessModal}
          title="Success"
          onClose={() => dispatch(toggleEditInstallationAddressSuccessModal())}
        >
          {cmsAccountInfo.value}
        </InfoModal>
        <InfoModal
          open={openEditInstallationAliasSuccessModal}
          title="Success"
          onClose={() => dispatch(toggleEditInstallationAliasSuccessModal())}
          className="edit-alias-success-modal"
        >
          {cmsSystemAliasUpdated.value}
        </InfoModal>
      </div>
    )
  );
};

export default AccountInfo;
