import { createReducer } from '@reduxjs/toolkit';

const initialState = null;

/**
 * Keeps simulated user claims state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const simulatedUserClaims = createReducer(initialState, {
  SET_SIMULATEDUSER_CLAIMS: (state, { value }) => value.payload,
});

export default simulatedUserClaims;
