export const GlobalAdminNav = [
  { name: "Manage Customers", path: "/managecustomer" },
  { name: "Manage Users", path: "/manageuser" },
];

export const CustomerTeamAdminNav = [
  { name: "Manage Users", path: "/manageuser" },
];

export const CustomerUserNav = [];

export const manageCustomersConfig = {
  manageCustomers: {
    id: "manageCustomers",
    tileTitle: "Manage Customers",
    drawerTitle: "Manage Customers",
    link: "/managecustomer",
    logo: "groups",
    description: "As a Global Administrator, you can add or manage customers",
    tileLinkIcon: "navigate_next",
    padding: 10,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const manageUsersConfig = {
  manageUsers: {
    id: "manageUsers",
    tileTitle: "Manage Users",
    drawerTitle: "Manage Users",
    link: "/manageuser",
    logo: "manage_accounts",
    description: "Add or manage users",
    tileLinkIcon: "navigate_next",
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const purchaseConfig = {
  purchase: {
    id: "purchase",
    tileTitle: "Purchase Parts",
    drawerTitle: "Purchase Parts",
    link: "",
    logo: "ViewInAr",
    description: "Go to FlowParts.com to order parts",
    tileLinkIcon: "navigate_next",
    tileOrder: 1,
    drawerOrder: 2,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const reportsConfig = {
  reports: {
    id: "reports",
    tileTitle: "Reports",
    drawerTitle: "Reports",
    link: "/reports",
    logo: "reports",
    description: "View recent reports",
    tileLinkIcon: "navigate_next",
    tileOrder: 2,
    disabled: false,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const iotDashboardConfig = {
  iotDashboard: {
    id: "iotDashboard",
    tileTitle: "Waterjet \n Connect IQ",
    drawerTitle: "Waterjet Connect IQ",
    link: "",
    logo: "dashboard",
    description: "View current component life information",
    tileLinkIcon: "navigate_next",
    tileOrder: 0,
    drawerOrder: 1,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
    display: "none",
  },
};

export const scheduleTrainingConfig = {
  scheduleTraining: {
    id: "scheduleTraining",
    tileTitle: "Schedule Training",
    drawerTitle: "Schedule Training",
    link: "https://training.flowwaterjet.com",
    logo: "school",
    description: "Access Flow's eLearning Academy",
    tileLinkIcon: "navigate_next",
    tileOrder: 3,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
    display: "none",
  },
};

export const partsOrdersConfig = {
  orderHistory: {
    id: "orderHistory",
    tileTitle: "Parts Orders",
    drawerTitle: "Parts Orders",
    link: "/orderHistory",
    logo: "orderHistory",
    description: "View recent orders",
    tileLinkIcon: "navigate_next",
    tileOrder: 2,
    disabled: false,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const docVideoConfig = {
  docVideo: {
    id: "docAndVideo",
    tileTitle: "Docs & Videos",
    drawerTitle: "Docs & Videos",
    link: "/docsAndVideos",
    logo: "multimedia",
    description: "Search Flow's knowledge base",
    tileLinkIcon: "navigate_next",
    tileOrder: 6,
    drawerOrder: 5,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const serviceHistoryConfig = {
  serviceHistory: {
    id: "serviceHistory",
    tileTitle: "Service Activity",
    drawerTitle: "Service Activity",
    link: "/serviceHistory",
    logo: "construction",
    description: "View recent and upcoming services",
    tileLinkIcon: "navigate_next",
    tileOrder: 5,
    disabled: false,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const comingAttractionsConfig = {
  comingAttractions: {
    id: "comingAttractions",
    tileTitle: "Upcoming Events",
    drawerTitle: "Upcoming Events",
    link: "/upcomingEvents",
    logo: "star",
    description: "See what’s happening at Flow",
    tileLinkIcon: "navigate_next",
    tileOrder: 7,
    disabled: false,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const contactUsConfig = {
  contactUs: {
    id: "contactUs",
    tileTitle: "Contact Us",
    drawerTitle: "Contact Us",
    link: "/contactUs",
    logo: "phoneIcon",
    description: "Send a message to the Customer Care team",
    tileLinkIcon: "navigate_next",
    tileOrder: 11,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const customCMSTileConfig1 = {
  customCMSTile1: {
    id: "customeCMSTile1",
    tileTitle: "customeCMSTile1",
    drawerTitle: "customeCMSTile1",
    link: "/CMSPage",
    logo: "phoneIcon",
    description: "Custom tile from CMS",
    tileLinkIcon: "navigate_next",
    tileOrder: 91,
    disabled: false,
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const dynamicCMSTileConfig1 = {
  dynamicCMSTile1: {
    id: "dynamicCMSTile1",
    tileTitle: "dynamicCMSTile2",
    drawerTitle: "dynamicCMSTile2",
    link: "/CMSPage",
    logo: "phoneIcon",
    description: "Custom tile from CMS",
    tileLinkIcon: "navigate_next",
    tileOrder: 91,
    disabled: false,
    display: "none",
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const dynamicCMSTileConfig2 = {
  dynamicCMSTile2: {
    id: "dynamicCMSTile2",
    tileTitle: "dynamicCMSTile2",
    drawerTitle: "dynamicCMSTile2",
    link: "/CMSPage",
    logo: "phoneIcon",
    description: "Custom tile from CMS",
    tileLinkIcon: "navigate_next",
    tileOrder: 91,
    disabled: false,
    display: "none",
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const dynamicCMSTileConfig3 = {
  dynamicCMSTile3: {
    id: "dynamicCMSTile3",
    tileTitle: "dynamicCMSTile",
    drawerTitle: "dynamicCMSTile",
    link: "/CMSPage",
    logo: "phoneIcon",
    description: "Custom tile from CMS",
    tileLinkIcon: "navigate_next",
    tileOrder: 91,
    disabled: false,
    display: "none",
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const dynamicCMSTileConfig4 = {
  dynamicCMSTile4: {
    id: "dynamicCMSTile4",
    tileTitle: "dynamicCMSTile",
    drawerTitle: "dynamicCMSTile",
    link: "/CMSPage",
    logo: "phoneIcon",
    description: "Custom tile from CMS",
    tileLinkIcon: "navigate_next",
    tileOrder: 91,
    disabled: false,
    display: "none",
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const dynamicCMSTileConfig5 = {
  dynamicCMSTile5: {
    id: "dynamicCMSTile5",
    tileTitle: "dynamicCMSTile",
    drawerTitle: "dynamicCMSTile",
    link: "/CMSPage",
    logo: "phoneIcon",
    description: "Custom tile from CMS",
    tileLinkIcon: "navigate_next",
    tileOrder: 91,
    disabled: false,
    display: "none",
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const dynamicCMSTileConfig6 = {
  dynamicCMSTile6: {
    id: "dynamicCMSTile6",
    tileTitle: "dynamicCMSTile",
    drawerTitle: "dynamicCMSTile",
    link: "/CMSPage",
    logo: "phoneIcon",
    description: "Custom tile from CMS",
    tileLinkIcon: "navigate_next",
    tileOrder: 91,
    disabled: false,
    display: "none",
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const dynamicCMSTileConfig7 = {
  dynamicCMSTile7: {
    id: "dynamicCMSTile7",
    tileTitle: "dynamicCMSTile",
    drawerTitle: "dynamicCMSTile",
    link: "/CMSPage",
    logo: "phoneIcon",
    description: "Custom tile from CMS",
    tileLinkIcon: "navigate_next",
    tileOrder: 91,
    disabled: false,
    display: "none",
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const dynamicCMSTileConfig8 = {
  dynamicCMSTile8: {
    id: "dynamicCMSTile8",
    tileTitle: "dynamicCMSTile",
    drawerTitle: "dynamicCMSTile",
    link: "/CMSPage",
    logo: "phoneIcon",
    description: "Custom tile from CMS",
    tileLinkIcon: "navigate_next",
    tileOrder: 91,
    disabled: false,
    display: "none",
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const dynamicCMSTileConfig9 = {
  dynamicCMSTile9: {
    id: "dynamicCMSTile9",
    tileTitle: "dynamicCMSTile",
    drawerTitle: "dynamicCMSTile",
    link: "/CMSPage",
    logo: "phoneIcon",
    description: "Custom tile from CMS",
    tileLinkIcon: "navigate_next",
    tileOrder: 91,
    disabled: false,
    display: "none",
    tileTitleColor: "#5B6770",
    tileDescriptionColor: "#5B6770",
  },
};

export const flowGoHomeConfig = {
  flowGoHome: {
    id: "flowGoHome",
    drawerTitle: "Home",
    link: "/",
    drawerOrder: 0,
  },
};

export const ROLE_CONFIG = {
  GlobalAdmin: {
    appInsightsId: "GlobalAdmin",
    navItems: {
      ...manageCustomersConfig,
      ...manageUsersConfig,
      ...iotDashboardConfig,
      ...docVideoConfig,
    },
    banner: {
      imagesId: "globalAdmin",
      textId: "GlobalAdmin",
    },
    drawerItems: {
      ...flowGoHomeConfig,
    },
    drawerItemsBottom: {
      ...manageCustomersConfig,
      ...manageUsersConfig,
    },
  },
  CustomerTeamAdmin: {
    appInsightsId: "CustomerTeamAdmin",
    navItems: {
      ...iotDashboardConfig,
      ...purchaseConfig,
      ...partsOrdersConfig,
      ...reportsConfig,
      ...scheduleTrainingConfig,
      ...serviceHistoryConfig,
      ...docVideoConfig,
      ...comingAttractionsConfig,
      ...customCMSTileConfig1,
      ...dynamicCMSTileConfig1,
      ...dynamicCMSTileConfig2,
      ...dynamicCMSTileConfig3,
      ...dynamicCMSTileConfig4,
      ...dynamicCMSTileConfig5,
      ...dynamicCMSTileConfig6,
      ...dynamicCMSTileConfig7,
      ...dynamicCMSTileConfig8,
      ...dynamicCMSTileConfig9,
      manageUsers: {
        ...manageUsersConfig.manageUsers,
        description: "Add or manage users",
        tileOrder: 9,
      },
      ...contactUsConfig,
    },
    banner: {
      imagesId: "customerTeamAdmin",
      textId: "CustomerTeamAdmin",
    },
    drawerItems: {
      ...flowGoHomeConfig,
      ...iotDashboardConfig,
      ...purchaseConfig,
      ...docVideoConfig,
    },
  },
  CustomerTeamAdminCustomerUser: {
    appInsightsId: "CustomerUser",
    navItems: {
      ...iotDashboardConfig,
      ...purchaseConfig,
      ...partsOrdersConfig,
      ...reportsConfig,
      ...scheduleTrainingConfig,
      ...serviceHistoryConfig,
      ...docVideoConfig,
      ...comingAttractionsConfig,
      ...customCMSTileConfig1,
      ...dynamicCMSTileConfig1,
      ...dynamicCMSTileConfig2,
      ...dynamicCMSTileConfig3,
      ...dynamicCMSTileConfig4,
      ...dynamicCMSTileConfig5,
      ...dynamicCMSTileConfig6,
      ...dynamicCMSTileConfig7,
      ...dynamicCMSTileConfig8,
      ...dynamicCMSTileConfig9,
      manageUsers: {
        ...manageUsersConfig.manageUsers,
        description: "Add or manage users",
        tileOrder: 9,
      },
      ...contactUsConfig,
    },
    banner: {
      imagesId: "customerTeamAdmin",
      textId: "CustomerTeamAdmin",
    },
    drawerItems: {
      ...flowGoHomeConfig,
      ...iotDashboardConfig,
      ...purchaseConfig,
      ...docVideoConfig,
    },
  },
  CustomerUser: {
    appInsightsId: "CustomerUser",
    navItems: {
      ...iotDashboardConfig,
      ...purchaseConfig,
      ...partsOrdersConfig,
      ...reportsConfig,
      ...scheduleTrainingConfig,
      ...serviceHistoryConfig,
      ...docVideoConfig,
      ...comingAttractionsConfig,
      ...contactUsConfig,
      ...customCMSTileConfig1,
      ...dynamicCMSTileConfig1,
      ...dynamicCMSTileConfig2,
      ...dynamicCMSTileConfig3,
      ...dynamicCMSTileConfig4,
      ...dynamicCMSTileConfig5,
      ...dynamicCMSTileConfig6,
      ...dynamicCMSTileConfig7,
      ...dynamicCMSTileConfig8,
      ...dynamicCMSTileConfig9,
    },
    banner: {
      imagesId: "customerUser",
      textId: "CustomerUser",
    },
    drawerItems: {
      ...flowGoHomeConfig,
      ...iotDashboardConfig,
      ...purchaseConfig,
      ...docVideoConfig,
    },
  },
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const rolesJSON = {
  CustomerTeamAdmin: {
    value: "CustomerTeamAdmin",
    name: "Customer Team Admin",
  },
  CustomerUser: { value: "CustomerUser", name: "Customer User" },
};

export const rolesArray = Object.keys(rolesJSON).map((role) => ({
  value: role,
  name: rolesJSON[role].name,
}));

export const statesInitialsMap = {
  Alabama: "AL",
  Alaska: "AK",
  "American Samoa": "AS",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District Of Columbia": "DC",
  "Federated States Of Micronesia": "FM",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  "Marshall Islands": "MH",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Northern Mariana Islands": "MP",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Palau: "PW",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  "Virgin Islands": "VI",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};
