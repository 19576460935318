import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { useSelector } from 'react-redux';

import './RolesSelect.scss';

const RolesSelect = ({ defaultValue, roles }) => {
  const { errors, control, trigger } = useFormContext();
  const [selectedRoles, setSelectedRoles] = useState(defaultValue);
  const inSimulationMode = useSelector((state) => state.inSimulationMode);
  const check = defaultValue !== null;
  const rolesClass = defaultValue === null ? 'roles-select-wrapper-simulation' : 'roles-select-wrapper';

  return (
    <div className={rolesClass}>
      <InputLabel
        className="modal-label"
        id="role-multiple-checkbox-label"
        classes={{
          root: 'roles-select-label-root',
        }}
      >
        {check ? 'Role' : 'Customer role'}
      </InputLabel>
      <Controller
        control={control}
        name="Roles"
        rules={{
          validate: (rolesList) => (rolesList.length ? true : 'Role is required'),
        }}
        render={({ onChange, value }) => (
          <Select
            labelId="role-multiple-checkbox-label"
            multiple
            displayEmpty
            variant="outlined"
            value={value}
            disabled={inSimulationMode}
            onChange={(event) => {
              const val = defaultValue === null
                ? event.target.value.slice(-1)
                : event.target.value;
              setSelectedRoles(val);
              onChange(val);
              trigger('Roles');
            }}
            input={(
              <OutlinedInput
                error={!!errors.Roles}
                classes={{
                  root: 'roles-select-outlined-input-root',
                }}
              />
            )}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return check ? 'Select role' : 'Select customer role';
              }
              return selected.join(', ');
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {roles.map((role) => (
              <MenuItem key={role.value} value={role.name} classes={{ root: 'roles-select-menu-item-root' }}>
                {check && <Checkbox checked={selectedRoles.indexOf(role.name) > -1} color="primary" />}
                <ListItemText primary={role.name} />
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors.Roles && <span className="error-message">{errors.Roles.message}</span>}
    </div>
  );
};

export default RolesSelect;
