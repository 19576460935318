// PastOrdersDetails.jsx

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrders } from "store/reducers";
import { getOrderHistoryDetails } from "store/actions";
import Paper from "components/generic/Paper/Paper";
import Table from "components/generic/Table/Table/Table";
import TablePagination from "components/generic/Table/TablePagination/TablePagination";
import TableCell from "components/generic/Table/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { cMSResources } from "../../../index";
import MaterialButton from "@material-ui/core/Button";

import "./PastOrdersDetails.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const rowsPerPage = 25;

const PastOrdersDetails = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("oNo");

  const orders = useSelector(getOrders);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const handleChangeRowsPerPage = () => {
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    dispatch(getOrderHistoryDetails(id));

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, id]);

  const pagedResult =
    orders &&
    orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  var cmsItemNumber = findArrayElementByTitle(cMSResources, "{{ItemNumber}}");
  var cmsItemName = findArrayElementByTitle(cMSResources, "{{ItemName}}");
  var cmsAvgUnitPrice = findArrayElementByTitle(
    cMSResources,
    "{{AvgUnitPrice}}"
  );
  var cmsOrderQuantity = findArrayElementByTitle(
    cMSResources,
    "{{OrderQuantity}}"
  );
  var cmsOriginalPrice = findArrayElementByTitle(
    cMSResources,
    "{{OriginalPrice}}"
  );
  var cmsDiscountAmount = findArrayElementByTitle(
    cMSResources,
    "{{DiscountAmount}}"
  );
  var cmsFinalPrice = findArrayElementByTitle(cMSResources, "{{FinalPrice}}");
  var cmsDetails = findArrayElementByTitle(cMSResources, "{{Details}}");

  return (
    <>
      <div className="past-orders-details-container">
        {isMobile ? (
          <div className="mobile-orders-details">
            {pagedResult &&
              pagedResult.map((row, index) => (
                <div
                  key={`mobile-row-${index}`}
                  className="mobile-order-details"
                >
                  <div>
                    {cmsItemNumber.value}: {row.itemNumber}
                  </div>
                  <div>
                    {cmsItemName.value}: {row.itemName}
                  </div>
                  <div>
                    {cmsAvgUnitPrice.value}: {row.averageUnitPriceLocal}
                  </div>
                  <div>
                    {cmsOrderQuantity.value}: {row.orderQuantity}
                  </div>
                  <div>
                    {cmsOriginalPrice.value}:{" "}
                    {row.grossOrderAmountLocalCurrency}
                  </div>
                  <div>
                    {cmsDiscountAmount.value}: {row.discountAmountLocalCurrency}
                  </div>
                  <div>
                    {cmsFinalPrice.value}: {row.netOrderAmountLocalCurrency}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <Paper variant="gray" className="past-orders-details-paper">
            <Table
              head={
                <>
                  <TableCell align="left">{cmsItemNumber.value}</TableCell>
                  <TableCell>{cmsItemName.value}</TableCell>
                  <TableCell width={170}>{cmsAvgUnitPrice.value}</TableCell>
                  <TableCell>{cmsOrderQuantity.value}</TableCell>
                  <TableCell>{cmsOriginalPrice.value}</TableCell>
                  <TableCell>{cmsDiscountAmount.value}</TableCell>
                  <TableCell>{cmsFinalPrice.value}</TableCell>
                </>
              }
            >
              {pagedResult &&
                pagedResult.map((row, index) => (
                  <TableRow
                    key={`desktop-row-${index}`}
                    className="generic-table-body-row"
                  >
                    <TableCell>{row.itemNumber}</TableCell>
                    <TableCell>{row.itemName}</TableCell>
                    <TableCell>{row.averageUnitPriceLocal}</TableCell>
                    <TableCell>{row.orderQuantity}</TableCell>
                    <TableCell>{row.grossOrderAmountLocalCurrency}</TableCell>
                    <TableCell>{row.discountAmountLocalCurrency}</TableCell>
                    <TableCell>{row.netOrderAmountLocalCurrency}</TableCell>
                  </TableRow>
                ))}
            </Table>
          </Paper>
        )}
      </div>

      {pagedResult && (
        <TablePagination
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default PastOrdersDetails;
