import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "components/generic/Modal/Modal";
import { changeInstallationAddress } from "store/actions";
import { statesInitialsMap } from "../../../constants";
import AddressSection from "../AddressSection/AddressSection";
import ModalButtons from "../ModalButtons/ModalButtons";
import { cMSResources } from "index";

import "./EditInstallationAddressModal.scss";

/**
 * Modal used to edit the user's billing information
 *
 * @param {Boolean}  open                 indicates if the modal is opened
 * @param {Function} onCloseHandler       called when the modal is closed
 * @param {Function} onSendRequestHandler called when the request to change the installation address is send
 * @param {Object}   installationAddress  installation address data
 * @param {String}   siteId               the id of the installation
 */

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const EditInstallationAddressModal = ({
  open,
  onCloseHandler,
  onSendRequestHandler,
  installationAddress,
  siteId,
}) => {
  const { street, city, state, postalCode } = installationAddress;
  const defaultValues = {
    street: street || "",
    city: city || "",
    state: statesInitialsMap[state] || "",
    postalCode: postalCode || "",
  };
  const defaultLanguage = sessionStorage.getItem("defaultLanguage");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", reValidateMode: "onBlur", defaultValues });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    onSendRequestHandler();
    dispatch(changeInstallationAddress(data, siteId, defaultLanguage));
  };

  const onModalClose = (event, reason) => {
    if (reason === "backdropClick") {
      onCloseHandler();
    }
  };

  var cmsSendRequest = findArrayElementByTitle(cMSResources, "{{SendRequest}}");
  var cmsCancel = findArrayElementByTitle(cMSResources, "{{Cancel}}");

  const buttonsConfig = [
    {
      text: cmsCancel.value,
      handler: onCloseHandler,
    },
    {
      text: cmsSendRequest.value,
      handler: handleSubmit(onSubmit),
    },
  ];

  var cmsRequestInstallationAddressChange = findArrayElementByTitle(
    cMSResources,
    "{{RequestInstallationAddressChange}}"
  );

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      className="edit-installation-address-modal"
      title={cmsRequestInstallationAddressChange.value}
      footer={<ModalButtons config={buttonsConfig} />}
    >
      <form className="edit-installation-address-modal-form">
        <AddressSection register={register} errors={errors} />
      </form>
    </Modal>
  );
};

export default EditInstallationAddressModal;
