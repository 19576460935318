import React, { useEffect, useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/generic/Button/Button";
import request from "api/request";
import { emailRegex, rolesJSON, rolesArray } from "../../constants";
import RolesSelect from "./RolesSelect/RolesSelect";
import {
  getCustomers,
  getUsers,
  getCustomersData,
  setCustomerData,
  spinnerToggle,
} from "../../store/actions";
import { debounce } from "../Common/basicUtils";
import "./Modal.scss";
import { cMSResources } from "../../index";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const ModalPopup = ({ rowData, screenName, isEdit, onHide, show }) => {
  const cdata = useSelector((state) => state.customerData);
  const userClaims = useSelector((state) => state.claims);
  const customerName = JSON.parse(userClaims.extension_CustomerMapping)
    .CustomerMappings[0].CustomerName;
  let roleDefaultValue = "Customer Team Admin, Customer User";

  var cmsFirstName = findArrayElementByTitle(cMSResources, "{{FirstName}}");
  var cmsLastName = findArrayElementByTitle(cMSResources, "{{LastName}}");
  var cmsUserEmail = findArrayElementByTitle(cMSResources, "{{UserEmail}}");
  var cmsAdd = findArrayElementByTitle(cMSResources, "{{Add}}");
  var cmsRole = findArrayElementByTitle(cMSResources, "{{Role}}");
  var cmsCancel = findArrayElementByTitle(cMSResources, "{{Cancel}}");
  var cmsCustomerMobilePhone = findArrayElementByTitle(
    cMSResources,
    "{{CustomerMobilePhone}}"
  );
  const defaultLanguage = sessionStorage.getItem("defaultLanguage");

  if (isEdit) {
    roleDefaultValue = [];
    if (rowData) {
      const rolesToProcess =
        typeof rowData.Role === "string"
          ? rowData.Role.split(",")
          : rowData.Role;
      roleDefaultValue = rolesToProcess.map(
        (roleValue) => rolesJSON[roleValue].name
      );
    }
  } else if (screenName === "manage-user") {
    roleDefaultValue = [];
  }

  const defaultValues = {
    FirstName: rowData ? rowData["First Name"] : null,
    LastName: rowData ? rowData["Last Name"] : null,
    CustomerName: null,
    CustomerNumber: null,
    PrimaryContactEmail: rowData ? rowData["Primary Contact Email"] : null,
    Roles: roleDefaultValue,
    MobilePhone: rowData ? rowData["Mobile Phone"] : null,
  };
  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues,
  });
  const { register, handleSubmit, errors, control, trigger, setValue } =
    formMethods;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCustomerData());
  }, [dispatch]);

  let cnumber = ["Select"];
  cnumber = cdata && cdata.map((item) => item.CustomerNumber);

  let cname = ["Select"];
  cname = cdata && cdata.map((item) => item.CustomerName);

  const currentRole = userClaims
    ? JSON.parse(userClaims.extension_CustomerMapping).CustomerMappings[0].Role
    : null;

  const [customerNameInputValue, setCustomerNameInputValue] = useState(null);
  const [customerNumberInputValue, setCustomerNumberInputValue] =
    useState(null);
  const [selectedCustomerAddress, setSelectedCustomerAddress] = useState(
    userClaims.streetAddress
      ? userClaims.streetAddress
      : "address not available"
  );

  const editUser = ({
    Roles,
    FirstName,
    LastName,
    PrimaryContactEmail,
    MobilePhone,
  }) => {
    let roles = [];
    roles = Roles.map((item) => item.split(" ").join(""));

    const userDetails = {
      FirstName,
      LastName,
      Roles: roles,
      Action: "Edit User",
      Id: rowData.Id,
      Registered: rowData.Registered,
      PrimaryContact: rowData["Primary Contact"],
      CustomerName: rowData["Customer Name"],
      CustomerNumber: rowData["Customer Number"],
      Email: PrimaryContactEmail,
      MobilePhone,
    };

    request({
      urlSlug: "api/UserData/UpdateUsers",
      method: "POST",
      payload: userDetails,
    })
      .then((result) => {
        dispatch(spinnerToggle(false));
        if (!!result && result.success === true) {
          Swal.fire({
            title: "Success",
            text: "user updated successfully",
            icon: "success",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "Internal Error",
            text: result.errorMessage || "please try again after sometime",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }

        screenName === "manage-user"
          ? currentRole && currentRole.includes("GlobalAdmin")
            ? dispatch(getUsers("All", "Manage Users"))
            : dispatch(getUsers(userDetails.CustomerName, "Manage Users"))
          : dispatch(getCustomers("All", "Manage Customers"));
      })
      .catch(() => {
        dispatch(spinnerToggle(false));
        Swal.fire({
          title: "Internal Error",
          text: "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const createUser = ({
    Roles,
    FirstName,
    LastName,
    PrimaryContactEmail,
    CustomerName,
    CustomerNumber,
    MobilePhone,
  }) => {
    const role =
      screenName === "manage-user"
        ? Roles.map((item) => item.split(" ").join(""))
        : ["CustomerTeamAdmin", "CustomerUser"];

    const userDetails = {
      FirstName,
      LastName,
      Address: selectedCustomerAddress,
      Roles: role,
      Email: PrimaryContactEmail,
      CustomerName:
        currentRole && currentRole.includes("GlobalAdmin")
          ? CustomerName
          : JSON.parse(userClaims.extension_CustomerMapping).CustomerMappings[0]
              .CustomerName,
      CustomerNumber:
        currentRole && currentRole.includes("GlobalAdmin")
          ? CustomerNumber
          : JSON.parse(userClaims.extension_CustomerMapping).CustomerMappings[0]
              .CustomerNumber,
      PrimaryContact: screenName !== "manage-user",
      flowPartsUrl:
        currentRole && currentRole.includes("GlobalAdmin")
          ? null
          : userClaims.extension_FlowPartsUrl,
      MobilePhone,
    };

    request({
      urlSlug: `api/UserData/CreateUser/${defaultLanguage}`,
      method: "POST",
      payload: userDetails,
    })
      .then((result) => {
        dispatch(spinnerToggle(false));
        let title;
        let text;
        let icon;
        if (!!result && result.success === true) {
          title = "Success";
          text = "user onboarded successfully";
          icon = "success";
        } else if (
          !!result &&
          result.success === false &&
          !!result.data &&
          result.data.toLowerCase() === "user already exists"
        ) {
          title = "Warning";
          text = "user already exists";
          icon = "warning";
        } else {
          title = "Internal Error";
          text = result.errorMessage || "please try again after sometime";
          icon = "error";
        }

        Swal.fire({
          title,
          text,
          icon,
          confirmButtonText: "Ok",
        });

        screenName === "manage-user"
          ? currentRole && currentRole.includes("GlobalAdmin")
            ? dispatch(getUsers("All", "Manage Users"))
            : dispatch(getUsers(customerName, "Manage Users"))
          : dispatch(getCustomers("All", "Manage Customers"));
      })
      .catch(() => {
        dispatch(spinnerToggle(false));
        Swal.fire({
          title: "Internal Error",
          text: "please try again after sometime",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const fetchResults = debounce((inputName, inputText) => {
    dispatch(getCustomersData(inputName, inputText));
  }, 200);

  const onSubmit = (formControlledData) => {
    dispatch(spinnerToggle(true));
    onHide();

    if (isEdit) {
      editUser(formControlledData);
      return;
    }

    createUser(formControlledData);
  };

  const inSimulationMode = useSelector((state) => state.inSimulationMode);

  return (
    <Dialog
      onClose={onHide}
      open={show}
      PaperProps={{ classes: { root: "modal-root" } }}
    >
      <FormProvider {...formMethods}>
        <form className="modal-form" noValidate>
          <Row>
            <Col lg={6} md={12} sm={12} xl={6} xs={12} className="col-group">
              <label className="modal-label" htmlFor="fNameInput">
                {cmsFirstName.value}
              </label>
              <input
                id="fNameInput"
                type="text"
                name="FirstName"
                ref={register({ required: "First name is required" })}
                className={classNames({ "input-error": errors.FirstName })}
                disabled={inSimulationMode}
              />
              {errors.FirstName && (
                <span className="error-message">
                  {errors.FirstName.message}
                </span>
              )}
            </Col>
            <Col lg={6} md={12} sm={12} xl={6} xs={12} className="col-group">
              <label className="modal-label" htmlFor="lNameInput">
                {cmsLastName.value}
              </label>
              <input
                id="lNameInput"
                type="text"
                name="LastName"
                ref={register({ required: "Last name is required" })}
                className={classNames({ "input-error": errors.LastName })}
                disabled={inSimulationMode}
              />
              {errors.LastName && (
                <span className="error-message">{errors.LastName.message}</span>
              )}
            </Col>
            {currentRole &&
            currentRole.includes("GlobalAdmin") &&
            isEdit === false &&
            !inSimulationMode ? (
              <>
                <Col
                  lg={6}
                  md={12}
                  sm={12}
                  xl={6}
                  xs={12}
                  className="col-group"
                >
                  <label
                    className="modal-label"
                    htmlFor="customer-name-autocomplete"
                  >
                    Customer Name
                  </label>
                  <Controller
                    control={control}
                    rules={{ required: "Required field" }}
                    name="CustomerName"
                    disabled={inSimulationMode}
                    render={() => (
                      <Autocomplete
                        value={customerNameInputValue}
                        size="small"
                        className="customer-name"
                        disabled={inSimulationMode}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setCustomerNameInputValue(newValue);
                            let indexInCName = 0;
                            cname.map((item, index) => {
                              if (item === newValue) indexInCName = index;
                            });
                            cdata.map((item, index) => {
                              if (index === indexInCName) {
                                setCustomerNumberInputValue(
                                  item.CustomerNumber
                                );
                                setSelectedCustomerAddress(
                                  item.BillingAddress !== ""
                                    ? item.BillingAddress
                                    : "address not available"
                                );
                                setValue("CustomerNumber", item.CustomerNumber);
                                trigger("CustomerNumber");
                              }
                            });
                          } else {
                            setCustomerNameInputValue(null);
                            setSelectedCustomerAddress("");
                            setCustomerNumberInputValue(null);
                            setValue("CustomerNumber", null);
                            trigger("CustomerNumber");
                          }

                          setValue("CustomerName", newValue);
                          trigger("CustomerName");
                        }}
                        onInputChange={(event, newInputValue) => {
                          if (newInputValue !== "")
                            fetchResults("CustomerName", newInputValue);
                          else {
                            setCustomerNumberInputValue(null);
                            setSelectedCustomerAddress("");
                          }
                        }}
                        getOptionSelected={(option, value) => option === value}
                        id="customer-name-autocomplete"
                        options={cname}
                        style={{
                          height: "50px",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            placeholder="Choose Customer Name"
                            variant="outlined"
                            className={classNames({
                              "autocomplete-error": errors.CustomerName,
                            })}
                            disabled={inSimulationMode}
                          />
                        )}
                        classes={{
                          inputRoot: "mui-autocomplete-inputRoot",
                        }}
                      />
                    )}
                  />
                  {errors.CustomerName && (
                    <span className="error-message">
                      {errors.CustomerName.message}
                    </span>
                  )}
                </Col>
                <Col
                  lg={6}
                  md={12}
                  sm={12}
                  xl={6}
                  xs={12}
                  className="col-group"
                >
                  <label
                    className="modal-label"
                    htmlFor="customer-number-autocomplete"
                  >
                    Customer Number
                  </label>
                  <Controller
                    control={control}
                    rules={{ required: "Required field" }}
                    name="CustomerNumber"
                    disabled={inSimulationMode}
                    render={() => (
                      <Autocomplete
                        value={customerNumberInputValue}
                        size="small"
                        label="Choose Customer Number"
                        className="customer-number"
                        disabled={inSimulationMode}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setCustomerNumberInputValue(newValue);
                            let indexInCNumber = 0;
                            cnumber.map((item, index) => {
                              if (item === newValue) indexInCNumber = index;
                            });
                            cdata.map((item, index) => {
                              if (index === indexInCNumber) {
                                setCustomerNameInputValue(item.CustomerName);
                                setSelectedCustomerAddress(
                                  item.BillingAddress !== ""
                                    ? item.BillingAddress
                                    : "address not available"
                                );
                                setValue("CustomerName", item.CustomerName);
                                trigger("CustomerName");
                              }
                            });
                          } else {
                            setCustomerNameInputValue(null);
                            setSelectedCustomerAddress("");
                            setCustomerNumberInputValue(null);
                            setValue("CustomerName", null);
                            trigger("CustomerName");
                          }
                          setValue("CustomerNumber", newValue);
                          trigger("CustomerNumber");
                        }}
                        onInputChange={(event, newInputValue) => {
                          if (newInputValue !== "")
                            fetchResults("CustomerNumber", newInputValue);
                          else {
                            setCustomerNameInputValue(null);
                            setSelectedCustomerAddress("");
                          }
                        }}
                        getOptionSelected={(option, value) => option === value}
                        id="customer-number-autocomplete"
                        options={cnumber}
                        style={{
                          height: "50px",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            placeholder="Choose Customer Number"
                            variant="outlined"
                            className={classNames({
                              "autocomplete-error": errors.CustomerNumber,
                            })}
                            disabled={inSimulationMode}
                          />
                        )}
                        classes={{
                          inputRoot: "mui-autocomplete-inputRoot",
                        }}
                      />
                    )}
                  />
                  {errors.CustomerNumber && (
                    <span className="error-message">
                      {errors.CustomerNumber.message}
                    </span>
                  )}
                </Col>
              </>
            ) : null}
            <Col lg={6} md={12} sm={12} xl={6} xs={12} className="col-group">
              <label className="modal-label" htmlFor="emailInput">
                {" "}
                {screenName !== "manage-user"
                  ? "Primary Contact Email"
                  : cmsUserEmail.value}
              </label>
              <input
                id="emailInput"
                type="text"
                name="PrimaryContactEmail"
                ref={register({
                  required: "Email is required",
                  validate: (email) =>
                    emailRegex.test(email.toLowerCase())
                      ? true
                      : "Email is invalid",
                })}
                className={classNames({
                  "input-error": errors.PrimaryContactEmail,
                })}
                disabled={inSimulationMode}
              />
              {errors.PrimaryContactEmail && (
                <span className="error-message">
                  {errors.PrimaryContactEmail.message}
                </span>
              )}
            </Col>
            <Col lg={6} md={12} sm={12} xl={6} xs={12} className="col-group">
              {screenName !== "manage-user" && !isEdit ? (
                <>
                  <label className="modal-label" htmlFor="roleInput">
                    {cmsRole.value}
                  </label>
                  <input
                    id="roleInput"
                    type="text"
                    name="Roles"
                    defaultValue={roleDefaultValue}
                    ref={register}
                    disabled={inSimulationMode}
                  />
                </>
              ) : (
                <RolesSelect
                  defaultValue={roleDefaultValue}
                  roles={rolesArray}
                />
              )}
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xl={6}
              xs={12}
              className="col-group no-asterisk"
            >
              <label className="modal-label" htmlFor="phoneInput">
                {cmsCustomerMobilePhone.value}
              </label>
              <input
                id="phoneInput"
                type="text"
                name="MobilePhone"
                placeholder="ex: +1 234 567 8900"
                ref={register()}
                className={classNames({ "input-error": errors.FirstName })}
                disabled={inSimulationMode}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12} xl={6} xs={12} className="col-group">
              <Button
                text={isEdit === true ? "UPDATE" : cmsAdd.value}
                variant="contained"
                color={inSimulationMode ? "disabled" : "primary"}
                size="modal-btn"
                onClick={!inSimulationMode && handleSubmit(onSubmit)}
              />
            </Col>
            <Col lg={6} md={6} sm={12} xl={6} xs={12} className="col-group">
              <Button
                text={cmsCancel.value}
                variant="contained"
                color="primary"
                size="modal-btn"
                onClick={onHide}
              />
            </Col>
          </Row>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ModalPopup;
