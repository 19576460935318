import { createReducer } from '@reduxjs/toolkit';
import {
  setServiceHistory,
} from '../actions';

const initialState = null;

/**
 * Keeps setServiceHistory state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const serviceHistory = createReducer(initialState, {
  [setServiceHistory.type]: (state, { value }) => value.response,
});

export default serviceHistory;
