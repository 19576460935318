import React from 'react';
import Button from 'components/generic/Button/Button';
import flowlogo from '../../assets/flowpartslogo.svg';
import { cmsLogoAndCollor } from '../../index';

import './PasswordResetSuccess.scss';

/**
 * Page, rendered when the users have successfully updated their password
 *
 * @param {Object} msalObj Object with MSAL data
 */
const PasswordResetSuccess = ({ msalObj }) => (
  <div style={{backgroundColor:cmsLogoAndCollor.color}} className="password-reset--wrapper">
    <div className="password-reset--tile">
      <div className="password-reset--tile-inner">
        <img className="header-logo" alt="flow-logo" src={cmsLogoAndCollor.logo} />
        <div className="password-reset-message">Your password has been reset successfully</div>
        <Button
          text="Sign in"
          variant="contained"
          color="primary"
          onClick={() => {
            msalObj.logout();
          }}
          className="password-reset--login-btn"
        />
      </div>
      <div className="tile-item">
        <span className="login-contact">
          Having trouble logging in?
          <span className="contact-email">
            Contact
            {' '}
            <a href={cmsLogoAndCollor.customerService}>{cmsLogoAndCollor.customerService}</a>
          </span>
        </span>
      </div>
    </div>
  </div>
);

export default PasswordResetSuccess;
