import { createReducer } from '@reduxjs/toolkit';

const initialState = null;

const enhanceClaims = (userClaims) => {
  const { CustomerNumber, CustomerName, Role } = JSON.parse(userClaims.extension_CustomerMapping).CustomerMappings[0];

  return {
    ...userClaims,
    userDetails: {
      customerName: CustomerName,
      number: CustomerNumber,
      address: userClaims.streetAddress,
      role: Role,
    },
  };
};

/**
 * Keeps claims state
 *
 * @param  {Array} state: the new state
 * @param  {Object} action: action to be performed
 * @return {Object} the new state
 */
const claims = createReducer(initialState, {
  SET_CLAIMS: (state, { value }) => enhanceClaims(value.payload),
});

export default claims;
