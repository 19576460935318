import 'whatwg-fetch';
import { accessToken } from '../../index';

const FetchData = (url = '', data = {}, method = 'POST', isJson) => {
  const requestObject = {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    redirect: 'follow',
    referrer: 'no-referrer',
  };
  if (method === 'POST' || method === 'PUT') { requestObject.body = JSON.stringify(data); }
  return fetch(url, requestObject).then((response) => response.json());
};

export default FetchData;
