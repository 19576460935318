import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import {
  toggleModal,
  disableUsers,
  resendUserInvite,
  getUsers,
} from "store/actions";
import ModalPopup from "../Modal/Modal";
import edit from "../../assets/edit.svg";
import resend from "../../assets/Resend.svg";
import visibility from "../../assets/hidden.svg";
import enable from "../../assets/enable.svg";
import editDisabled from "../../assets/editDisabled.svg";
import visibilityDisabled from "../../assets/hiddenDisabled.svg";
import enableDisabled from "../../assets/enableDisabled.svg";

import "./TableMobile.scss";

let inSimulationMode;

const TableMobile = (props) => {
  let data = null;
  const customerGridData = useSelector((state) => state.customerGridData);
  const userGridData = useSelector((state) => state.userGridData);
  const userClaims = useSelector((state) => state.claims);
  let currentGridData;
  const dispatch = useDispatch();
  if (localStorage.getItem("inSimulationMode") === "true") {
    inSimulationMode = true;
    const { customerName } = JSON.parse(localStorage.getItem("simulatedUser"));
    // dispatch(getUsers(customerName, "Manage Users"));
  }
  if (props.screenName === "manage-customer") {
    currentGridData = customerGridData;
  } else {
    currentGridData = userGridData;
  }
  data = currentGridData.map((item) => ({
    "Customer Name":
      item.extension_CustomerMapping.CustomerMappings[0].CustomerName,
    "Customer Number":
      item.extension_CustomerMapping.CustomerMappings[0].CustomerNumber,
    "Primary Contact Email": item.mail,
    "Invitation Status":
      item.extension_Registered === true ? "Registered" : "Invited",
    "First Name": item.givenName,
    "Last Name": item.surname,
    "Primary Contact": item.extension_PrimaryContact,
    Role: item.extension_CustomerMapping.CustomerMappings[0].Role,
    Id: item.id,
    Registered: item.extension_Registered,
    "Account Enabled": item.accountEnabled,
    // Phone: item.mobilePhone,
  }));
  const toggleAccountButton = () => {
    const userData = {
      CustomerName: rowData["Customer Name"],
      CustomerNumber: rowData["Customer Number"],
      Action: rowData["Account Enabled"] ? "Disable User" : "Enable User",
      Id: rowData.Id,
      CurrentScreen:
        props.screenName === "manage-user"
          ? "Manage Users"
          : "Manage Customers",
    };
    dispatch(disableUsers(userData));
  };
  const resendInvitationButton = () => {
    const userData = {
      FirstName: rowData["First Name"],
      Email: rowData["Primary Contact Email"],
      Action: "Resend Invitation",
      Id: rowData.Id,
      Roles: rowData.Role,
      CustomerName: rowData["Customer Name"],
      CustomerNumber: rowData["Customer Number"],
    };
    dispatch(resendUserInvite(userData));
  };

  const [rowData, setRowData] = useState({});
  const [editClicked, setEditClicked] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const show = useSelector((state) => state.showModal);
  const openModal = (event, cellData) => {
    setAnchorEl(event.currentTarget);
    setRowData(cellData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {Object.keys(data).length >= 0 ? (
        <div className="table-mobile">
          {data.map((cellData, index) => (
            <div className="table-mobile-row">
              <div className="table-mobile-data">
                <div className="table-mobile-customer-name">
                  {cellData["Customer Name"]}
                </div>
                <div className="table-mobile-customer-number">
                  {" "}
                  {cellData["Customer Number"]}
                </div>
                <div className="table-mobile-customer-email">
                  {cellData["Primary Contact Email"]}
                </div>
                <div className="table-mobile-customer-email">
                  {cellData.Role.join(",")}
                </div>
                <div className="table-mobile-invited">
                  {cellData["Invitation Status"]}
                </div>
              </div>
              <div className="table-mobile-ellipses">
                <MoreVertIcon
                  className="image-ellipses"
                  onClick={(event) => {
                    openModal(event, cellData);
                  }}
                />
              </div>
            </div>
          ))}
          {editClicked ? (
            <ModalPopup
              show={show}
              rowData={rowData}
              currentDevice="mobile"
              screenName={props.screenName}
              isEdit
              onHide={() => {
                dispatch(toggleModal(false));
                setEditClicked(false);
              }}
            />
          ) : null}
        </div>
      ) : null}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: "edit-customer-popover",
        }}
      >
        <div className="edit-customer-popover-inner-wrapper">
          {rowData["Invitation Status"] === "Invited" && (
            <div
              className="table-mobile-popup-text"
              onClick={() => {
                resendInvitationButton();
                handleClose();
              }}
            >
              Resend Invitation
              <img className="table-img-status" alt="flow-logo" src={resend} />
            </div>
          )}
          <div
            className="table-mobile-popup-text"
            onClick={() => {
              !inSimulationMode && setEditClicked(true);
              !inSimulationMode && dispatch(toggleModal(true));
              !inSimulationMode && handleClose();
            }}
          >
            Edit
            <img
              className="table-img-status"
              alt="flow-logo"
              src={inSimulationMode ? editDisabled : edit}
            />
          </div>
          {userClaims.emails[0] !== rowData["Primary Contact Email"] && (
            <div
              className="table-mobile-popup-text"
              onClick={() => {
                !inSimulationMode && toggleAccountButton();
                !inSimulationMode && handleClose();
              }}
            >
              {rowData["Account Enabled"] ? "Disable" : "Enable"}
              <img
                className="table-img-status"
                alt="flow-logo"
                src={
                  rowData["Account Enabled"]
                    ? inSimulationMode
                      ? visibilityDisabled
                      : visibility
                    : inSimulationMode
                    ? enableDisabled
                    : enable
                }
              />
            </div>
          )}
        </div>
      </Popover>
    </>
  );
};

export default TableMobile;
