import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { getOrders, getCustomerNumber } from "store/reducers";
import { getOrderHistory } from "store/actions";
import Paper from "components/generic/Paper/Paper";
import Table from "components/generic/Table/Table/Table";
import TablePagination from "components/generic/Table/TablePagination/TablePagination";
import TableCell from "components/generic/Table/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MaterialButton from "@material-ui/core/Button";
import { cMSResources } from "../../../index";

import "./PastOrders.scss";

function findArrayElementByTitle(array, key) {
  return array.find((element) => {
    return element.key === key;
  });
}

const rowsPerPage = 25;

const PastOrders = () => {
  const orders = useSelector(getOrders);
  const customerNumber = useSelector(getCustomerNumber);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const handleChangeRowsPerPage = () => {
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    dispatch(getOrderHistory(customerNumber));

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, customerNumber]);

  const pagedResult =
    orders &&
    orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  var cmsOrderNumber = findArrayElementByTitle(cMSResources, "{{OrderNumber}}");
  var cmsOrderDate = findArrayElementByTitle(cMSResources, "{{OrderDate}}");
  var cmsOrderTotal = findArrayElementByTitle(cMSResources, "{{OrderTotal}}");
  var cmsWebOrders = findArrayElementByTitle(cMSResources, "{{WebOrders}}");
  var cmsOrderShippingDate = findArrayElementByTitle(
    cMSResources,
    "{{OrderShippingDate}}"
  );
  var cmsDetails = findArrayElementByTitle(cMSResources, "{{Details}}");

  const formatCurrency = (amount, currencyCode) => {
    if (currencyCode === "USD") {
      return `$${amount}`;
    } else {
      return `${currencyCode} ${amount}`;
    }
  };

  return (
    <>
      <div className="past-orders-container">
        {isMobile ? (
          <div className="mobile-orders">
            {pagedResult &&
              pagedResult.map((row, index) => (
                <div key={`mobile-row-${index}`} className="mobile-order">
                  <div>
                    {cmsOrderNumber.value}: {row.orderNumber}
                  </div>
                  <div>
                    {cmsOrderDate.value}:{" "}
                    {format(new Date(row.orderDate), "MM/dd/yyyy")}
                  </div>
                  <div>
                    {cmsOrderTotal.value}:{" "}
                    {formatCurrency(
                      row.netOrderAmountLocalCurrency,
                      row.localCurrencyCode
                    )}
                  </div>
                  <div>
                    {cmsWebOrders.value}: {row.isWebOrder ? "Yes" : "No"}
                  </div>
                  <div>
                    {cmsOrderShippingDate.value}: {row.shipDate}
                  </div>
                  <div>
                    <MaterialButton
                      variant="contained"
                      color="primary"
                      href={`/orderHistoryDetails/?oNo=${row.orderNumber}`}
                    >
                      {cmsDetails.value}
                    </MaterialButton>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <Paper variant="gray" className="past-orders-paper">
            <Table
              head={
                <>
                  <TableCell align="left">{cmsOrderNumber.value}</TableCell>
                  <TableCell>{cmsOrderDate.value}</TableCell>
                  <TableCell className="past-orders-order-total" align="center">
                    {cmsOrderTotal.value}
                  </TableCell>
                  <TableCell align="center">{cmsWebOrders.value}</TableCell>
                  <TableCell>{cmsOrderShippingDate.value}</TableCell>
                  <TableCell>{cmsDetails.value}</TableCell>
                </>
              }
            >
              {pagedResult &&
                pagedResult.map((row, index) => (
                  <TableRow
                    key={`desktop-row-${index}`}
                    className="generic-table-body-row"
                  >
                    <TableCell>{row.orderNumber}</TableCell>
                    <TableCell>
                      {format(new Date(row.orderDate), "MM/dd/yyyy")}
                    </TableCell>
                    <TableCell
                      className="past-orders-order-total"
                      align="center"
                    >
                      {formatCurrency(
                        row.netOrderAmountLocalCurrency,
                        row.localCurrencyCode
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.isWebOrder ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{row.shipDate}</TableCell>
                    <TableCell>
                      <MaterialButton
                        variant="contained"
                        color="primary"
                        href={`/orderHistoryDetails/?oNo=${row.orderNumber}`}
                      >
                        {cmsDetails.value}
                      </MaterialButton>
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
          </Paper>
        )}
      </div>

      {pagedResult && (
        <TablePagination
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default PastOrders;
