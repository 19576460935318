import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import classNames from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/generic/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import {
  getUsers,
  setSimulationMode,
  setSimulatedUserClaims,
} from "store/actions";
import { emailRegex, rolesArray } from "../../constants";
import RolesSelect from "./RolesSelect/RolesSelect";

import DialogBox from "./Dialogbox";

const SimulationModalPopup = ({ show, onHide }) => {
  const roleDefaultValue = [];
  const defaultValues = {
    PrimaryContactEmail: null,
    Roles: roleDefaultValue,
  };
  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues,
  });
  const { register, handleSubmit, errors } = formMethods;
  const dispatch = useDispatch();
  const userGridData = useSelector((state) => state.userGridData);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [user, setUser] = useState(null);
  const [userExistWithEmail, setUserWithEmail] = useState(true);

  const handleClickToOpen = (formControlledData) => {
    setOpen(true);
    setFormData(formControlledData);
    const role = formControlledData?.Roles[0].replace(/\s/g, "");
    if (!user) {
      setUser(
        userGridData.find(
          (currentUser) =>
            currentUser.mail.trim().toLowerCase() ===
              formControlledData.PrimaryContactEmail.trim().toLowerCase() &&
            currentUser.extension_CustomerMapping.CustomerMappings[0].Role?.includes(
              role
            )
        )
      );
    } else if (
      !user?.extension_CustomerMapping.CustomerMappings[0].Role?.includes(role)
    ) {
      setUser(null);
    }
  };

  const checkUserExist = (event) => {
    const userEmail = event.currentTarget.value;
    if (userEmail) {
      const userWithEmail = userGridData.find(
        (currentUser) =>
          currentUser.mail.trim().toLowerCase() ===
          userEmail.trim().toLowerCase()
      );
      setUser(userWithEmail);
      userWithEmail ? setUserWithEmail(true) : setUserWithEmail(false);
    }
  };

  const handleToClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    if (user) {
      dispatch(setSimulationMode(true));
      const customerName =
        user?.extension_CustomerMapping.CustomerMappings[0].CustomerName;
      const simulatedUserObj = {
        role: user?.extension_CustomerMapping.CustomerMappings[0].Role,
        userName: `${user?.givenName} ${user?.surname}`,
        customerName,
        customerNumber:
          user?.extension_CustomerMapping.CustomerMappings[0].CustomerNumber,
        mail: user?.mail,
        selectedRole: formData?.Roles[0]?.replace(/\s/g, ""),
      };
      dispatch(setSimulatedUserClaims(simulatedUserObj));
      dispatch(getUsers(customerName, "Manage Users"));
      localStorage.setItem("simulatedUser", JSON.stringify(simulatedUserObj));
      localStorage.setItem("inSimulationMode", true);
      onHide();
    }
  };

  return (
    <Dialog
      onClose={onHide}
      open={show}
      PaperProps={{ classes: { root: "modal-root" } }}
    >
      <FormProvider {...formMethods}>
        <form className="modal-form" noValidate>
          <Row>
            <Col lg={12} md={12} sm={12} xl={12} xs={12} className="col-group">
              <div
                style={{
                  fontSize: 30,
                  fontWeight: 700,
                  color: "#017681",
                  textAlign: "center",
                }}
              >
                Simulate As
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              xl={12}
              xs={12}
              className="col-group"
              style={{ marginTop: 20 }}
            >
              <label className="modal-label" htmlFor="PrimaryContactEmail">
                Customer email address
              </label>
              <input
                id="PrimaryContactEmail"
                type="text"
                name="PrimaryContactEmail"
                placeholder="Customer Email"
                ref={register({
                  required: "Email is required",
                  validate: (email) =>
                    emailRegex.test(email.toLowerCase())
                      ? true
                      : "Email is invalid",
                })}
                onBlur={checkUserExist}
                onChange={() => {
                  setUserWithEmail(true);
                }}
                className={classNames({
                  "input-error": errors.PrimaryContactEmail,
                })}
                style={{ width: "100%" }}
              />
              {errors.PrimaryContactEmail && (
                <span className="error-message">
                  {errors.PrimaryContactEmail.message}
                </span>
              )}
            </Col>
            <Col lg={12} md={12} sm={12} xl={12} xs={12} className="col-group">
              <RolesSelect defaultValue={null} roles={rolesArray} />
            </Col>
          </Row>
          {!user && open && userExistWithEmail && (
            <div className="error-message" style={{ textAlign: "center" }}>
              User doesn&apos;t exist with the selected role
            </div>
          )}
          {!userExistWithEmail && (
            <div className="error-message" style={{ textAlign: "center" }}>
              Email address is not valid
            </div>
          )}
          <div className="simulation-buttons">
            <>
              <Button
                text="CANCEL"
                variant="outlined"
                color="primary"
                size="modal-btn"
                onClick={onHide}
                className="simulate-cancel-btn"
              />
              <Button
                text="SIMULATE"
                variant="contained"
                color="primary"
                size="modal-btn"
                onClick={handleSubmit(handleClickToOpen)}
              />
              <div
                className="divider"
                style={{ width: "10px", height: "auto" }}
              />
            </>

            {user && (
              <DialogBox
                checkOpen={open}
                handleToClose={handleToClose}
                onSubmit={onSubmit}
                enteringSimulation
                displayMessage="Simulate as the following user and role?"
                formData={formData}
                user={user}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default SimulationModalPopup;
